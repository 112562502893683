import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { TransferState, makeStateKey } from "@angular/platform-browser";
import { of } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class IncidentTrackerService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private transferState: TransferState
  ) {}

  getIncidentTrackerList(offSet?: any, end?: any) {
    let params = `end=${end}&offset=${offSet}`;
    return this.http.get(`grc/incident?${params}`);
  }

  getProgram() {
    return this.http.get(`/delivery/get-complete-program-list/`);
  }

  getProject() {
    return this.http.get(`/delivery/get-complete-project-list/`);
  }

  getVendor() {
    return this.http.get(`/delivery/get-complete-vendor-list/`);
  }

  getDomain() {
    return this.http.get(`grc/incidentdomain/`);
  }

  getSubDomain() {
    return this.http.get(`grc/incidentsubdomain/`);
  }

  getAudit() {
    return this.http.get(`delivery/audit-master`);
  }

  getUser() {
    return this.http.get(`users/user-list/`);
  }

  addNewIncidentTracker(data: any) {
    return this.http.post("grc/incident/", data);
  }

  updateIncidentTracker(id: any, data: any) {
    return this.http.put(`grc/incident/${id}/`, data);
  }

  deleteIncidentTracker(incidentId: any) {
    return this.http.delete(`grc/incident/${incidentId}/`);
  }

  getAuditList() {
    return this.http.get(`delivery/audit-master`);
  }
  getAuditLogList() {
    return this.http.get(`delivery/audit-history`);
  }
  postAuditLogList(payload: any) {
    return this.http.post("delivery/audit-history/", payload);
  }
  deleteAuditLogById(id: any) {
    return this.http.delete(`delivery/audit-history/${id}/`);
  }
  getOwner() {
    return this.http.get(`users/user-list/`);
  }

  postAuditMaster(payload: any) {
    return this.http.post(`delivery/audit-master/`, payload);
  }
  deleteAuditById(auditId: any) {
    return this.http.delete(`delivery/audit-master/${auditId}/`);
  }
  downloadFile(id: any) {
    return this.http.get(`delivery/audit-master/${id}/template/download`, {
      responseType: "blob",
    });
  }
  downloadAuditLogFile(id: any) {
    return this.http.get(`delivery/audit-history/${id}/report/download`, {
      responseType: "blob",
    });
  }
  getIncidentType() {
    return this.http.get("base_value/?type=grc_incident_type");
  }
  downloadTemplate() {
    return this.http.get(`grc/audit-templates/default/`, {
      responseType: "blob",
    });
  }

  getAuditTemplates() {
    return this.http.get(`grc/audit-templates/`);
  }

  deletedAuditTemplate(id: any) {
    return this.http.delete(`grc/audit-templates/${id}`);
  }

  uploadFile(payload: any) {
    return this.http.post(`grc/audit-templates/`, payload);
  }

  getAuditTemplateCategory() {
    return this.http.get("base_value/?type=grc_audit_category");
  }

  getFrequencies() {
    return this.http.get("base_value/?type=grc_frequency_type");
  }

  getAuditTypes() {
    return this.http.get("base_value/?type=grc_audit_type");
  }

  getFilteredAuditTemplates(
    type: any,
    name: any,
    applicability: any,
    tags: any,
    owner: any
  ) {
    return this.http.get(
      `grc/audit-templates/?type=${type}&title=${name}&applicability=${applicability}&tags=${tags}&created_by=${owner}`
    );
  }

  createAuditInstance(payload: any) {
    return this.http.post("grc/audit-instances/", payload);
  }

  getAuditInstances() {
    return this.http.get("grc/audit-instances/");
  }

  getAuditInstancesByProjectId(projectId: any) {
    return this.http.get(`grc/audit-instances/?project=${projectId}`);
  }

  getInstanceInfo(instanceId: any) {
    return this.http.get(`grc/audit-instances/${instanceId}/`);
  }

  getSeverityReport(instanceId: any) {
    return this.http.get(
      `/grc/audit-instances/severity-report?id=${instanceId}`
    );
  }

  downloadResponseTemplate(templateId: any) {
    return this.http.get(
      `grc/audit-templates/${templateId}/response-template`,
      { responseType: "blob" }
    );
  }

  getIncidentStatus() {
    return this.http.get("base_value/?type=grc_incident_status");
  }

  getIncidentSeverity() {
    return this.http.get("base_value/?type=grc_incident_severity");
  }

  editInstanceResponse(responseId: any, payload: any) {
    return this.http.patch(`grc/audit-responses/${responseId}/`, payload);
  }

  uploadEvidence(responseId: any, payload: any) {
    return this.http.post(
      `grc/audit-responses/${responseId}/evidences/upload/`,
      payload
    );
  }

  downloadEvidence(instanceId: any) {
    return this.http.get(
      `grc/audit-responses/${instanceId}/evidences/download/`,
      {
        responseType: "blob",
      }
    );
  }

  getFilteredAuditInstances(
    type: any,
    name: any,
    status: any,
    portfolio: any,
    subportfolio: any,
    program: any,
    project: any,
    vendor: any,
    tags: any
  ) {
    return this.http.get(
      `grc/audit-instances/?type=${type}&title=${name}&status=${status}&portfolio=${portfolio}&sub_portfolio=${subportfolio}&program=${program}&project=${project}&vendor=${vendor}&tags=${tags}`
    );
  }

  getFilteredIncidents(
    title: any,
    portfolio: any,
    subportfolio: any,
    program: any,
    project: any,
    vendor: any
  ) {
    return this.http.get(
      `grc/incident/?title=${title}&portfolio=${portfolio}&sub_portfolio=${subportfolio}&program=${program}&project=${project}&vendor=${vendor}`
    );
  }

  getAuditInsights() {
    return this.http.get("grc/audit-instances/insights/");
  }
}
