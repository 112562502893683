import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import * as textConfiguration from "src/assets/static-text-configuration.json";

import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { FilterService } from "../../services/filter.service";
import { ProjectService } from "../../services/project.service";
import { CommonService } from "../../services/common.service";
@Component({
  selector: "app-filter-card",
  providers: [DatePipe],
  templateUrl: "./filter-card.component.html",
  styleUrls: ["./filter-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FilterCardComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Input() pagetype: any;
  @Input() queryParam: any = "";
  @Input() pageTab: any = "";
  isFilterhasValue: boolean = false;

  portFolios: any;
  portfolioList: any;

  filteredPortfolioList: any;
  filterForm: FormGroup | any;

  filteredSubPortfolioList: any;
  subPortfolioList: any;

  filteredProgramList: any;
  programList: any;

  filteredOpportunityList: any;
  oppurtunityList: any;

  filteredOppStatusList: any;
  oppStatusList: any;

  filteredProjectList: any;
  projectList: any;

  filteredOKRObjList: any;
  OKRObjList: any;

  filteredOKRKeyResultList: any;
  OKRKeyResultList: any;

  filteredOKRGoalList: any;
  OKRGoalList: any;

  filteredVendorList: any;
  vendorList: any;

  filteredTagList: any;
  tagList: any;

  FilteredapplicationMasterFilterList: any;
  applicationMasterList: any;

  FilteredWorkForceMonthFilterList: any;
  workforceMonthList: any = this.staticText?.delivery?.month_list;

  FilteredWorkforceFilterList: any;
  workforceList: any;

  FilteredOwnerFilterList: any;
  ownerList: any;

  FilteredWorkgroupFilterList: any;
  workgroupList: any;

  FilteredFinancialsSpendMonthFilterList: any;
  financialsSpendMonthList: any = this.staticText?.delivery?.month_list;

  FilterDomainList: any;
  domainList: any;

  FilterRiskDomainList: any;
  riskDomainList: any;

  FilterRiskNameList: any;
  riskNameList: any;

  FilterSubDomainList: any;
  subDomainList: any;

  FilterRiskSubDomainList: any;
  subRiskDomainList: any;

  filteredOKROwnerList: any;
  OKROwnerList: any;

  filteredMOSList: any;
  MOSList: any;

  FilterRiskResponseList: any = [
    { id: "Accept", name: "Accept" },
    { id: "Mitigate", name: "Mitigate" },
    { id: "Transfer", name: "Transfer" },
  ];
  riskResponseList: any = [
    { id: "Accept", name: "Accept" },
    { id: "Mitigate", name: "Mitigate" },
    { id: "Transfer", name: "Transfer" },
  ];

  FilterRiskStatusList: any = [
    { id: 16, name: "Open" },
    { id: 39, name: "Closed" },
  ];
  riskStatusList: any = [
    { id: 16, name: "Open" },
    { id: 39, name: "Closed" },
  ];
  displayLabels: any;
  userDetails: any;

  constructor(
    private projectService: ProjectService,
    private filterService: FilterService,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getDisplayLables();
    this.userDetails = JSON.parse(localStorage.getItem("permission") || "{}");
    this.filterForm = this.formBuilder.group({
      portfolio: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      subportfolio: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      program: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      opportunity: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      project: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      vendor: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tag: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      oppStatus: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      applicationTier: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      applicationSupportGroup: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      applicationBusinessOwner: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      applicationBusinessArea: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      workforceMonth: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      financialsSpendMonth: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      workforceJobCategory: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      workforceClassification: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      workforcePersonas: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      workforceCountry: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      owner: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      workgroup: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      domain: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      subdomain: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      riskDomain: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      riskSubdomain: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      riskName: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      riskresponse: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      riskstatus: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      okrobj: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      okrkr: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      okrgoal: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      okrPrimaryOwner: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      okrSecondaryOwner: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      okrEndDate: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      okrMOS: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      mavenTitle: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),

      pcmTitle: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      pcmCategory: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      pcmLocation: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tcpCostPool: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tcpCostSubPool: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tcpExpenseType: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      titTowerName: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      titSubTowerName: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      titSubTowerElement: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      titDelivery: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      titUnitMeasure: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tscServiceName: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tscServiceType: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tscServiceCategory: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tsnName: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tsnSolutionType: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tsnCategory: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
      tsnOffering: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(""),
      }),
    });

    if (
      this.pagetype == "opportunity" ||
      this.pagetype == "project" ||
      this.pagetype == "deal"
    ) {
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
      this.getVendor();
      this.getTag();
    }
    if (this.pagetype == "opportunity" || this.pagetype == "deal") {
      this.getOpportunity();
      this.getOppStatus();
    }
    if (this.pagetype == "OKR-dasboard") {
      this.getOpportunity();
      this.getProject();
      this.getObjectives();
      this.getKeyResult();
      this.getGoals();
    }
    if (this.pagetype == "OKR-register") {
      this.getObjectives();
      this.getKeyResult();
      this.getGoals();
      this.getTag();
      this.getOwners();
      this.getMos();
    }
    if (this.pagetype == "project") {
      this.getProject();
    }

    if (this.pagetype == "application") {
      this.getApplicationsFilters();
    }
    if (this.pagetype == "workforce") {
      this.getWorkforceFilters();
      this.getVendor();
    }
    if (this.pagetype == "riskregister") {
      this.getTag();
      this.getRiskDomain();
      this.getRiskNames();
      this.getRiskSubDomain();
    }
    if (this.pagetype == "financials-spend") {
      this.getFinancialsFilters();
    }
    if (this.pagetype == "people-insights") {
      this.getWorkforceFilters();
      this.getVendor();
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
    }
    if (this.pagetype == "balance-scorecard") {
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
      this.getVendor();
    }
    if (
      this.pagetype == "crowdsourcing" ||
      this.pagetype == "assessment-template" ||
      this.pagetype == "plan-central" ||
      this.pagetype == "governance-assessment-template" ||
      this.pagetype == "maven"
    ) {
      // this.getPortfolios();
      // this.getSubPortfolios();
      // this.getProgram();
      this.getTag();
    }

    if (this.pagetype == "parade") {
      this.getTag();
    }

    if (this.pagetype == "crowdsourcing-workbench") {
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
      this.getTag();
    }
    if (this.pagetype == "riskcoverage") {
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
      this.getProject();
      this.getRiskDomain();
      this.getRiskSubDomain();
    }

    this.filterService.setValue("");
  }

  get portfolioArray(): FormArray {
    return this.filterForm.get("portfolio").get("array") as FormArray;
  }

  addPortfolioArrayControl(control: any) {
    this.portfolioArray.push(new FormControl(control));
  }

  get subportfolioArray(): FormArray {
    return this.filterForm.get("subportfolio").get("array") as FormArray;
  }

  addSubportfolioArrayControl(control: any) {
    this.subportfolioArray.push(new FormControl(control));
  }

  get programArray(): FormArray {
    return this.filterForm.get("program").get("array") as FormArray;
  }

  addProgramArrayControl(control: any) {
    this.programArray.push(new FormControl(control));
  }

  get projectArray(): FormArray {
    return this.filterForm.get("project").get("array") as FormArray;
  }

  addProjectArrayControl(control: any) {
    this.projectArray.push(new FormControl(control));
  }

  getDisplayLables() {
    this.displayLabels = this.commonService.getLabels();
  }

  setQueryParam(field: any) {
    if (this.queryParam[field]) {
      let obj = [];
      if (field == "portfolio") {
        obj = this.portfolioList.filter(
          (e: any) => e.id == Number(this.queryParam[field])
        );
      } else if (field == "subportfolio") {
        obj = this.subPortfolioList.filter(
          (e: any) => e.id == Number(this.queryParam[field])
        );
      }
      if (field == "program") {
        obj = this.programList.filter(
          (e: any) => e.id == Number(this.queryParam[field])
        );
      }
      this.filterForm.value[field].array = obj;
      this.onOptionSelectionChanged(field);
    } else if (
      this.filterForm.value[field].array.length > 0 &&
      Object.keys(this.queryParam).length == 0
    ) {
      this.filterForm.value[field].array.value = [];
      this.filterForm.controls[field].controls["input"].setValue("");
      this.onOptionSelectionChanged(field);
    }
  }

  async getPortfolios() {
    await this.filterService.getPortfolios().subscribe((res: any) => {
      this.filteredPortfolioList = res.records;
      this.portfolioList = res.records;
      if (
        (this.pagetype === "balance-scorecard" ||
          this.pagetype === "riskcoverage") &&
        !this.userDetails.is_superuser &&
        this.userDetails.owner_level === "portfolio"
      ) {
        this.filteredPortfolioList = res.records.filter(
          (rec: any) => rec.id === this.userDetails.owner_entity_id
        );
        this.portfolioList = res.records.filter(
          (rec: any) => rec.id === this.userDetails.owner_entity_id
        );
        setTimeout(() => {
          while (this.portfolioArray.length !== 0) {
            this.portfolioArray.removeAt(0);
          }
          this.addPortfolioArrayControl(this.filteredPortfolioList[0]);
          this.filterForm.patchValue({
            portfolio: {
              input: this.filteredPortfolioList[0],
            },
          });
          this.onOptionSelectionChanged("portfolio");
        }, 0);
      }
    });
  }
  async getSubPortfolios() {
    this.filteredSubPortfolioList = [];
    this.subPortfolioList = [];
    await this.filterService
      .getSubPortfolios(this.filterForm.value.portfolio.input.id)
      .subscribe((res: any) => {
        if (this.filterForm.value.portfolio.input.id) {
          res = res.records;
        }
        this.filteredSubPortfolioList = res;
        this.subPortfolioList = res;
        if (
          (this.pagetype === "balance-scorecard" ||
            this.pagetype === "riskcoverage") &&
          !this.userDetails.is_superuser &&
          this.userDetails.owner_level === "sub_portfolio"
        ) {
          this.filteredSubPortfolioList = res.filter(
            (rec: any) => rec.id === this.userDetails.owner_entity_id
          );
          this.subPortfolioList = res.filter(
            (rec: any) => rec.id === this.userDetails.owner_entity_id
          );
          setTimeout(() => {
            while (this.subportfolioArray.length !== 0) {
              this.subportfolioArray.removeAt(0);
            }
            this.addSubportfolioArrayControl(this.filteredSubPortfolioList[0]);
            this.filterForm.patchValue({
              subportfolio: {
                input: this.filteredSubPortfolioList[0],
              },
            });
            this.onOptionSelectionChanged("subportfolio");
          }, 0);
        }
        this.setQueryParam("subportfolio");
      });
  }

  async getProgram() {
    await this.filterService
      .getProgram(this.filterForm.value.subportfolio.input.id)
      .subscribe((res: any) => {
        if (this.filterForm.value.subportfolio.input.id) {
          res = res.records;
        }
        this.programList = res;
        this.programList = this.programList.map((val: any) => ({
          id: val.id,
          manager: val.manager,
          name: val.name,
          level: "program",
        }));
        this.filteredProgramList = this.programList;
        if (
          (this.pagetype === "balance-scorecard" ||
            this.pagetype === "riskcoverage") &&
          !this.userDetails.is_superuser &&
          this.userDetails.owner_level === "program"
        ) {
          this.programList = res.filter(
            (rec: any) => rec.id === this.userDetails.owner_entity_id
          );
          this.programList = this.programList.map((val: any) => ({
            id: val.id,
            manager: val.manager,
            name: val.name,
            level: "program",
          }));
          this.filteredProgramList = this.programList;
          setTimeout(() => {
            while (this.programArray.length !== 0) {
              this.programArray.removeAt(0);
            }
            this.addProgramArrayControl(this.filteredProgramList[0]);
            this.filterForm.patchValue({
              program: {
                input: this.filteredProgramList[0],
              },
            });
            this.onOptionSelectionChanged("program");
          }, 0);
        }
        this.setQueryParam("program");
      });
  }
  async getOpportunity() {
    await this.filterService
      .getOpportunity(
        this.filterForm.value.subportfolio.input.id,
        this.filterForm.value.program.input.id
      )
      .subscribe((res: any) => {
        if (
          this.filterForm.value.subportfolio.input.id &&
          this.filterForm.value.program.input.id
        ) {
          res = res.records;
        }
        res.forEach((element: any) => {
          element.name = element.opportunity_name;
        });
        this.filteredOpportunityList = res;
        this.oppurtunityList = res;
      });
  }
  async getOppStatus() {
    await this.filterService
      .getOppStatus(this.pagetype)
      .subscribe((res: any) => {
        res.forEach((element: any) => {
          element.name = element.label;
        });
        res = res.filter((item: any) => item.label !== "Cancelled");
        this.filteredOppStatusList = res;
        this.oppStatusList = res;
      });
  }
  async getProject() {
    await this.filterService
      .getProject(
        this.filterForm.value.subportfolio.input.id,
        this.filterForm.value.program.input.id
      )
      .subscribe((res: any) => {
        if (
          this.filterForm.value.subportfolio.input.id &&
          this.filterForm.value.program.input.id
        ) {
          res = res.records;
        }
        res.forEach((element: any) => {
          element.name = element.project_name;
        });
        this.filteredProjectList = res;
        this.projectList = res;
        if (
          this.pagetype === "riskcoverage" &&
          !this.userDetails.is_superuser &&
          this.userDetails.owner_level === "project"
        ) {
          this.projectList = res.filter(
            (rec: any) => rec.id === this.userDetails.owner_entity_id
          );
          this.filteredProjectList = this.projectList;
          setTimeout(() => {
            while (this.projectArray.length !== 0) {
              this.projectArray.removeAt(0);
            }
            this.addProjectArrayControl(this.filteredProjectList[0]);
            this.filterForm.patchValue({
              project: {
                input: this.filteredProjectList[0],
              },
            });
            this.onOptionSelectionChanged("project");
          }, 0);
        }
      });
  }
  async getObjectives() {
    await this.filterService.getObjectives().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.title;
      });
      this.filteredOKRObjList = res;
      this.OKRObjList = res;
    });
  }

  async getKeyResult() {
    await this.filterService.getKeyResult().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.title;
      });
      this.filteredOKRKeyResultList = res;
      this.OKRKeyResultList = res;
    });
  }

  async getGoals() {
    await this.filterService.getGoals().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.goal_name;
      });
      this.filteredOKRGoalList = res;
      this.OKRGoalList = res;
    });
  }

  async getDomain() {
    await this.filterService.getDomain().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.display_name;
      });
      this.FilterDomainList = res;
      this.domainList = res;
    });
  }

  async getRiskDomain() {
    await this.filterService.getRiskDomain().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.display_name;
      });
      this.FilterRiskDomainList = res;
      this.riskDomainList = res;
    });
  }

  async getRiskNames() {
    await this.filterService.getRisks().subscribe((res: any) => {
      // res.forEach((element: any) => {
      //   element.name = element.name;
      // });
      this.FilterRiskNameList = res;
      this.riskNameList = res;
    });
  }

  async getSubDomain() {
    await this.filterService.getSubDomain().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.display_name;
      });
      this.FilterSubDomainList = res;
      this.subDomainList = res;
    });
  }

  async getRiskSubDomain() {
    await this.filterService.getRiskSubDomain().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.display_name;
      });
      this.FilterRiskSubDomainList = res;
      this.subRiskDomainList = res;
    });
  }

  async getVendor() {
    await this.filterService.getVendor().subscribe((res: any) => {
      this.filteredVendorList = res;
      this.vendorList = res;
    });
  }
  async getTag() {
    await this.filterService.getTag().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.tag_name;
      });
      this.filteredTagList = res;
      this.tagList = res;
    });
  }

  async getOwners() {
    let users: any = [];
    await this.filterService.getOwners().subscribe((res: any) => {
      res.forEach((element: any, index: any) => {
        if (element.first_name || element.last_name) {
          element.name = element.first_name + " " + element.last_name;
          users.push(element);
        }
      });
      this.filteredOKROwnerList = users;
      this.OKROwnerList = users;
    });
  }

  async getMos() {
    let mos: any = [];
    await this.filterService.getMOS().subscribe((res: any) => {
      // res.forEach((element:any) => {
      //   if(element.instancemetrics){
      //     element.name = element.instancemetrics;
      //     mos.push(element);
      //   }
      // });
      this.filteredMOSList = res;
      this.MOSList = res;
    });
  }

  getPortfolioName(portfolioId: any) {
    if (portfolioId) {
      return this.portfolioList.find(
        (item: any) => item.id === Number(portfolioId)
      ).name;
    }
  }

  getSubPortfolioName(subPortfolioId: any) {
    if (subPortfolioId) {
      return this.subPortfolioList.find(
        (item: any) => item.id === Number(subPortfolioId)
      ).display_name;
    }
  }

  getProgramName(programId: any) {
    if (programId) {
      return this.programList.find((item: any) => item.id === Number(programId))
        .name;
    }
  }

  getOpportunityName(opportunityId: any) {
    if (opportunityId) {
      return this.oppurtunityList.find((item: any) => item.id === opportunityId)
        .opportunity_name;
    }
  }

  getProjectName(projectId: any) {
    if (projectId) {
      return this.projectList.find((item: any) => item.id === projectId)
        .project_name;
    }
  }

  getVendorName(vendorId: any) {
    if (vendorId) {
      return this.vendorList.find((item: any) => item.id === vendorId).name;
    }
  }

  getTagName(tagId: any) {
    if (tagId) {
      return this.tagList.find((item: any) => item.id === tagId).tag_name;
    }
  }

  getDomainName(domaonId: any) {
    if (domaonId) {
      return this.domainList.find((item: any) => item.id === domaonId)
        .display_name;
    }
  }

  getSubDomainName(subdomaonId: any) {
    if (subdomaonId) {
      return this.subDomainList.find((item: any) => item.id === subdomaonId)
        .display_name;
    }
  }

  getRiskResponseName(response: any) {
    if (response) {
      return this.riskResponseList.find((item: any) => item.id === response)
        .name;
    }
  }

  getRiskStatusName(status: any) {
    if (status) {
      return this.riskStatusList.find((item: any) => item.id === status).name;
    }
  }

  searchSubPortfolio() {
    return this.getSubPortfolioList();
  }

  searchProgram() {
    return this.getProgramList();
  }

  searchOpportunity() {
    return this.getOpportunityList();
  }

  searchProject() {
    return this.getProjectList();
  }

  searchVendor() {
    return this.getVendorList();
  }

  searchTag() {
    return this.getTagList();
  }

  searchDomain() {
    return this.getDomainList();
  }

  searchSubDomain() {
    return this.getSubDomainList();
  }

  searchRiskResponse() {
    return this.getRiskResponseList();
  }
  searchRiskStatus() {
    return this.getRiskStatusList();
  }

  filteredList(filterObj: any, field: any) {
    if (filterObj) {
      let filteringArray = this.filterForm.value[field].array
        ? this.filterForm.value[field].array
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? filterObj.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (innerel: any) {
                  return innerel.id == array_el.id;
                }).length == 0
              );
            })
          : filterObj;
      filteredArray =
        typeof this.filterForm.value[field].input === "string"
          ? filteredArray.filter((f: any) => {
              return (
                f.name
                  .toLowerCase()
                  .indexOf(this.filterForm.value[field].input.toLowerCase()) ===
                0
              );
            })
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  filteredListNoName(filterObj: any, field: any) {
    if (filterObj) {
      let filteringArray = this.filterForm.value[field].array
        ? this.filterForm.value[field].array
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? filterObj.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (innerel: any) {
                  return innerel.id == array_el.id;
                }).length == 0
              );
            })
          : filterObj;

      filteredArray =
        typeof this.filterForm.value[field].input === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.display_name
                  .toLowerCase()
                  .indexOf(this.filterForm.value[field].input.toLowerCase()) ===
                0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  search(
    event: MatAutocompleteSelectedEvent,
    field: any,
    isInput = false
  ): void {
    if (!isInput) {
      this.filterForm.value[field].array.push(event.option.value);
    } else {
      this.filterForm.value[field].input = event;
    }
    this.onOptionSelectionChanged(field);
  }

  searchDate(event: any, field: any): void {
    let date = this.datepipe.transform(event, "yyyy-MM-dd");
    this.filterForm.value[field].array.push(date);
    this.onOptionSelectionChanged(field);
  }

  remove(val: string, field: any): void {
    const index = this.filterForm.value[field].array.indexOf(val);
    if (index >= 0) {
      this.filterForm.value[field].array.splice(index, 1);
      this.filterService.setValue(this.filterForm.value);
      if (!this.filterForm.value[field].array.length) {
        this.filterForm.value[field].input = "";
      }
    }
    this.groupHasValues(this.filterForm);
    if (field == "portfolio") {
      this.getSubPortfolios();
    }
    if (field == "subportfolio") {
      this.getProgram();
    }
    if (
      (field == "program" && this.pagetype == "opportunity") ||
      (field == "program" && this.pagetype == "deal")
    ) {
      this.getOpportunity();
    }
    if (field == "program" && this.pagetype == "project") {
      this.getProject();
    }
  }

  clearSearchField(field: any) {
    this.filterForm.controls[field].controls["array"].setValue([]);
    this.onOptionSelectionChanged(field);
    this.groupHasValues(this.filterForm);
  }

  onOptionSelectionChanged(field: any = null) {
    if (field == "portfolio") {
      this.getSubPortfolios();
    }
    if (field == "subportfolio") {
      this.getProgram();
    }
    if (
      (field == "program" && this.pagetype == "opportunity") ||
      (field == "program" && this.pagetype == "deal")
    ) {
      this.getOpportunity();
    }
    if (field == "program" && this.pagetype == "project") {
      this.getProject();
    }

    for (var key in this.filterForm.value) {
      if (key != field) {
        // this.filterForm.controls[key].controls['array'].setValue([]);
        // this.filterForm.controls[key].controls['input'].setValue("");
      }
    }
    this.filterService.setFilterField(field);
    this.filterService.setValue(this.filterForm.value);
    this.groupHasValues(this.filterForm);
  }

  // getPortfolioList(){
  //   if(this.portfolioList){
  //     let filteringArray = this.filterForm.value.portfolio.value ? this.filterForm.value.portfolio.value : [];
  //     var filteredArray  = filteringArray.length > 0 ? this.filteredPortfolioList.filter(function(array_el: any){
  //       return filteringArray.filter(function(chl: any){
  //           return chl.portfolio.value == array_el.id;
  //       }).length == 0
  //     }) : this.portfolioList;

  //     filteredArray = typeof this.filterForm.value.portfolio === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.filterForm.value.portfolio.toLowerCase()) === 0) : filteredArray;
  //     return filteredArray;
  //   }else{
  //     return [];
  //   }
  // }

  getSubPortfolioList() {
    if (this.subPortfolioList) {
      let filteringArray = this.filterForm.value.subportfolio.value
        ? this.filterForm.value.subportfolio.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredSubPortfolioList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.subportfolio.value == array_el.id;
                }).length == 0
              );
            })
          : this.subPortfolioList;

      filteredArray =
        typeof this.filterForm.value.subportfolio === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.subportfolio.toLowerCase()) ===
                0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getProgramList() {
    if (this.programList) {
      let filteringArray = this.filterForm.value.program.value
        ? this.filterForm.value.program.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredProgramList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.program.value == array_el.id;
                }).length == 0
              );
            })
          : this.programList;

      filteredArray =
        typeof this.filterForm.value.program === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.program.toLowerCase()) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getOpportunityList() {
    if (this.oppurtunityList) {
      let filteringArray = this.filterForm.value.opportunity.value
        ? this.filterForm.value.opportunity.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredOpportunityList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.opportunity.value == array_el.id;
                }).length == 0
              );
            })
          : this.oppurtunityList;

      filteredArray =
        typeof this.filterForm.value.opportunity === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.opportunity_name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.opportunity.toLowerCase()) ===
                0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getOppStatusList() {
    if (this.oppStatusList) {
      let filteringArray = this.filterForm.value.oppStatus.value
        ? this.filterForm.value.oppStatus.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredOppStatusList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.oppStatus.value == array_el.id;
                }).length == 0
              );
            })
          : this.oppStatusList;

      filteredArray =
        typeof this.filterForm.value.oppStatus === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.label
                  .toLowerCase()
                  .indexOf(this.filterForm.value.oppStatus.toLowerCase()) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getProjectList() {
    if (this.projectList) {
      let filteringArray = this.filterForm.value.project.value
        ? this.filterForm.value.project.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredProjectList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.project.value == array_el.id;
                }).length == 0
              );
            })
          : this.projectList;

      filteredArray =
        typeof this.filterForm.value.project === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.project_name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.project.toLowerCase()) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getVendorList() {
    if (this.vendorList) {
      let filteringArray = this.filterForm.value.vendor.value
        ? this.filterForm.value.vendor.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredVendorList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.vendor.value == array_el.id;
                }).length == 0
              );
            })
          : this.vendorList;

      filteredArray =
        typeof this.filterForm.value.vendor === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.vendor.toLowerCase()) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getTagList() {
    if (this.tagList) {
      let filteringArray = this.filterForm.value.tag.value
        ? this.filterForm.value.tag.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.filteredTagList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.tag.value == array_el.id;
                }).length == 0
              );
            })
          : this.tagList;

      filteredArray =
        typeof this.filterForm.value.tag === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.tag_name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.tag.toLowerCase()) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getDomainList() {
    if (this.domainList) {
      let filteringArray = this.filterForm.value.domain.value
        ? this.filterForm.value.domain.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilterDomainList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.domain.value == array_el.id;
                }).length == 0
              );
            })
          : this.domainList;

      filteredArray =
        typeof this.filterForm.value.domain === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.display_name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.domain.toLowerCase()) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getSubDomainList() {
    if (this.subDomainList) {
      let filteringArray = this.filterForm.value.subdomain.value
        ? this.filterForm.value.subdomain.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilterSubDomainList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.subdomain.value == array_el.id;
                }).length == 0
              );
            })
          : this.subDomainList;

      filteredArray =
        typeof this.filterForm.value.subdomain === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.display_name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.subdomain.toLowerCase()) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getRiskResponseList() {
    if (this.riskResponseList) {
      let filteringArray = this.filterForm.value.riskresponse.value
        ? this.filterForm.value.riskresponse.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilterRiskResponseList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.riskresponse.value == array_el.id;
                }).length == 0
              );
            })
          : this.riskResponseList;

      filteredArray =
        typeof this.filterForm.value.riskresponse === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.riskresponse.toLowerCase()) ===
                0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getRiskStatusList() {
    if (this.riskStatusList) {
      let filteringArray = this.filterForm.value.riskstatus.value
        ? this.filterForm.value.riskstatus.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilterRiskStatusList.filter(function (array_el: any) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.riskstatus.value == array_el.id;
                }).length == 0
              );
            })
          : this.riskStatusList;

      filteredArray =
        typeof this.filterForm.value.riskstatus === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.name
                  .toLowerCase()
                  .indexOf(this.filterForm.value.riskstatus.toLowerCase()) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  //Application Master code

  async getApplicationsFilters() {
    await this.filterService.getApplicationFilters().subscribe((res: any) => {
      let unique_tiers: any = [];
      res.unique_tiers.forEach((element: any) => {
        unique_tiers.push({
          id: element,
          display_name: element,
        });
      });
      let unique_support_groups: any = [];
      res.unique_support_groups.forEach((element: any) => {
        unique_support_groups.push({
          id: element,
          display_name: element,
        });
      });
      let unique_business_owners: any = [];
      res.unique_business_owners.forEach((element: any) => {
        unique_business_owners.push({
          id: element,
          display_name: element,
        });
      });
      let unique_business_areas: any = [];
      res.unique_business_areas.forEach((element: any) => {
        unique_business_areas.push({
          id: element,
          display_name: element,
        });
      });
      this.FilteredapplicationMasterFilterList = {
        unique_tiers: unique_tiers,
        unique_support_groups: unique_support_groups,
        unique_business_owners: unique_business_owners,
        unique_business_areas: unique_business_areas,
      };

      this.applicationMasterList = this.FilteredapplicationMasterFilterList;
    });
  }
  //Applcation Tier
  searchApplicationsTier() {
    return this.getApplicationTierList();
  }

  getApplicationTierList() {
    if (this.applicationMasterList?.unique_tiers?.length > 0) {
      let filteringArray = this.filterForm.value.applicationTier.value
        ? this.filterForm.value.applicationTier.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredapplicationMasterFilterList?.unique_tiers.filter(
              function (array_el: any) {
                return (
                  filteringArray.filter(function (chl: any) {
                    return chl.applicationTier.value == array_el;
                  }).length == 0
                );
              }
            )
          : this.applicationMasterList?.unique_tiers;

      filteredArray =
        typeof this.filterForm.value.applicationTier === "string"
          ? filteredArray.filter(
              (f: any) =>
                f
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.applicationTier.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getApplicationsTier(tierName: any) {
    if (tierName) {
      return tierName;
    }
  }

  //Support Group
  searchApplicationsSupportGroup() {
    return this.getApplicationSupportGroupList();
  }

  getApplicationSupportGroupList() {
    if (this.applicationMasterList?.unique_support_groups?.length > 0) {
      let filteringArray = this.filterForm.value.applicationSupportGroup.value
        ? this.filterForm.value.applicationSupportGroup.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredapplicationMasterFilterList?.unique_support_groups.filter(
              function (array_el: any) {
                return (
                  filteringArray.filter(function (chl: any) {
                    return chl.applicationSupportGroup.value == array_el;
                  }).length == 0
                );
              }
            )
          : this.applicationMasterList?.unique_support_groups;

      filteredArray =
        typeof this.filterForm.value.applicationSupportGroup === "string"
          ? filteredArray.filter(
              (f: any) =>
                f
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.applicationSupportGroup.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getApplicationsSupportGroup(supportGroupName: any) {
    if (supportGroupName) {
      return supportGroupName;
    }
  }
  //Business owners
  searchApplicationsBusinessOwner() {
    return this.getApplicationBusinessOwnerList();
  }

  getApplicationBusinessOwnerList() {
    if (this.applicationMasterList?.unique_business_owners?.length > 0) {
      let filteringArray = this.filterForm.value.applicationBusinessOwner.value
        ? this.filterForm.value.applicationBusinessOwner.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredapplicationMasterFilterList?.unique_business_owners.filter(
              function (array_el: any) {
                return (
                  filteringArray.filter(function (chl: any) {
                    return chl.applicationBusinessOwner.value == array_el;
                  }).length == 0
                );
              }
            )
          : this.applicationMasterList?.unique_business_owners;

      filteredArray =
        typeof this.filterForm.value.applicationBusinessOwner === "string"
          ? filteredArray.filter(
              (f: any) =>
                f
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.applicationBusinessOwner.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getApplicationsBusinessOwner(BusinessOwner: any) {
    if (BusinessOwner) {
      return BusinessOwner;
    }
  }
  //Business Area
  searchApplicationsBusinessArea() {
    return this.getApplicationBusinessAreaList();
  }

  getApplicationBusinessAreaList() {
    if (this.applicationMasterList?.unique_business_areas?.length > 0) {
      let filteringArray = this.filterForm.value.applicationBusinessArea.value
        ? this.filterForm.value.applicationBusinessArea.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredapplicationMasterFilterList?.unique_business_areas.filter(
              function (array_el: any) {
                return (
                  filteringArray.filter(function (chl: any) {
                    return chl.applicationBusinessArea.value == array_el;
                  }).length == 0
                );
              }
            )
          : this.applicationMasterList?.unique_business_areas;

      filteredArray =
        typeof this.filterForm.value.applicationBusinessArea === "string"
          ? filteredArray.filter(
              (f: any) =>
                f
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.applicationBusinessArea.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getApplicationsBusinessArea(BusinessArea: any) {
    if (BusinessArea) {
      return BusinessArea;
    }
  }
  //workforce filter Started

  async getWorkforceFilters() {
    await this.filterService.getWorkforceFilters().subscribe((res: any) => {
      let job_category: any = [];
      res.job_category.forEach((element: any) => {
        job_category.push({
          id: element,
          display_name: element,
        });
      });
      let classification: any = [];
      res.classification.forEach((element: any) => {
        classification.push({
          id: element,
          display_name: element,
        });
      });
      let country: any = [];
      res.country.forEach((element: any) => {
        country.push({
          id: element,
          display_name: element,
        });
      });
      let personas: any = [];
      res.personas.forEach((element: any) => {
        personas.push({
          id: element,
          display_name: element,
        });
      });
      this.FilteredWorkforceFilterList = {
        job_category: job_category,
        classification: classification,
        country: country,
        personas: personas,
      };

      this.workforceList = this.FilteredWorkforceFilterList;
    });
  }

  //Workforce filter for month
  searchWorkforceMonth() {
    return this.getWorkforceMonthList();
  }

  getWorkforceMonthList() {
    if (this.workforceMonthList?.length > 0) {
      let filteringArray = this.filterForm.value.workforceMonth.value
        ? this.filterForm.value.workforceMonth.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredWorkForceMonthFilterList.filter(function (
              array_el: any
            ) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.workforceMonth.value == array_el.id;
                }).length == 0
              );
            })
          : this.workforceMonthList;

      filteredArray =
        typeof this.filterForm.value.workforceMonth === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.display_name
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.workforceMonth.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getWorkforceMonth(monthId: any) {
    if (monthId) {
      return this.workforceMonthList.find((item: any) => item.id === monthId)
        .display_name;
    }
  }

  //Workforce filter for Job Category
  searchWorkforceJobCategory() {
    return this.getWorkforceJobCategoryList();
  }

  getWorkforceJobCategoryList() {
    if (this.workforceList?.job_category?.length > 0) {
      let filteringArray = this.filterForm.value.workforceJobCategory.value
        ? this.filterForm.value.workforceJobCategory.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredWorkforceFilterList?.job_category.filter(function (
              array_el: any
            ) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.workforceJobCategory.value == array_el;
                }).length == 0
              );
            })
          : this.workforceList?.job_category;

      filteredArray =
        typeof this.filterForm.value.workforceJobCategory === "string"
          ? filteredArray.filter(
              (f: any) =>
                f
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.workforceJobCategory.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getWorkforceJobCategory(jobCategory: any) {
    if (jobCategory) {
      return jobCategory;
    }
  }

  //Workforce filter for Classification
  searchWorkforceClassification() {
    return this.getWorkforceClassificationList();
  }

  getWorkforceClassificationList() {
    if (this.workforceList?.classification?.length > 0) {
      let filteringArray = this.filterForm.value.workforceClassification.value
        ? this.filterForm.value.workforceClassification.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredWorkforceFilterList?.classification.filter(function (
              array_el: any
            ) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.workforceClassification.value == array_el;
                }).length == 0
              );
            })
          : this.workforceList?.classification;

      filteredArray =
        typeof this.filterForm.value.workforceClassification === "string"
          ? filteredArray.filter(
              (f: any) =>
                f
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.workforceClassification.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getWorkforceClassification(classification: any) {
    if (classification) {
      return classification;
    }
  }

  //Workforce filter for Country
  searchWorkforceCountry() {
    return this.getWorkforceCountryList();
  }

  getWorkforceCountryList() {
    if (this.workforceList?.country?.length > 0) {
      let filteringArray = this.filterForm.value.workforceCountry.value
        ? this.filterForm.value.workforceCountry.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredWorkforceFilterList?.country.filter(function (
              array_el: any
            ) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.workforceCountry.value == array_el;
                }).length == 0
              );
            })
          : this.workforceList?.country;

      filteredArray =
        typeof this.filterForm.value.workforceCountry === "string"
          ? filteredArray.filter(
              (f: any) =>
                f
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.workforceCountry.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getWorkforceCountry(country: any) {
    if (country) {
      return country;
    }
  }

  searchWorkforcePersonas() {
    return this.getWorkforcePersonasList();
  }

  getWorkforcePersonasList() {
    if (this.workforceList?.personas?.length > 0) {
      let filteringArray = this.filterForm.value.workforcePersonas.value
        ? this.filterForm.value.workforcePersonas.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredWorkforceFilterList?.personas.filter(function (
              array_el: any
            ) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.workforcePersonas.value == array_el;
                }).length == 0
              );
            })
          : this.workforceList?.personas;

      filteredArray =
        typeof this.filterForm.value.workforcePersonas === "string"
          ? filteredArray.filter(
              (f: any) =>
                f
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.workforcePersonas.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }
  getWorkforcePersonas(personas: any) {
    if (personas) {
      return personas;
    }
  }

  getFinancialsFilters() {
    return [];
  }

  searchFinancialSpendMonth() {
    return this.getWorkforceMonthList();
  }

  getFinancialSpendMonthList() {
    if (this.financialsSpendMonthList?.length > 0) {
      let filteringArray = this.filterForm.value.financialsSpendMonth.value
        ? this.filterForm.value.financialsSpendMonth.value
        : [];
      var filteredArray =
        filteringArray.length > 0
          ? this.FilteredFinancialsSpendMonthFilterList.filter(function (
              array_el: any
            ) {
              return (
                filteringArray.filter(function (chl: any) {
                  return chl.financialsSpendMonth.value == array_el.id;
                }).length == 0
              );
            })
          : this.financialsSpendMonthList;

      filteredArray =
        typeof this.filterForm.value.financialsSpendMonth === "string"
          ? filteredArray.filter(
              (f: any) =>
                f.display_name
                  .toLowerCase()
                  .indexOf(
                    this.filterForm.value.financialsSpendMonth.toLowerCase()
                  ) === 0
            )
          : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getFinancialSpendMonth(monthId: any) {
    if (monthId) {
      return this.financialsSpendMonthList.find(
        (item: any) => item.id === monthId
      ).display_name;
    }
  }

  groupHasValues(formBuilderGroup: any) {
    let flag: any = false;
    Object.keys(formBuilderGroup.value).forEach((key: any) => {
      if (formBuilderGroup.value[key]["array"].length > 0) {
        flag = true;
        this.isFilterhasValue = true;
      }
    });
    if (flag == false) {
      this.isFilterhasValue = false;
    }
  }

  clearAllFilter() {
    for (var key in this.filterForm.value) {
      this.filterForm.controls[key].controls["array"].setValue([]);
      this.filterForm.controls[key].controls["input"].setValue("");
    }
    this.filterService.setFilterField("");
    this.filterService.setValue(this.filterForm.value);
    this.isFilterhasValue = false;
  }
}
