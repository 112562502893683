<div
  class="filter-holder"
  [ngStyle]="{
    'box-shadow':
      pagetype == 'turnkey-tracker' ||
      pagetype == 'crowdsourcing' ||
      pagetype == 'parade' ||
      pagetype == 'assessment-template' ||
      pagetype == 'governance-assessment-template' ||
      pagetype == 'plan-central'
        ? 'none'
        : ''
  }"
>
  <form [formGroup]="filterForm">
    <div style="display: flex">
      <div
        *ngIf="
          pagetype !== 'assessment-template' &&
          pagetype !== 'plan-central' &&
          pagetype !== 'governance-assessment-template'
        "
        style="width: 50px; margin-right: 5px"
      >
        <div
          class="filter-text"
          [ngStyle]="{
            height:
              pagetype == 'turnkey-tracker' ||
              pagetype == 'crowdsourcing' ||
              pagetype == 'parade'
                ? '40px'
                : ''
          }"
        >
          <span class="hld">
            <img
              *ngIf="!isFilterhasValue"
              src="./assets/images/filter-cp.png"
            />
            <img
              *ngIf="isFilterhasValue"
              class="cursor-pointer"
              title="Clear All"
              (click)="clearAllFilter()"
              src="./assets/images/clear-filter.png"
            />
          </span>
        </div>
      </div>
      <div style="flex-grow: 1">
        <div
          class="vscroll"
          id="style-3"
          *ngIf="
            pagetype == 'opportunity' ||
            pagetype == 'project' ||
            this.pagetype == 'deal'
          "
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="portfolio"
            >
              <mat-chip-list #chipList1 aria-label="Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.portfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'portfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="portfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="search($event, 'portfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredPortfolioList,
                      'portfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('portfolio')"
                *ngIf="filterForm.value.portfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="subportfolio"
            >
              <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.subportfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'subportfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.sub_portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="autoSub"
                  [matChipInputFor]="chipList2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="subportfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoSub="matAutocomplete"
                (optionSelected)="search($event, 'subportfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredSubPortfolioList,
                      'subportfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('subportfolio')"
                *ngIf="filterForm.value.subportfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="program"
            >
              <mat-chip-list #chipList3 aria-label="Sub program selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.program.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'program')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.program }}..."
                  formControlName="input"
                  [matAutocomplete]="autoPro"
                  [matChipInputFor]="chipList3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="program_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoPro="matAutocomplete"
                (optionSelected)="search($event, 'program')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredProgramList, 'program')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('program')"
                *ngIf="filterForm.value.program.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div
            class="form-item"
            *ngIf="pagetype == 'opportunity' || this.pagetype == 'deal'"
          >
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="opportunity"
            >
              <mat-chip-list #chipList4 aria-label="Sub opportunity selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.opportunity.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'opportunity')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search opportunity..."
                  formControlName="input"
                  [matAutocomplete]="autoOppo"
                  [matChipInputFor]="chipList4"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="opportunity_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoOppo="matAutocomplete"
                (optionSelected)="search($event, 'opportunity')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredOpportunityList,
                      'opportunity'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('opportunity')"
                *ngIf="filterForm.value.opportunity.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'project'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="project"
            >
              <mat-chip-list #chipList5 aria-label="Sub project selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.project.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'project')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Project..."
                  formControlName="input"
                  [matAutocomplete]="autoProject"
                  [matChipInputFor]="chipList5"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="project_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoProject="matAutocomplete"
                (optionSelected)="search($event, 'project')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredProjectList, 'project')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('project')"
                *ngIf="filterForm.value.project.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="vendor"
            >
              <mat-chip-list #chipList6 aria-label="Sub vendor selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.vendor.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'vendor')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search OU/GDP..."
                  formControlName="input"
                  [matAutocomplete]="autoVendor"
                  [matChipInputFor]="chipList6"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="vendor_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoVendor="matAutocomplete"
                (optionSelected)="search($event, 'vendor')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredVendorList, 'vendor')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('vendor')"
                *ngIf="filterForm.value.vendor.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tag"
            >
              <mat-chip-list #chipList7 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tag.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tag')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Tags..."
                  formControlName="input"
                  [matAutocomplete]="autoTag"
                  [matChipInputFor]="chipList7"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoTag="matAutocomplete"
                (optionSelected)="search($event, 'tag')"
              >
                <mat-option
                  *ngFor="let option of filteredList(filteredTagList, 'tag')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                  matTooltip="{{ option.name }} - {{ option.tag_category }}"
                >
                  <b>{{ option.name }}</b> - {{ option.tag_category }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('tag')"
                *ngIf="filterForm.value.tag.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div
            class="form-item"
            *ngIf="pagetype == 'opportunity' || this.pagetype == 'deal'"
          >
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="oppStatus"
            >
              <mat-chip-list #chipListOppStatus aria-label="Opportunity Status">
                <mat-chip
                  *ngFor="let item of filterForm?.value.oppStatus.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'oppStatus')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Status..."
                  formControlName="input"
                  [matAutocomplete]="oppStatus"
                  [matChipInputFor]="chipListOppStatus"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="oppStatus_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #oppStatus="matAutocomplete"
                (optionSelected)="search($event, 'oppStatus')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredOppStatusList,
                      'oppStatus'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                  matTooltip="{{ option.label }}"
                >
                  {{ option.label }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('oppStatus')"
                *ngIf="filterForm.value.oppStatus.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <!--Workforce Filter Start-->
        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'workforce'"
          style="width: calc(55vw - 90px)"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workforceMonth"
            >
              <mat-chip-list #chipList8 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.workforceMonth.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'workforceMonth')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Month..."
                  formControlName="input"
                  [matAutocomplete]="autoworkforceMonth"
                  [matChipInputFor]="chipList8"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="workforceMonth_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoworkforceMonth="matAutocomplete"
                (optionSelected)="search($event, 'workforceMonth')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      workforceMonthList,
                      'workforceMonth'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforceMonth')"
                *ngIf="filterForm.value.workforceMonth.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pageTab != 'attrition'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workforceJobCategory"
            >
              <mat-chip-list #chipList9 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="
                    let item of filterForm?.value.workforceJobCategory.array
                  "
                  [selectable]="selectable"
                  (removed)="remove(item, 'workforceJobCategory')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Job Category..."
                  formControlName="input"
                  [matAutocomplete]="autoworkforceJobCategory"
                  [matChipInputFor]="chipList9"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="workforceJobCategory_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoworkforceJobCategory="matAutocomplete"
                (optionSelected)="search($event, 'workforceJobCategory')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredWorkforceFilterList?.job_category,
                      'workforceJobCategory'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforceJobCategory')"
                *ngIf="filterForm.value.workforceJobCategory.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="vendor"
            >
              <mat-chip-list #chipList10 aria-label="Sub vendor selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.vendor.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'vendor')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search OU/GDP..."
                  formControlName="input"
                  [matAutocomplete]="autoVendor2"
                  [matChipInputFor]="chipList10"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="vendor_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoVendor2="matAutocomplete"
                (optionSelected)="search($event, 'vendor')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredVendorList, 'vendor')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('vendor')"
                *ngIf="filterForm.value.vendor.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pageTab != 'attrition'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workforcePersonas"
            >
              <mat-chip-list
                #chipList11
                aria-label="Sub classification selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.workforcePersonas.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'workforcePersonas')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Personas..."
                  formControlName="input"
                  [matAutocomplete]="autoworkforcePersonas"
                  [matChipInputFor]="chipList11"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="workforcePersonas_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoworkforcePersonas="matAutocomplete"
                (optionSelected)="search($event, 'workforcePersonas')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredWorkforceFilterList?.personas,
                      'workforcePersonas'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforcePersonas')"
                *ngIf="filterForm.value.workforcePersonas.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workforceCountry"
            >
              <mat-chip-list
                #chipList12
                aria-label="Sub classification selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.workforceCountry.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'workforceCountry')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Country..."
                  formControlName="input"
                  [matAutocomplete]="autoworkforceCountry"
                  [matChipInputFor]="chipList12"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="workforceCountry_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoworkforceCountry="matAutocomplete"
                (optionSelected)="search($event, 'workforceCountry')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredWorkforceFilterList?.country,
                      'workforceCountry'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforceCountry')"
                *ngIf="filterForm.value.workforceCountry.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <!--Workforce Filter End-->

        <!-- Risk Register Start-->
        <div class="vscroll" id="style-3" *ngIf="pagetype == 'riskregister'">
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="riskDomain"
            >
              <mat-chip-list #chipListdomain1 aria-label="domain selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.riskDomain.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'riskDomain')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search domain..."
                  formControlName="input"
                  [matAutocomplete]="autoDomain"
                  [matChipInputFor]="chipListdomain1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="domain_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoDomain="matAutocomplete"
                (optionSelected)="search($event, 'riskDomain')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      FilterRiskDomainList,
                      'riskDomain'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('riskDomain')"
                *ngIf="filterForm.value.riskDomain.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="riskSubdomain"
            >
              <mat-chip-list
                #chipListsubdomain1
                aria-label="Sub domain selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.riskSubdomain.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'riskSubdomain')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search sub domain..."
                  formControlName="input"
                  [matAutocomplete]="autosubdom"
                  [matChipInputFor]="chipListsubdomain1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="sub_domain_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autosubdom="matAutocomplete"
                (optionSelected)="search($event, 'riskSubdomain')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      FilterRiskSubDomainList,
                      'riskSubdomain'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('riskSubdomain')"
                *ngIf="filterForm.value.riskSubdomain.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="riskName"
            >
              <mat-chip-list #chipListRiskName aria-label="risk name selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.riskName.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'riskName')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Risks..."
                  formControlName="input"
                  [matAutocomplete]="autoRiskName"
                  [matChipInputFor]="chipListRiskName"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="risk_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoRiskName="matAutocomplete"
                (optionSelected)="search($event, 'riskName')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(FilterRiskNameList, 'riskName')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('riskName')"
                *ngIf="filterForm.value.riskName.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tag"
            >
              <mat-chip-list #chipList7 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tag.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tag')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Tags..."
                  formControlName="input"
                  [matAutocomplete]="autoTag"
                  [matChipInputFor]="chipList7"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoTag="matAutocomplete"
                (optionSelected)="search($event, 'tag')"
              >
                <mat-option
                  *ngFor="let option of filteredList(filteredTagList, 'tag')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                  matTooltip="{{ option.name }} - {{ option.tag_category }}"
                >
                  <b>{{ option.name }}</b> - {{ option.tag_category }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('tag')"
                *ngIf="filterForm.value.tag.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <!-- Risk Register End-->

        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'financials-spend'"
          style="width: calc(55vw - 90px)"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="financialsSpendMonth"
            >
              <mat-chip-list #chipList8 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="
                    let item of filterForm?.value.financialsSpendMonth.array
                  "
                  [selectable]="selectable"
                  (removed)="remove(item, 'financialsSpendMonth')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Month..."
                  formControlName="input"
                  [matAutocomplete]="autofinancialsSpendMonth"
                  [matChipInputFor]="chipList8"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="financialsSpendMonth_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autofinancialsSpendMonth="matAutocomplete"
                (optionSelected)="search($event, 'financialsSpendMonth')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      financialsSpendMonthList,
                      'financialsSpendMonth'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforceMonth')"
                *ngIf="filterForm.value.workforceMonth.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>

        <!-- Maven Filter-->
        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'maven'"
          style="width: calc(55vw - 90px)"
        >
          <div class="form-item" *ngIf="pageTab === 1">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tag"
            >
              <mat-chip-list #chipList7 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tag.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tag')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Tags..."
                  formControlName="input"
                  [matAutocomplete]="autoTag"
                  [matChipInputFor]="chipList7"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoTag="matAutocomplete"
                (optionSelected)="search($event, 'tag')"
              >
                <mat-option
                  *ngFor="let option of filteredList(filteredTagList, 'tag')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                  matTooltip="{{ option.name }} - {{ option.tag_category }}"
                >
                  <b>{{ option.name }}</b> - {{ option.tag_category }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('tag')"
                *ngIf="filterForm.value.tag.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="mavenTitle"
            >
              <input
                type="text"
                matInput
                placeholder="Search Name..."
                status="basic"
                formControlName="input"
                (ngModelChange)="search($event, 'mavenTitle', true)"
                class="filter-input"
              />
            </mat-form-field>
          </div>
        </div>
        <!--Maven Ends-->

        <!--Application Master Filter Start-->
        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'application'"
          style="width: calc(55vw - 90px)"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="applicationTier"
            >
              <mat-chip-list
                #chipList13
                aria-label="Sub application tier selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.applicationTier.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'applicationTier')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Tiers..."
                  formControlName="input"
                  [matAutocomplete]="autoapplicationTier"
                  [matChipInputFor]="chipList13"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="applicationTier_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoapplicationTier="matAutocomplete"
                (optionSelected)="search($event, 'applicationTier')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredapplicationMasterFilterList?.unique_tiers,
                      'applicationTier'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('applicationTier')"
                *ngIf="filterForm.value.applicationTier.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="applicationSupportGroup"
            >
              <mat-chip-list
                #chipList14
                aria-label="Sub support group selection"
              >
                <mat-chip
                  *ngFor="
                    let item of filterForm?.value.applicationSupportGroup.array
                  "
                  [selectable]="selectable"
                  (removed)="remove(item, 'applicationSupportGroup')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Support Group..."
                  formControlName="input"
                  [matAutocomplete]="autoapplicationSupportGroup"
                  [matChipInputFor]="chipList14"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="applicationSupportGroup_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoapplicationSupportGroup="matAutocomplete"
                (optionSelected)="search($event, 'applicationSupportGroup')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredapplicationMasterFilterList?.unique_support_groups,
                      'applicationSupportGroup'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('applicationSupportGroup')"
                *ngIf="
                  filterForm.value.applicationSupportGroup.array.length > 0
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="applicationBusinessOwner"
            >
              <mat-chip-list
                #chipList15
                aria-label="Sub Business Owners selection"
              >
                <mat-chip
                  *ngFor="
                    let item of filterForm?.value.applicationBusinessOwner.array
                  "
                  [selectable]="selectable"
                  (removed)="remove(item, 'applicationBusinessOwner')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Business Owners..."
                  formControlName="input"
                  [matAutocomplete]="autoapplicationBusinessOwner"
                  [matChipInputFor]="chipList15"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="applicationBusinessOwner_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoapplicationBusinessOwner="matAutocomplete"
                (optionSelected)="search($event, 'applicationBusinessOwner')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredapplicationMasterFilterList?.unique_business_owners,
                      'applicationBusinessOwner'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('applicationBusinessOwner')"
                *ngIf="
                  filterForm.value.applicationBusinessOwner.array.length > 0
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="applicationBusinessArea">
                            <mat-chip-list #chipList16 aria-label="Sub business areas selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.applicationBusinessArea.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'applicationBusinessArea')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Support Group..."
                                    formControlName="input"
                                    [matAutocomplete]="autoapplicationBusinessArea"
                                    [matChipInputFor]="chipList16"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="applicationBusinessArea_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoapplicationBusinessArea="matAutocomplete" (optionSelected)="search($event, 'applicationBusinessArea')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredapplicationMasterFilterList?.unique_business_areas, 'applicationBusinessArea')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('applicationBusinessArea')" *ngIf="filterForm.value.applicationBusinessArea.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="portfolio">
                            <mat-chip-list #chipList1 aria-label="Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.portfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'portfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search portfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="portfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event, 'portfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredPortfolioList, 'portfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('portfolio')" *ngIf="filterForm.value.portfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="subportfolio">
                            <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.subportfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'subportfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search subportfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="autoSub"
                                    [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="subportfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)="search($event, 'subportfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredSubPortfolioList, 'subportfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('subportfolio')" *ngIf="filterForm.value.subportfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="program">
                            <mat-chip-list #chipList3 aria-label="Sub program selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.program.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'program')">
                                    {{item. name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search program..."
                                    formControlName="input"
                                    [matAutocomplete]="autoPro"
                                    [matChipInputFor]="chipList3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="program_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoPro="matAutocomplete" (optionSelected)="search($event, 'program')">
                                <mat-option *ngFor="let option of filteredList(filteredProgramList, 'program')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('program')" *ngIf="filterForm.value.program.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div> -->
        </div>
        <!--Application Master Filter End-->

        <!--People Insights Start-->
        <div class="vscroll" id="style-3" *ngIf="pagetype == 'people-insights'">
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workforceMonth"
            >
              <mat-chip-list #chipList141 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.workforceMonth.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'workforceMonth')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Month..."
                  formControlName="input"
                  [matAutocomplete]="autoworkforceMonth14"
                  [matChipInputFor]="chipList141"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="workforceMonth_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoworkforceMonth14="matAutocomplete"
                (optionSelected)="search($event, 'workforceMonth')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      workforceMonthList,
                      'workforceMonth'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforceMonth')"
                *ngIf="filterForm.value.workforceMonth.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workforceJobCategory"
            >
              <mat-chip-list #chipList25 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="
                    let item of filterForm?.value.workforceJobCategory.array
                  "
                  [selectable]="selectable"
                  (removed)="remove(item, 'workforceJobCategory')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Job Category..."
                  formControlName="input"
                  [matAutocomplete]="autoworkforceJobCategory15"
                  [matChipInputFor]="chipList25"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="workforceJobCategory_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoworkforceJobCategory15="matAutocomplete"
                (optionSelected)="search($event, 'workforceJobCategory')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredWorkforceFilterList?.job_category,
                      'workforceJobCategory'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforceJobCategory')"
                *ngIf="filterForm.value.workforceJobCategory.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="vendor"
            >
              <mat-chip-list #chipList136 aria-label="Sub vendor selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.vendor.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'vendor')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search OU/GDP..."
                  formControlName="input"
                  [matAutocomplete]="autoVendor16"
                  [matChipInputFor]="chipList136"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="vendor_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoVendor16="matAutocomplete"
                (optionSelected)="search($event, 'vendor')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredVendorList, 'vendor')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('vendor')"
                *ngIf="filterForm.value.vendor.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workforceClassification"
            >
              <mat-chip-list
                #chipList117
                aria-label="Sub classification selection"
              >
                <mat-chip
                  *ngFor="
                    let item of filterForm?.value.workforceClassification.array
                  "
                  [selectable]="selectable"
                  (removed)="remove(item, 'workforceClassification')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Job Category..."
                  formControlName="input"
                  [matAutocomplete]="autoworkforceClassification17"
                  [matChipInputFor]="chipList117"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="workforceClassification_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoworkforceClassification17="matAutocomplete"
                (optionSelected)="search($event, 'workforceClassification')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredWorkforceFilterList?.classification,
                      'workforceClassification'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforceClassification')"
                *ngIf="
                  filterForm.value.workforceClassification.array.length > 0
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workforceCountry"
            >
              <mat-chip-list
                #chipList128
                aria-label="Sub classification selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.workforceCountry.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'workforceCountry')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Country..."
                  formControlName="input"
                  [matAutocomplete]="autoworkforceCountry18"
                  [matChipInputFor]="chipList128"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="workforceCountry_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoworkforceCountry18="matAutocomplete"
                (optionSelected)="search($event, 'workforceCountry')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredListNoName(
                      FilteredWorkforceFilterList?.country,
                      'workforceCountry'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workforceCountry')"
                *ngIf="filterForm.value.workforceCountry.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="portfolio"
            >
              <mat-chip-list #chipList1 aria-label="Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.portfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'portfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="portfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="search($event, 'portfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredPortfolioList,
                      'portfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('portfolio')"
                *ngIf="filterForm.value.portfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="subportfolio"
            >
              <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.subportfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'subportfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.sub_portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="autoSub"
                  [matChipInputFor]="chipList2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="subportfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoSub="matAutocomplete"
                (optionSelected)="search($event, 'subportfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredSubPortfolioList,
                      'subportfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('subportfolio')"
                *ngIf="filterForm.value.subportfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="program"
            >
              <mat-chip-list #chipList3 aria-label="Sub program selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.program.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'program')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.program }}..."
                  formControlName="input"
                  [matAutocomplete]="autoPro"
                  [matChipInputFor]="chipList3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="program_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoPro="matAutocomplete"
                (optionSelected)="search($event, 'program')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredProgramList, 'program')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('program')"
                *ngIf="filterForm.value.program.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          >>>>>>> develop
        </div>
        <!--People Insights End-->
        <!--Balance Scorecard Filter-->
        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'balance-scorecard'"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="portfolio"
            >
              <mat-chip-list #chipList1 aria-label="Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.portfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'portfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  [disabled]="
                    !userDetails.is_superuser &&
                    (userDetails.owner_level === 'sub_portfolio' ||
                      userDetails.owner_level === 'program')
                      ? true
                      : false
                  "
                  name="portfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="search($event, 'portfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredPortfolioList,
                      'portfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('portfolio')"
                *ngIf="filterForm.value.portfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="subportfolio"
            >
              <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.subportfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'subportfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.sub_portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="autoSub"
                  [matChipInputFor]="chipList2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  [disabled]="
                    !userDetails.is_superuser &&
                    userDetails.owner_level === 'program'
                      ? true
                      : false
                  "
                  name="subportfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoSub="matAutocomplete"
                (optionSelected)="search($event, 'subportfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredSubPortfolioList,
                      'subportfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('subportfolio')"
                *ngIf="filterForm.value.subportfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="program"
            >
              <mat-chip-list #chipList3 aria-label="Sub program selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.program.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'program')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.program }}..."
                  formControlName="input"
                  [matAutocomplete]="autoPro"
                  [matChipInputFor]="chipList3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="program_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoPro="matAutocomplete"
                (optionSelected)="search($event, 'program')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredProgramList, 'program')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('program')"
                *ngIf="filterForm.value.program.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="vendor"
            >
              <mat-chip-list #chipListBV aria-label="Sub vendor selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.vendor.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'vendor')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search OU/GDP..."
                  formControlName="input"
                  [matAutocomplete]="autoVendor"
                  [matChipInputFor]="chipListBV"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="vendor_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoVendor="matAutocomplete"
                (optionSelected)="search($event, 'vendor')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredVendorList, 'vendor')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('vendor')"
                *ngIf="filterForm.value.vendor.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <!--Balance Scorecard Filter End-->
        <!--Plan Center Filter-->
        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'turnkey-tracker'"
          [ngStyle]="{
            width: pagetype == 'turnkey-tracker' ? '100%' : '',
            padding: pagetype == 'turnkey-tracker' ? '0px 0 0px 0' : ''
          }"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="owner"
            >
              <mat-chip-list #chipListTKT1 aria-label="Owner selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.owner.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'owner')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search owner..."
                  formControlName="input"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipListTKT1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="owner_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="search($event, 'owner')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredOwnerList, 'owner')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('owner')"
                *ngIf="filterForm.value.owner.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="workgroup"
            >
              <mat-chip-list #chipListTKT2 aria-label="Sub Workgroup selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.workgroup.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'workgroup')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search workgroup..."
                  formControlName="input"
                  [matAutocomplete]="autoSub"
                  [matChipInputFor]="chipListTKT2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="subportfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoSub="matAutocomplete"
                (optionSelected)="search($event, 'workgroup')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredWorkgroupList,
                      'workgroup'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('workgroup')"
                *ngIf="filterForm.value.workgroup.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tag"
            >
              <mat-chip-list #chipListTKT3 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tag.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tag')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search tag..."
                  formControlName="input"
                  [matAutocomplete]="autoPro"
                  [matChipInputFor]="chipListTKT3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoPro="matAutocomplete"
                (optionSelected)="search($event, 'tag')"
              >
                <mat-option
                  *ngFor="let option of filteredList(filteredTagList, 'tag')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                  matTooltip="{{ option.name }} - {{ option.tag_category }}"
                >
                  <b>{{ option.name }}</b> - {{ option.tag_category }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('tag')"
                *ngIf="filterForm.value.tag.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <!--Plan Center Filter End-->
        <!--Crowdsourcing Filter-->
        <div
          [ngClass]="
            pagetype !== 'assessment-template' &&
            pagetype !== 'plan-central' &&
            pagetype !== 'governance-assessment-template'
              ? 'vscroll'
              : ''
          "
          id="style-3"
          *ngIf="
            pagetype == 'crowdsourcing' ||
            pagetype == 'crowdsourcing-workbench' ||
            pagetype == 'assessment-template' ||
            pagetype == 'governance-assessment-template' ||
            pagetype == 'plan-central'
          "
          [ngStyle]="{
            width: pagetype == 'crowdsourcing' ? '100%' : '',
            padding: pagetype == 'crowdsourcing' ? '0px 0 0px 0' : ''
          }"
        >
          <div class="form-item" *ngIf="pagetype == 'crowdsourcing-workbench'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="portfolio"
            >
              <mat-chip-list #chipList1 aria-label="Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.portfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'portfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="portfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="search($event, 'portfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredPortfolioList,
                      'portfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('portfolio')"
                *ngIf="filterForm.value.portfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'crowdsourcing-workbench'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="subportfolio"
            >
              <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.subportfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'subportfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.sub_portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="autoSub"
                  [matChipInputFor]="chipList2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="subportfolio_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoSub="matAutocomplete"
                (optionSelected)="search($event, 'subportfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredSubPortfolioList,
                      'subportfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('subportfolio')"
                *ngIf="filterForm.value.subportfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'crowdsourcing-workbench'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="program"
            >
              <mat-chip-list #chipList3 aria-label="Sub program selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.program.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'program')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.program }}..."
                  formControlName="input"
                  [matAutocomplete]="autoPro"
                  [matChipInputFor]="chipList3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="program_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoPro="matAutocomplete"
                (optionSelected)="search($event, 'program')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredProgramList, 'program')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('program')"
                *ngIf="filterForm.value.program.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tag"
            >
              <mat-chip-list #chipListTKT3 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tag.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tag')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  class="tag-input"
                  placeholder="Search Tag..."
                  formControlName="input"
                  [matAutocomplete]="autoCrowdTag"
                  [matChipInputFor]="chipListTKT3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                  style="font-size: 12px"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoCrowdTag="matAutocomplete"
                (optionSelected)="search($event, 'tag')"
              >
                <mat-option
                  *ngFor="let option of filteredList(filteredTagList, 'tag')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                  matTooltip="{{ option.name }} - {{ option.tag_category }}"
                >
                  <b>{{ option.name }}</b> - {{ option.tag_category }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('tag')"
                *ngIf="filterForm.value.tag.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <!--Plan Center Filter End-->

        <!--Risk Coverage Filter-->

        <div class="vscroll" id="style-3" *ngIf="pagetype == 'riskcoverage'">
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="portfolio"
            >
              <mat-chip-list #chipList1 aria-label="Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.portfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'portfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="portfolio_name"
                  [disabled]="
                    !userDetails.is_superuser &&
                    (userDetails.owner_level === 'sub_portfolio' ||
                      userDetails.owner_level === 'program' ||
                      userDetails.owner_level == 'project')
                      ? true
                      : false
                  "
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="search($event, 'portfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredPortfolioList,
                      'portfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('portfolio')"
                *ngIf="filterForm.value.portfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="subportfolio"
            >
              <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.subportfolio.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'subportfolio')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.sub_portfolio }}..."
                  formControlName="input"
                  [matAutocomplete]="autoSub"
                  [matChipInputFor]="chipList2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="subportfolio_name"
                  [disabled]="
                    !userDetails.is_superuser &&
                    (userDetails.owner_level === 'program' ||
                      userDetails.owner_level === 'project')
                      ? true
                      : false
                  "
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoSub="matAutocomplete"
                (optionSelected)="search($event, 'subportfolio')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredSubPortfolioList,
                      'subportfolio'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('subportfolio')"
                *ngIf="filterForm.value.subportfolio.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="project"
            >
              <mat-chip-list #chipList5 aria-label="Sub project selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.project.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'project')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Project..."
                  formControlName="input"
                  [matAutocomplete]="autoProject"
                  [matChipInputFor]="chipList5"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="project_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoProject="matAutocomplete"
                (optionSelected)="search($event, 'project')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredProjectList, 'project')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('project')"
                *ngIf="filterForm.value.project.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="program"
            >
              <mat-chip-list #chipList3 aria-label="Sub program selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.program.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'program')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search {{ displayLabels.program }}..."
                  formControlName="input"
                  [matAutocomplete]="autoPro"
                  [matChipInputFor]="chipList3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="program_name"
                  [disabled]="
                    !userDetails.is_superuser &&
                    userDetails.owner_level === 'project'
                      ? true
                      : false
                  "
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoPro="matAutocomplete"
                (optionSelected)="search($event, 'program')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredProgramList, 'program')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('program')"
                *ngIf="filterForm.value.program.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="riskDomain"
            >
              <mat-chip-list #chipListdomain1 aria-label="domain selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.riskDomain.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'riskDomain')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search domain..."
                  formControlName="input"
                  [matAutocomplete]="autoDomain"
                  [matChipInputFor]="chipListdomain1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="domain_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoDomain="matAutocomplete"
                (optionSelected)="search($event, 'riskDomain')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      FilterRiskDomainList,
                      'riskDomain'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('riskDomain')"
                *ngIf="filterForm.value.riskDomain.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="riskSubdomain"
            >
              <mat-chip-list
                #chipListsubdomain1
                aria-label="Sub domain selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.riskSubdomain.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'riskSubdomain')"
                >
                  {{ item.display_name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search sub domain..."
                  formControlName="input"
                  [matAutocomplete]="autosubdom"
                  [matChipInputFor]="chipListsubdomain1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="sub_domain_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autosubdom="matAutocomplete"
                (optionSelected)="search($event, 'riskSubdomain')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      FilterRiskSubDomainList,
                      'riskSubdomain'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.display_name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('riskSubdomain')"
                *ngIf="filterForm.value.riskSubdomain.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pageTab.tab !== 2">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="riskresponse"
            >
              <mat-chip-list
                #chipListriskresponse1
                aria-label="Risk Response selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.riskresponse.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'riskresponse')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search risk response..."
                  formControlName="input"
                  [matAutocomplete]="autoriskresponse"
                  [matChipInputFor]="chipListriskresponse1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="risk_response_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoriskresponse="matAutocomplete"
                (optionSelected)="search($event, 'riskresponse')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      FilterRiskResponseList,
                      'riskresponse'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('riskresponse')"
                *ngIf="filterForm.value.riskresponse.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pageTab.tab !== 2">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="riskstatus"
            >
              <mat-chip-list
                #chipListRiskStatus1
                aria-label="Risk status selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.riskstatus.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'riskstatus')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search risk status..."
                  formControlName="input"
                  [matAutocomplete]="autoriskstatus"
                  [matChipInputFor]="chipListRiskStatus1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="risk_response"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoriskstatus="matAutocomplete"
                (optionSelected)="search($event, 'riskstatus')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      FilterRiskStatusList,
                      'riskstatus'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('riskstatus')"
                *ngIf="filterForm.value.riskstatus.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>

        <!--Risk Coverage Filter end-->

        <!--parade Filter-->
        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'parade'"
          [ngStyle]="{
            width: pagetype == 'parade' ? '100%' : '',
            padding: pagetype == 'parade' ? '0px 0 0px 0' : ''
          }"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tag"
            >
              <mat-chip-list #chipListTKT33 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tag.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tag')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search tag..."
                  formControlName="input"
                  [matAutocomplete]="autoCrowdTag"
                  [matChipInputFor]="chipListTKT33"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoCrowdTag="matAutocomplete"
                (optionSelected)="search($event, 'tag')"
              >
                <mat-option
                  *ngFor="let option of filteredList(filteredTagList, 'tag')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                  matTooltip="{{ option.name }} - {{ option.tag_category }}"
                >
                  <b>{{ option.name }}</b> - {{ option.tag_category }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('tag')"
                *ngIf="filterForm.value.tag.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>

        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'OKR-dasboard' || pagetype == 'OKR-register'"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="okrobj"
            >
              <mat-chip-list
                #chipListokrobj
                aria-label="Sub objective selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.okrobj.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'okrobj')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Objective..."
                  formControlName="input"
                  [matAutocomplete]="autookrobj"
                  [matChipInputFor]="chipListokrobj"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="objective_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autookrobj="matAutocomplete"
                (optionSelected)="search($event, 'okrobj')"
              >
                <mat-option
                  *ngFor="let option of filteredList(OKRObjList, 'okrobj')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('okrobj')"
                *ngIf="filterForm.value.okrobj.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="okrkr"
            >
              <mat-chip-list
                #chipListokrkr
                aria-label="Sub key result selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.okrkr.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'okrkr')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Key Result..."
                  formControlName="input"
                  [matAutocomplete]="autookrkr"
                  [matChipInputFor]="chipListokrkr"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="okrkr_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autookrkr="matAutocomplete"
                (optionSelected)="search($event, 'okrkr')"
              >
                <mat-option
                  *ngFor="let option of filteredList(OKRKeyResultList, 'okrkr')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('okrkr')"
                *ngIf="filterForm.value.okrkr.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="okrgoal"
            >
              <mat-chip-list #chipListokrgoal aria-label="Sub goal selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.okrgoal.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'okrgoal')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Goal..."
                  formControlName="input"
                  [matAutocomplete]="autookrgoal"
                  [matChipInputFor]="chipListokrgoal"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="goal_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autookrgoal="matAutocomplete"
                (optionSelected)="search($event, 'okrgoal')"
              >
                <mat-option
                  *ngFor="let option of filteredList(OKRGoalList, 'okrgoal')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('okrgoal')"
                *ngIf="filterForm.value.okrgoal.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'OKR-dasboard'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="project"
            >
              <mat-chip-list #chipList5 aria-label="Sub project selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.project.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'project')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Project..."
                  formControlName="input"
                  [matAutocomplete]="autoProject"
                  [matChipInputFor]="chipList5"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="project_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoProject="matAutocomplete"
                (optionSelected)="search($event, 'project')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(filteredProjectList, 'project')
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('project')"
                *ngIf="filterForm.value.project.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'OKR-dasboard'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="opportunity"
            >
              <mat-chip-list #chipList4 aria-label="Sub opportunity selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.opportunity.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'opportunity')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search opportunity..."
                  formControlName="input"
                  [matAutocomplete]="autoOppo"
                  [matChipInputFor]="chipList4"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="opportunity_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoOppo="matAutocomplete"
                (optionSelected)="search($event, 'opportunity')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredOpportunityList,
                      'opportunity'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('opportunity')"
                *ngIf="filterForm.value.opportunity.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'OKR-register'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="okrMOS"
            >
              <mat-chip-list #chipListOKRMOS aria-label="Sub MOS selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.okrMOS.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'okrMOS')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search MOS..."
                  formControlName="input"
                  [matAutocomplete]="autoOKRMOS"
                  [matChipInputFor]="chipListOKRMOS"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="primary_owner"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoOKRMOS="matAutocomplete"
                (optionSelected)="search($event, 'okrMOS')"
              >
                <mat-option
                  *ngFor="let option of filteredList(filteredMOSList, 'okrMOS')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('okrMOS')"
                *ngIf="filterForm.value.okrMOS.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'OKR-register'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="okrPrimaryOwner"
            >
              <mat-chip-list
                #chipListOKRPrimOwner
                aria-label="Sub primary owner selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.okrPrimaryOwner.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'okrPrimaryOwner')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Primary Owner..."
                  formControlName="input"
                  [matAutocomplete]="autoOKRPrimOwner"
                  [matChipInputFor]="chipListOKRPrimOwner"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="primary_owner"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoOKRPrimOwner="matAutocomplete"
                (optionSelected)="search($event, 'okrPrimaryOwner')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredOKROwnerList,
                      'okrPrimaryOwner'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('okrPrimaryOwner')"
                *ngIf="filterForm.value.okrPrimaryOwner.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'OKR-register'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="okrSecondaryOwner"
            >
              <mat-chip-list
                #chipListOKRSecondOwner
                aria-label="Sub secondary owner selection"
              >
                <mat-chip
                  *ngFor="let item of filterForm?.value.okrSecondaryOwner.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'okrSecondaryOwner')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Secondary Owner..."
                  formControlName="input"
                  [matAutocomplete]="autoOKRSecondOwner"
                  [matChipInputFor]="chipListOKRSecondOwner"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="second_owner"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoOKRSecondOwner="matAutocomplete"
                (optionSelected)="search($event, 'okrSecondaryOwner')"
              >
                <mat-option
                  *ngFor="
                    let option of filteredList(
                      filteredOKROwnerList,
                      'okrSecondaryOwner'
                    )
                  "
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('okrSecondaryOwner')"
                *ngIf="filterForm.value.okrSecondaryOwner.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'OKR-register'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tag"
            >
              <mat-chip-list #chipListTKT33 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tag.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tag')"
                >
                  {{ item.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Tag..."
                  formControlName="input"
                  [matAutocomplete]="autoCrowdTag"
                  [matChipInputFor]="chipListTKT33"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoCrowdTag="matAutocomplete"
                (optionSelected)="search($event, 'tag')"
              >
                <mat-option
                  *ngFor="let option of filteredList(filteredTagList, 'tag')"
                  [value]="option"
                  style="font-size: 0.8rem; height: 30px"
                  matTooltip="{{ option.name }} - {{ option.tag_category }}"
                >
                  <b>{{ option.name }}</b> - {{ option.tag_category }}
                </mat-option>
              </mat-autocomplete>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('tag')"
                *ngIf="filterForm.value.tag.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-item" *ngIf="pagetype == 'OKR-register'">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="okrEndDate"
            >
              <mat-chip-list
                #chipListOKREndDate
                aria-label="Sub secondary owner selection"
              >
                <!-- <mat-chip
                    *ngFor="let item of filterForm?.value.okrEndDate.array"
                    [selectable]="selectable"
                    (removed)="remove(item, 'okrEndDate')">
                    {{item.name}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip> -->
                <input
                  placeholder="Select End Date..."
                  formControlName="input"
                  (dateInput)="searchDate($event.value, 'okrEndDate')"
                  [matDatepicker]="picker"
                  [matChipInputFor]="chipListOKREndDate"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="end_owner"
                  (click)="picker.open()"
                />
              </mat-chip-list>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #picker
                (selectedChanged)="search($event, okrEndDate)"
              ></mat-datepicker>
              <button
                mat-button
                mat-icon-button
                matSuffix
                class="close-btn"
                (click)="clearSearchField('okrEndDate')"
                *ngIf="filterForm.value.okrEndDate.array.length > 0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <!--PCM-->
        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'people_competency_map'"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="pcmTitle"
            >
              <mat-chip-list #chipListTKT35 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.pcmTitle.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'pcmTitle')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Title..."
                  formControlName="input"
                  [matAutocomplete]="pcmTitle"
                  [matChipInputFor]="chipListTKT35"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #pcmTitle="matAutocomplete"
                (optionSelected)="search($event, 'pcmTitle')"
              >
                <mat-option
                  [value]="this.filterForm.value.pcmTitle.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.pcmTitle.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="pcmCategory"
            >
              <mat-chip-list #chipListTKT36 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.pcmCategory.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'pcmCategory')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Job Category..."
                  formControlName="input"
                  [matAutocomplete]="pcmCategory"
                  [matChipInputFor]="chipListTKT36"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #pcmCategory="matAutocomplete"
                (optionSelected)="search($event, 'pcmCategory')"
              >
                <mat-option
                  [value]="this.filterForm.value.pcmCategory.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.pcmCategory.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="pcmLocation"
            >
              <mat-chip-list #chipListTKT37 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.pcmLocation.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'pcmLocation')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Client Location..."
                  formControlName="input"
                  [matAutocomplete]="pcmLocation"
                  [matChipInputFor]="chipListTKT37"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #pcmLocation="matAutocomplete"
                (optionSelected)="search($event, 'pcmLocation')"
              >
                <mat-option
                  [value]="this.filterForm.value.pcmLocation.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.pcmLocation.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <!--Taxonomy-->
        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'taxonomy_costpool'"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tcpCostPool"
            >
              <mat-chip-list #chipListTKT40 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tcpCostPool.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tcpCostPool')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Cost Pool..."
                  formControlName="input"
                  [matAutocomplete]="tcpCostPool"
                  [matChipInputFor]="chipListTKT40"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tcpCostPool="matAutocomplete"
                (optionSelected)="search($event, 'tcpCostPool')"
              >
                <mat-option
                  [value]="this.filterForm.value.tcpCostPool.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tcpCostPool.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tcpCostSubPool"
            >
              <mat-chip-list #chipListTKT41 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tcpCostSubPool.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tcpCostSubPool')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Cost Sub Pool..."
                  formControlName="input"
                  [matAutocomplete]="tcpCostSubPool"
                  [matChipInputFor]="chipListTKT41"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tcpCostSubPool="matAutocomplete"
                (optionSelected)="search($event, 'tcpCostSubPool')"
              >
                <mat-option
                  [value]="this.filterForm.value.tcpCostSubPool.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tcpCostSubPool.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tcpExpenseType"
            >
              <mat-chip-list #chipListTKT42 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tcpExpenseType.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tcpExpenseType')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Expense Type..."
                  formControlName="input"
                  [matAutocomplete]="tcpExpenseType"
                  [matChipInputFor]="chipListTKT42"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tcpExpenseType="matAutocomplete"
                (optionSelected)="search($event, 'tcpExpenseType')"
              >
                <mat-option
                  [value]="this.filterForm.value.tcpExpenseType.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tcpExpenseType.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'taxonomy_itTower'"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="titTowerName"
            >
              <mat-chip-list #chipListTKT44 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.titTowerName.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'titTowerName')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Tower name..."
                  formControlName="input"
                  [matAutocomplete]="titTowerName"
                  [matChipInputFor]="chipListTKT44"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #titTowerName="matAutocomplete"
                (optionSelected)="search($event, 'titTowerName')"
              >
                <mat-option
                  [value]="this.filterForm.value.titTowerName.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.titTowerName.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="titSubTowerName"
            >
              <mat-chip-list #chipListTKT45 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.titSubTowerName.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'titSubTowerName')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Sub Tower Name..."
                  formControlName="input"
                  [matAutocomplete]="titSubTowerName"
                  [matChipInputFor]="chipListTKT45"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #titSubTowerName="matAutocomplete"
                (optionSelected)="search($event, 'titSubTowerName')"
              >
                <mat-option
                  [value]="this.filterForm.value.titSubTowerName.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.titSubTowerName.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="titSubTowerElement"
            >
              <mat-chip-list #chipListTKT46 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="
                    let item of filterForm?.value.titSubTowerElement.array
                  "
                  [selectable]="selectable"
                  (removed)="remove(item, 'titSubTowerElement')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Sub Tower Element..."
                  formControlName="input"
                  [matAutocomplete]="titSubTowerElement"
                  [matChipInputFor]="chipListTKT46"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #titSubTowerElement="matAutocomplete"
                (optionSelected)="search($event, 'titSubTowerElement')"
              >
                <mat-option
                  [value]="this.filterForm.value.titSubTowerElement.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.titSubTowerElement.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="titUnitMeasure"
            >
              <mat-chip-list #chipListTKT47 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.titUnitMeasure.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'titUnitMeasure')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Unit Measure..."
                  formControlName="input"
                  [matAutocomplete]="titUnitMeasure"
                  [matChipInputFor]="chipListTKT47"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #titUnitMeasure="matAutocomplete"
                (optionSelected)="search($event, 'titUnitMeasure')"
              >
                <mat-option
                  [value]="this.filterForm.value.titUnitMeasure.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.titUnitMeasure.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="titDelivery"
            >
              <mat-chip-list #chipListTKT48 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.titDelivery.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'titDelivery')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Delivery..."
                  formControlName="input"
                  [matAutocomplete]="titDelivery"
                  [matChipInputFor]="chipListTKT48"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #titDelivery="matAutocomplete"
                (optionSelected)="search($event, 'titDelivery')"
              >
                <mat-option
                  [value]="this.filterForm.value.titDelivery.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.titDelivery.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'taxonomy_serviceTaxonomy'"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tscServiceName"
            >
              <mat-chip-list #chipListTKT49 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tscServiceName.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tscServiceName')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Service Name..."
                  formControlName="input"
                  [matAutocomplete]="tscServiceName"
                  [matChipInputFor]="chipListTKT49"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tscServiceName="matAutocomplete"
                (optionSelected)="search($event, 'tscServiceName')"
              >
                <mat-option
                  [value]="this.filterForm.value.tscServiceName.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tscServiceName.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tscServiceType"
            >
              <mat-chip-list #chipListTKT50 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tscServiceType.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tscServiceType')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Service Type..."
                  formControlName="input"
                  [matAutocomplete]="tscServiceType"
                  [matChipInputFor]="chipListTKT50"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tscServiceType="matAutocomplete"
                (optionSelected)="search($event, 'tscServiceType')"
              >
                <mat-option
                  [value]="this.filterForm.value.tscServiceType.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tscServiceType.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tscServiceCategory"
            >
              <mat-chip-list #chipListTKT51 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="
                    let item of filterForm?.value.tscServiceCategory.array
                  "
                  [selectable]="selectable"
                  (removed)="remove(item, 'tscServiceCategory')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Service Category..."
                  formControlName="input"
                  [matAutocomplete]="tscServiceCategory"
                  [matChipInputFor]="chipListTKT51"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tscServiceCategory="matAutocomplete"
                (optionSelected)="search($event, 'tscServiceCategory')"
              >
                <mat-option
                  [value]="this.filterForm.value.tscServiceCategory.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tscServiceCategory.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div
          class="vscroll"
          id="style-3"
          *ngIf="pagetype == 'taxonomy_solutionTaxonomy'"
        >
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tsnName"
            >
              <mat-chip-list #chipListTKT52 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tsnName.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tsnName')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Name..."
                  formControlName="input"
                  [matAutocomplete]="tsnName"
                  [matChipInputFor]="chipListTKT52"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tsnName="matAutocomplete"
                (optionSelected)="search($event, 'tsnName')"
              >
                <mat-option
                  [value]="this.filterForm.value.tsnName.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tsnName.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tsnSolutionType"
            >
              <mat-chip-list #chipListTKT53 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tsnSolutionType.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tsnSolutionType')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Solution Type..."
                  formControlName="input"
                  [matAutocomplete]="tsnSolutionType"
                  [matChipInputFor]="chipListTKT53"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tsnSolutionType="matAutocomplete"
                (optionSelected)="search($event, 'tsnSolutionType')"
              >
                <mat-option
                  [value]="this.filterForm.value.tsnSolutionType.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tsnSolutionType.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tsnCategory"
            >
              <mat-chip-list #chipListTKT54 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tsnCategory.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tsnCategory')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Category..."
                  formControlName="input"
                  [matAutocomplete]="tsnCategory"
                  [matChipInputFor]="chipListTKT54"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tsnCategory="matAutocomplete"
                (optionSelected)="search($event, 'tsnCategory')"
              >
                <mat-option
                  [value]="this.filterForm.value.tsnCategory.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tsnCategory.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-item">
            <mat-form-field
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
              formGroupName="tsnOffering"
            >
              <mat-chip-list #chipListTKT55 aria-label="Sub tag selection">
                <mat-chip
                  *ngFor="let item of filterForm?.value.tsnOffering.array"
                  [selectable]="selectable"
                  (removed)="remove(item, 'tsnOffering')"
                >
                  {{ item }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Search Offering..."
                  formControlName="input"
                  [matAutocomplete]="tsnOffering"
                  [matChipInputFor]="chipListTKT55"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  name="tag_name"
                />
              </mat-chip-list>
              <mat-autocomplete
                #tsnOffering="matAutocomplete"
                (optionSelected)="search($event, 'tsnOffering')"
              >
                <mat-option
                  [value]="this.filterForm.value.tsnOffering.input"
                  style="font-size: 0.8rem; height: 30px"
                >
                  {{ this.filterForm.value.tsnOffering.input }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
