import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TurnkeyTrackerService {
  constructor(private http: HttpClient) {}

  getOwner() {
    return this.http.get(`users/user-list/`);
  }

  getWorkgroup() {
    return this.http.get(`dynamic-form/?form_name=opportunity_teams`);
  }

  getPlans() {
    return this.http.get(`turnkey-tracker/plan/`);
  }

  createPlan(data: any) {
    return this.http.post(`turnkey-tracker/plan/`, data);
  }

  getPlanDetail(planId: any) {
    return this.http.get(`turnkey-tracker/plan/` + planId + "/");
  }

  updatePlan(id: any, obj: any) {
    return this.http.patch(`turnkey-tracker/plan/` + id + "/", obj);
  }

  updatePhase(id: any, obj: any) {
    return this.http.patch(`turnkey-tracker/phase/` + id + "/", obj);
  }

  createPhase(obj: any) {
    return this.http.post(`turnkey-tracker/phase/`, obj);
  }

  sortPhase(obj: any) {
    return this.http.put(`turnkey-tracker/phase/sort/`, obj);
  }

  deletePlan(id: any) {
    return this.http.delete(`turnkey-tracker/plan/` + id);
  }

  deletePhase(id: any) {
    return this.http.delete(`turnkey-tracker/phase/` + id + "/");
  }

  updateActivity(id: any, obj: any) {
    return this.http.patch(`turnkey-tracker/activity/` + id + "/", obj);
  }

  deleteActivity(id: any) {
    return this.http.delete(`turnkey-tracker/activity/` + id + "/");
  }

  createActivity(obj: any) {
    return this.http.post(`turnkey-tracker/activity/`, obj);
  }

  sortActivity(obj: any) {
    return this.http.put(`turnkey-tracker/activity/sort/`, obj);
  }

  getTemplates() {
    return this.http.get(`turnkey-tracker/template-plan/`);
  }

  uploadFile(payload: any) {
    return this.http.post(
      `turnkey-tracker/template-plan/turnkey_template_upload/`,
      payload
    );
  }

  deleteTemplate(id: any) {
    return this.http.delete(`turnkey-tracker/template-plan/` + id);
  }

  saveTemplate(payload: any) {
    return this.http.post(
      `turnkey-tracker/template-plan/turnkey_template_creation/`,
      payload
    );
  }

  getPlanCategories() {
    return this.http.get("base_value/?type=plan_category");
  }

  getFilteredPlans(
    category: any,
    name: any,
    tags: any,
    owner: any,
    dueDate: any,
    workgroup: any
  ) {
    return this.http.get(
      `turnkey-tracker/plan/?search=category:${category},name:${name},tags:${tags},created_by:${owner},end_date:${dueDate},workgroup:${workgroup}`
    );
  }

  getFilteredPlanTemplates(category: any, name: any, tags: any) {
    return this.http.get(
      `turnkey-tracker/template-plan/?search=category:${category},name:${name},tags:${tags}`
    );
  }

  sendNotification(activityId: number) {
    return this.http.post(
      `turnkey-tracker/plan/activity_notification/${activityId}/`,
      {}
    );
  }

  uploadCommentFile(payload: any, activityId: any) {
    return this.http.post(
      `turnkey-tracker/plan/activity_upload_document/${activityId}/`,
      payload
    );
  }

  getWorkgroups() {
    return this.http.get("base_value/?type=opportunity_function");
  }

  getPlanStats(planId: number) {
    return this.http.get(`turnkey-tracker/plan/${planId}/stats/`);
  }
}
