<app-modal-header-common title="Pulses"> </app-modal-header-common>
<div class="pulses-dialog">
  <div class="content">
    <app-pulse
      [pulsesList]="pulsesList"
      [projectId]="projectId"
      [card]="card"
      [isRisk]="isRisk"
    ></app-pulse>
  </div>
  <div style="display: flex; justify-content: end" class="mt-4">
    <button mat-flat-button color="secondary" (click)="closeDialog()">
      Close
    </button>
  </div>
</div>
