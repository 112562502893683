<app-modal-header-common [title]="'Create Parade'"> </app-modal-header-common>
<div class="plan-form">
  <app-loading *ngIf="loading"></app-loading>
  <form *ngIf="!loading" [formGroup]="paradeForm">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <div style="display: flex; justify-content: end" class="mt-3">
      <button
        mat-raised-button
        color="primary"
        class="templ-btn"
        (click)="createParade()"
      >
        Create
      </button>
    </div>
  </form>
</div>
