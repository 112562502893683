import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { from, Observable, throwError, of } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  passToken: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    this.spinner.hide();
    if (
      err.status === 503 ||
      (err.status === 401 &&
        err.error?.detail.includes("Signature has expired"))
    ) {
      if (
        err.status === 401 &&
        (err.error?.detail.includes("Signature has expired") ||
          err.error?.detail.includes("Invalid signature."))
      ) {
        this.toastr.error(err.error?.detail);
      }
      localStorage.clear();
      this.router.navigate(["auth/login"]);
      return of(err.message);
    } else if (err.status === 403) {
      this.toastr.error(err.error?.detail || err.error?.error || err.error || "Oops, something went wrong. Our team is working on it!");
    } else if (err.status == 400) {
      this.toastr.error(err.error?.detail || err.error?.error || "Oops, something went wrong. Our team is working on it!");
    } else if (err.status != 404) {
      this.toastr.error(err.error?.detail || err.error?.error || "Oops, something went wrong. Our team is working on it!");
    }
    console.log("Error", err)
    return throwError("Oops, something went wrong. Our team is working on it!");
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    var backend_url;
    let temp_host = window.location.host;
    if (temp_host === "titan.dev.kepler.kairhos.com" || "localhost:4200") {
      backend_url = window.location.protocol + "//" + temp_host;
      let subdomain = temp_host.split(".");
      subdomain.splice(1, 0, "kepler");
      let testkepler = window.location.protocol + "//" + subdomain.join(".");
      backend_url = "https://demo.rc-keplerbe.kairhos.com";
    } else {
      let subdomain = temp_host.split(".");
      subdomain.splice(1, 0, "kepler");
      backend_url = window.location.protocol + "//" + subdomain.join(".");
      backend_url = "https://demo.rc-keplerbe.kairhos.com";
    }

    var url = window.location.host.includes("localhost")
      ? "https://titan.dev.kepler.kairhos.com"
      : backend_url;

    // if (window.location.href.includes('rate/vendor-rate') || window.location.href.includes('rate/market-rate')) {
    //     let subdomain = temp_host.split('.');
    //     subdomain.splice(1, 0, "rate");
    //     backend_url = window.location.protocol + '//' + subdomain.join('.');
    //     var url = window.location.host.includes('localhost') ? 'https://titan.rate.dev.kepler.kairhos.com' : backend_url

    // }
    if (window.location.href.includes("vendor/list")) {
      let subdomain = temp_host.split(".");
      subdomain.splice(1, 0, "vendor");
      backend_url = window.location.protocol + "//" + subdomain.join(".");
      console.log("new backend -", backend_url);
      var url = window.location.host.includes("localhost")
        ? "https://titan.vendor.dev.kepler.kairhos.com"
        : backend_url;
    }

    if (req.url.includes("dev.airo.kairhos.com")) {
      console.log("test");
      let path = "";
      path += `${req.url}`;
      req = req.clone({
        url: path,
      });
      return next.handle(req);
    }

    if (req.url.includes("tag_ids")) {
      let path = "";
      path += `${url}/server/v1/${req.url}`;
      if (!req.url.includes("graph.microsoft.com")) {
        return from(this.getToken()).pipe(
          mergeMap((token: any) => {
            req = req.clone({
              setHeaders: {
                authorization: `JWT ${token}`,
                // "Upgrade-Insecure-Requests":"1",
              },
              url: path,
            });
            return next
              .handle(req)
              .pipe(catchError((x) => this.handleAuthError(x)));
          })
        );
      } else {
        return next.handle(req).pipe(catchError((x) => this.handleAuthError(x)));
      }
    }

    // if(!req.url.includes('tag_ids')){
    //     if ((req.url.includes('tag') || req.url.includes('maptag') || req.url.includes('recomendedtags') || req.url.includes('removetag')) && !req.url.includes('delivery')) {
    //         let temp_host = window.location.host
    //         if (temp_host === 'titan.dev.kepler.kairhos.com' || temp_host === 'localhost:4200') {
    //             backend_url = 'titan.dev.kepler.kairhos.com';
    //             let subdomain = backend_url.split('.');
    //             subdomain.splice(1, 0, "tag");
    //             backend_url = 'https://' + subdomain.join('.');
    //         }
    //         else {
    //             let subdomain = temp_host.split('.');
    //             subdomain.splice(1, 0, "tag");
    //             backend_url = 'https://' + subdomain.join('.');
    //         }

    //         var url = backend_url;
    //     }
    // }

    if (
      req.url.includes("auth") ||
      req.url.includes("reset") ||
      req.url.includes("get-user-profile") ||
      req.url.includes("password-reset-confirm") ||
      req.url.includes("api-password-reset-verify") ||
      req.url.includes("api-token-verify")
    ) {
      let path = "";
      path += `${url}/server/${req.url}`;
      req = req.clone({
        url: path,
      });
      if (req.url.includes("password-reset-confirm")) {
        this.activatedRoute.queryParams.subscribe((params) => {
          this.passToken = params["tk"];
        });
        req = req.clone({
          setHeaders: {
            "Content-Type": "application/json",
          },
          url: path,
        });
      }
      if (req.url) return next.handle(req);
      else return of("error");
    }

    // else if ((req.url.includes('tag') || req.url.includes('maptag') || req.url.includes('recomendedtags') || req.url.includes('removetag')) && !req.url.includes('delivery')) {
    //     let path = '';
    //     path += `${url}/${req.url}`;
    //     req = req.clone({
    //         setHeaders: {
    //             'Content-Type': 'application/json'
    //         },
    //         url: path
    //     });
    //     return next.handle(req);
    // }
    else if (req.url.includes("forms")) {
      let path = "";
      path += `${url}/server/api/v1/${req.url}`;

      return from(this.getToken()).pipe(
        mergeMap((token: any) => {
          req = req.clone({
            setHeaders: {
              authorization: `JWT ${token}`,
            },
            url: path,
          });
          return next
            .handle(req)
            .pipe(catchError((x) => this.handleAuthError(x)));
        })
      );
    } else if (
      req.url.includes("check-user-exists") ||
      req.url.includes("get-token-by-sso") ||
      req.url.includes("vendor_otp")
    ) {
      let path = "";
      path += `${url}/server/v1/${req.url}`;
      req = req.clone({
        setHeaders: {
          "Content-Type": "application/json",
          //   "Access-Control-Allow-Origin":"*",
        },
        url: path,
      });
      return next.handle(req);
    }

    // else if (window.location.href.includes('rate/vendor-rate') || window.location.href.includes('rate/market-rate')) {
    //     let path = '';
    //     path += `${url}/${req.url}`;
    //     req = req.clone({
    //         setHeaders: {
    //             'Content-Type': 'application/json',
    //             //"Access-Control-Allow-Origin":"*",
    //             //"Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",

    //         },

    //         url: path
    //     });
    //     return next.handle(req);
    // }
    else if (
      window.location.href.includes("signup") ||
      window.location.href.includes("verify")
    ) {
      let path = "";
      path += `${url}/server/v1/${req.url}`;
      req = req.clone({
        setHeaders: {
          "Content-Type": "application/json",
          //"Access-Control-Allow-Origin":"*",
          //"Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
        },

        url: path,
      });
      return next.handle(req);
    } else if (window.location.href.includes("vendor/list")) {
      let path = "";
      path += `${url}/${req.url}`;
      req = req.clone({
        // setHeaders: {
        //     //'Content-Type': 'multipart/form-data',
        //     //"Access-Control-Allow-Origin":"*",
        //     //"Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",

        // },

        url: path,
      });
      return next.handle(req);
    } else if (req.url.includes("api.powerbi.com")) {
      let path = "";
      path += `${req.url}`;
      req = req.clone({
        url: path,
      });
      return next.handle(req);
    } else if (req.url.includes("dev.airo.kairhos.com")) {
      console.log("test");
      let path = "";
      path += `${req.url}`;
      req = req.clone({
        url: path,
      });
      return next.handle(req);
    } else {
      let path = "";
      path += `${url}/server/v1/${req.url}`;
      if (!req.url.includes("graph.microsoft.com")) {
        return from(this.getToken()).pipe(
          mergeMap((token: any) => {
            req = req.clone({
              setHeaders: {
                authorization: `JWT ${token}`,
                // "Upgrade-Insecure-Requests":"1",
              },
              url: path,
            });
            return next
              .handle(req)
              .pipe(catchError((x) => this.handleAuthError(x)));
          })
        );
      } else {
        return next.handle(req).pipe(catchError((x) => this.handleAuthError(x)));
      }
    }
  }

  getToken() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          resolve(token);
        } else {
          reject("token not found");
        }
      } catch (err) {
        reject("token not found");
      }
    });
  }
}
