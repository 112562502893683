import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Calculator } from "src/app/shared/components/scorecard-matrix/scorecard/calculator";
import { ScorecardService } from "src/app/shared/services/scorecard.service";

@Component({
  selector: "app-what-if",
  templateUrl: "./what-if.component.html",
  styleUrls: ["./what-if.component.scss"],
})
export class WhatIfComponent implements OnInit {
  whatIfData: any = null;
  defaultWhatIfItems: any;
  whatIfItems: any;
  updatedValues: any = null;
  sliderValue = 0;
  currentMetricId: any;
  metricsList: any = [];

  constructor(
    public dialogRef: MatDialogRef<WhatIfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ScorecardService: ScorecardService
  ) {}

  ngOnInit(): void {
    this.currentMetricId = this.data?.metricId;
    this.getMetricsList();
  }

  getMetricsList() {
    this.ScorecardService.getMetricsList().subscribe((resp: any) => {
      this.metricsList = resp;
      if (!this.currentMetricId) {
        this.currentMetricId = resp[0].id;
      }
      this.getWhatIfInfo();
    });
  }

  getWhatIfInfo() {
    this.ScorecardService.getWhatIfData(this.currentMetricId).subscribe(
      (resp: any) => {
        this.whatIfData = resp[0];
        this.defaultWhatIfItems = resp[0].default;
        this.whatIfItems = this.whatIfData.dependents;
        this.sliderValue = Math.floor(
          this.whatIfData.default[this.whatIfData.metric.name]
        );
      }
    );
  }

  increment() {
    this.sliderValue++;
    if (this.sliderValue > 100) {
      this.sliderValue = 100;
    }
    this.onInputChange();
  }

  decrement() {
    this.sliderValue--;
    if (this.sliderValue < 0) {
      this.sliderValue = 0;
    }
    this.onInputChange();
  }

  onInputChange() {
    const calc = new Calculator(this.whatIfData);
    calc.calculate();
    calc.changeMetric(this.whatIfData.metric.name, this.sliderValue / 100);
    this.updatedValues = calc.values;
  }

  dialogClose() {
    this.dialogRef.close();
  }
}
