import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransferState, makeStateKey } from "@angular/platform-browser";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ParadeService {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let id = route.params["id"];
    try {
      id = atob(id);
    } catch (e) {
      this.router.navigate(["404"]);
    }
    const detailKey = makeStateKey(`parade/parade/${id}/`);
    return this.http.get(`parade/parade/${id}/`).pipe(
      tap((res: any) => {
        this.transferState.set(detailKey, res);
      })
    );
  }

  createParade(payload: any) {
    return this.http.post(`parade/parade/`, payload);
  }

  updateParade(id: any, payload: any) {
    return this.http.patch(`parade/parade/${id}/`, payload);
  }

  getParade(tagFilterId: any = [], filterName: any = "") {
    let queryParams = "";
    queryParams = "tags=" + [tagFilterId] + "&name=" + filterName;
    return this.http.get(`parade/parade/?${queryParams}`);
  }

  deleteParade(id: any) {
    return this.http.delete(`parade/parade/${id}/`);
  }

  getParadeById(id: any) {
    return this.http.get(`parade/parade/${id}/`);
  }

  getImageBase64(imageUrl: string): Observable<any> {
    return this.http.get(imageUrl);
  }

  downloadImage(id: any) {
    return this.http.get(`parade/files/${id}/`, { responseType: "blob" });
  }

  createPresentation(postdata: any) {
    return this.http.post(`parade/presentation/`, postdata);
  }

  postComments(id: any, formData: any) {
    return this.http.post(`parade/feedback/`, formData);
  }

  getResponseComments(id: any) {
    return this.http.get(`parade/feedback/?parade_id=${id}`);
  }

  createAction(id: any, postdata: any) {
    return this.http.post(
      `parade/parade/parade_action_tracker/${id}/`,
      postdata
    );
  }

  getAction(id: any) {
    return this.http.get(`parade/parade/parade_action_tracker/${id}/`);
  }

  getUsers() {
    return this.http.get(`users/user-list/`);
  }

  getGroups() {
    return this.http.get("base_value/?type=opportunity_function");
  }

  getNamesList() {
    return this.http.get("parade/parade/names/");
  }
}
