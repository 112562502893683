<!-- custom Tree without Forms -->
<section id="treelist" [class]="treeclass" *ngIf="treecardtype == 'simpletree'">
  <div class="goalparent">
    <div class="accordion" id="accordionExample" *ngIf="showChildOnly">
      <div
        class="d-flex justify-content-between align-items-center ml-3"
        *ngFor="let key_result of treearray; let subindex = index"
      >
        <div class="col-md-11 accordion-item my-2 p-0">
          <h2 class="accordion-header d-flex" id="headingOne{{ subindex }}">
            <span class="bubble-right pt-2 pb-2"></span>
            <button
              class="accordion-button pl-3 collapsed"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapseOne' + subindex"
              aria-expanded="true"
              aria-controls="collapseOne{{ subindex }}"
            >
              <div class="col-md-10 p-0">
                <span
                  class="treeid fw-bold"
                  container="body"
                  [ngbPopover]="key_result?.key_title"
                  popoverClass="darkTheme"
                  triggers="mouseenter:mouseleave"
                >
                  {{ key_result?.key_title }}
                </span>
                <span
                  container="body"
                  [ngbPopover]="key_result?.key_result_title"
                  popoverClass="darkTheme"
                  triggers="mouseenter:mouseleave"
                >
                  {{ key_result?.key_result_title }}
                </span>
              </div>
              <div class="col-md-1">
                <img
                  class="ownericon"
                  container="body"
                  [ngbPopover]="parentMappingContent"
                  popoverClass="darkTheme"
                  triggers="mouseenter:mouseleave"
                  src="/assets/images/pages/tree/Owner_icon _pink.svg"
                  alt="close"
                  width="auto"
                  height="auto"
                />
                <ng-template #parentMappingContent>
                  <span
                    *ngIf="
                      key_result?.primary_owners.length == 0 &&
                        key_result?.support_owners.length == 0;
                      else showOwners
                    "
                  >
                    <p>Owners not found</p>
                  </span>
                  <ng-template #showOwners>
                    <div
                      class="d-flex flex-column"
                      *ngFor="
                        let primary of key_result?.primary_owners;
                        index as PrimaryIndex;
                        let last = last
                      "
                    >
                      <div class="flex-fill">
                        <span>{{ primary.full_name }} </span>
                        <span *ngIf="!primary.full_name && primary.username">{{
                          primary.username
                        }}</span>
                        <span
                          *ngIf="
                            !last ||
                            (key_result?.support_owners &&
                              key_result?.support_owners?.length > 0)
                          "
                        >
                          &comma;</span
                        >
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column"
                      *ngFor="
                        let support of key_result?.support_owners;
                        index as SupportIndex;
                        let last = last
                      "
                    >
                      <div class="flex-fill">
                        <span *ngIf="support.full_name"> &nbsp;</span>
                        <span>{{ support.full_name }} </span>
                        <span *ngIf="!support.full_name && support.username">{{
                          support.username
                        }}</span>
                        <span *ngIf="!last"> &comma;</span>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </button>
          </h2>
          <div
            id="collapseOne{{ subindex }}"
            class="accordion-collapse"
            [ngClass]="key_result.hasError == true ? 'show' : 'collapse'"
            aria-labelledby="headingOne{{ subindex }}"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body p-0">
              <div class="treeForm p-2">
                <div class="row text-capitalize">
                  <!-- <div class="col felx-fill"> -->
                  <mat-form-field appearance="outline" class="w-45 mlp-5 col-4">
                    <mat-label>Unit</mat-label>
                    <mat-select
                      placeholder="Unit"
                      [(ngModel)]="key_result.unit"
                      [name]="'unit' + 0 + '-' + subIndex"
                      (ngModelChange)="setMeasurementCriteriaId(key_result)"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <mat-option
                        *ngFor="let opt of unitOptions"
                        [value]="opt?.id"
                      >
                        {{ opt?.display_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-4 pl-0">
                    <mat-label>Frequency</mat-label>
                    <mat-select
                      placeholder="Frequency"
                      [(ngModel)]="key_result.frequency"
                      [name]="'frequency' + 0 + '-' + subIndex"
                      (ngModelChange)="setMeasurementCriteriaId(key_result)"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <mat-option
                        *ngFor="let opt of frequencyOptions"
                        [value]="opt?.id"
                      >
                        {{ opt?.display_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-4 pl-0">
                    <mat-label>Expected End Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker1"
                      [(ngModel)]="key_result.end_date"
                      [ngModelOptions]="{ standalone: true }"
                      dateFormat="yyyy/MM/dd"
                      (click)="picker1.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1> </mat-datepicker>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="mt-3 col-6">
                    <mat-label>Target</mat-label>
                    <input
                      matInput
                      type="number"
                      status="basic"
                      placeholder="Target"
                      autocomplete="off"
                      [(ngModel)]="key_result.target"
                      [name]="'target' + 0 + '-' + subIndex"
                      (ngModelChange)="setMeasurementCriteriaId(key_result)"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-form-field>
                  <div class="form-row pl-0 pr-2 pb-2 mb-2 col-6">
                    <div class="col-md-4">
                      <label class="red-value">{{
                        staticText.color_names.red
                      }}</label>
                      <input
                        type="number"
                        class="form-control form-field-1"
                        [(ngModel)]="key_result.red_range_value"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="0-10"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="amber-value">{{
                        staticText.color_names.amber
                      }}</label>
                      <input
                        type="number"
                        class="form-control form-field-2"
                        [(ngModel)]="key_result.amber_range_value"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="10-20"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="green-value">{{
                        staticText.color_names.green
                      }}</label>
                      <input
                        type="number"
                        class="form-control form-field-3"
                        [(ngModel)]="key_result.green_range_value"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="20-30"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col mx-2 p-0 cancel-icon"
          *ngIf="removeicon && !disableEdit"
        >
          <div
            class="removeicon pl-2"
            (click)="AddRemoveColumn(key_result, key_result?.is_custom)"
          >
            <img
              class="removeimg cursor-pointer"
              src="/assets/images/pages/tree/treecross.svg"
              alt="close"
              width="auto"
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="accordion accordion-flush goalaccordion"
      [id]="parentTagName + treeindex"
      *ngIf="!showChildOnly"
    >
      <div
        class="accordion-item goalitem"
        data-bs-toggle="collapse"
        [ngClass]="{ 'add-border': !parentTree[treeindex] }"
        [attr.data-bs-target]="'#goal' + parentTagName + treeindex"
        aria-expanded="true"
        [attr.aria-controls]="'goal' + treeindex"
      >
        <div class="accordion-header p-1 goalheader cursor-pointer">
          <div
            class="d-flex flex-column justify-content-around"
            (click)="parentTree[treeindex] = !parentTree[treeindex]"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center mt-1"
            >
              <div class="p-1 px-2">
                <h5 class="fw-bold goalhead">
                  {{ treearray?.goal_title | titlecase }}
                </h5>
              </div>
              <div class="d-flex justify-content-center">
                <div class="treearrowgoal px-2 d-flex align-items-center">
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="!parentTree[treeindex]"
                  >
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="parentTree[treeindex]"
                  >
                    keyboard_arrow_up
                  </mat-icon>
                </div>
              </div>
            </div>
            <!-- <div class="p-2" [id]="'goal'+parentTagName+treeindex" [ngClass]="treearray?.hasError==true?'show':'collapse'">
                            <div class="card">
                                <div class="card-body" style="padding: 0.5rem;">
                                    <p class="text-wrap goaldesc text-truncate mb-0">
                                        {{treearray?.goal_description}}
                                    </p>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
      <div
        [id]="'goal' + parentTagName + treeindex"
        class="accordion-collapse"
        [ngClass]="treearray?.hasError == true ? 'show' : 'collapse'"
        [attr.aria-labelledby]="parentTagName + treeindex"
        [attr.data-bs-parent]="parentTagName + treeindex"
      >
        <div class="accordion-body goalbody pt-2 pb-0 pe-0 ps-0">
          <div
            class="mainparent"
            *ngFor="let parent of treearray?.key_results; index as parentindex"
          >
            <div class="d-flex">
              <div
                class="pe-1 ps-1 flex-shrink-1 dotted"
                [ngClass]="{
                  dottedNoLength:
                    treearray?.key_results?.length - 1 == parentindex
                }"
              >
                <div
                  class="accordion-item parentitem cursor-pointer pb-2"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="
                    '#' + subParentTagname + parentindex + '_' + treeindex
                  "
                  aria-expanded="true"
                  [attr.aria-controls]="'parent' + parentindex"
                  (click)="
                    subParentTree[parentindex] = !subParentTree[parentindex]
                  "
                >
                  <div
                    class="treearrowparent d-flex align-items-center"
                    *ngIf="subParentTagname + parentindex"
                  >
                    <mat-icon
                      class="material-icons-outlined greencircle"
                      *ngIf="!subParentTree[parentindex]"
                    >
                      keyboard_arrow_down
                    </mat-icon>
                    <mat-icon
                      class="material-icons-outlined greencircle"
                      *ngIf="subParentTree[parentindex]"
                    >
                      keyboard_arrow_up
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="pe-1 ps-1 w-100">
                <div class="accordion accordion-flush parentaccordion">
                  <div
                    class="accordion-item parentitem cursor-pointer pb-2"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="
                      '#' + subParentTagname + parentindex + '_' + treeindex
                    "
                    aria-expanded="true"
                    [attr.aria-controls]="'parent' + parentindex"
                    (click)="
                      subParentTree[parentindex] = !subParentTree[parentindex]
                    "
                  >
                    <div class="accordion-header parentheader">
                      <div class="d-flex">
                        <span class="bubble-right pt-2 pb-2"></span>
                        <div class="pe-1 ps-1 w-100">
                          <div
                            class="d-flex flex-row justify-content-between align-items-center p-1"
                          >
                            <div class="parentcontext two-lines">
                              <h5 class="parenthead w-100 mb-0">
                                <span
                                  class="treeid"
                                  container="body"
                                  [ngbPopover]="parent?.key_title"
                                  popoverClass="darkTheme"
                                  triggers="mouseenter:mouseleave"
                                >
                                  {{ parent?.key_title }}
                                </span>
                                <span
                                  class="fw-bold"
                                  container="body"
                                  [ngbPopover]="parent?.key_result_title"
                                  popoverClass="darkTheme"
                                  triggers="mouseenter:mouseleave"
                                >
                                  {{ parent?.key_result_title }}
                                </span>
                              </h5>
                              <p
                                class="parentdesc text-wrap"
                                container="body"
                                [ngbPopover]="parent?.key_result_description"
                                popoverClass="darkTheme"
                                triggers="mouseenter:mouseleave"
                              >
                                {{ parent?.key_result_description }}
                              </p>
                            </div>
                            <div
                              class="p-1 d-flex justify-content-center align-items-center showownersparent"
                            >
                              <ng-container>
                                <img
                                  class="ownericon"
                                  container="body"
                                  [ngbPopover]="parentMappingContent"
                                  popoverClass="darkTheme"
                                  triggers="mouseenter:mouseleave"
                                  src="/assets/images/pages/tree/Owner_icon _pink.svg"
                                  alt="close"
                                  width="auto"
                                  height="auto"
                                />
                                <ng-template #parentMappingContent>
                                  <span
                                    *ngIf="
                                      parent?.primary_owners.length == 0 &&
                                        parent?.support_owners.length == 0;
                                      else showOwners
                                    "
                                  >
                                    <p>Owners not found</p>
                                  </span>
                                  <ng-template #showOwners>
                                    <div
                                      class="d-flex flex-column"
                                      *ngFor="
                                        let primary of parent?.primary_owners;
                                        index as PrimaryIndex;
                                        let last = last
                                      "
                                    >
                                      <div class="flex-fill">
                                        <span>{{ primary.full_name }} </span>
                                        <span
                                          *ngIf="
                                            !primary.full_name &&
                                            primary.username
                                          "
                                          >{{ primary.username }}</span
                                        >
                                        <span
                                          *ngIf="
                                            !last ||
                                            (parent?.support_owners &&
                                              parent?.support_owners?.length >
                                                0)
                                          "
                                        >
                                          &comma;</span
                                        >
                                      </div>
                                    </div>
                                    <div
                                      class="d-flex flex-column"
                                      *ngFor="
                                        let support of parent?.support_owners;
                                        index as SupportIndex;
                                        let last = last
                                      "
                                    >
                                      <div class="flex-fill">
                                        <span *ngIf="support.full_name">
                                          &nbsp;</span
                                        >
                                        <span>{{ support.full_name }} </span>
                                        <span
                                          *ngIf="
                                            !support.full_name &&
                                            support.username
                                          "
                                          >{{ support.username }}</span
                                        >
                                        <span *ngIf="!last"> &comma;</span>
                                      </div>
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    [id]="subParentTagname + parentindex + '_' + treeindex"
                    class="accordion-collapse"
                    [ngClass]="parent?.hasError == true ? 'show' : 'collapse'"
                    [attr.aria-labelledby]="parentTagName + treeindex"
                    [attr.data-bs-parent]="parentTagName + treeindex"
                  >
                    <div class="accordion-body parentbody pt-0 pb-2 pe-0 ps-0">
                      <div
                        class="subparent mb-2"
                        [ngClass]="{ okrlog: isOKRLog }"
                        *ngFor="
                          let subparent of parent?.child_key_results;
                          index as subIndex
                        "
                      >
                        <div class="d-flex">
                          <div
                            class="pe-1 ps-1 flex-shrink-1 dotted"
                            [ngClass]="{
                              dottedNoLength:
                                parent?.child_key_results?.length - 1 ==
                                subIndex
                            }"
                          >
                            <div
                              class="treearrowsubparent d-flex align-items-center"
                            >
                              <span class="yellowcircle"></span>
                            </div>
                          </div>
                          <div class="pe-1 ps-1 w-100 pb-0 border-form">
                            <div
                              class="accordion-body subparentaccordion"
                              data-bs-toggle="collapse"
                              aria-expanded="true"
                              [attr.data-bs-target]="
                                '#' +
                                subParentTagname +
                                parentindex +
                                '_' +
                                subIndex +
                                '_' +
                                treeindex
                              "
                              aria-expanded="true"
                              [attr.aria-controls]="
                                subParentTagname + parentindex + '_' + treeindex
                              "
                            >
                              <div class="d-flex">
                                <span class="bubble-right pt-2 pb-2"></span>
                                <div class="pe-1 ps-1 w-100">
                                  <div
                                    class="d-flex justify-content-between align-items-center"
                                  >
                                    <div
                                      class="p-1 subparentcontext three-lines"
                                    >
                                      <p
                                        class="recommended-badge m-0 mb-1"
                                        *ngIf="subparent?.is_recomended"
                                      >
                                        <i class="fa fa-star"></i>Recommended
                                      </p>
                                      <h5 class="subparenthead w-100">
                                        <span
                                          class="treeid cursor-pointer"
                                          container="body"
                                          [ngbPopover]="subparent?.key_title"
                                          popoverClass="darkTheme"
                                          triggers="mouseenter:mouseleave"
                                        >
                                          {{ subparent?.key_title }}</span
                                        >

                                        <span
                                          class="fw-bold cursor-pointer"
                                          container="body"
                                          [ngbPopover]="
                                            subparent?.key_result_title
                                          "
                                          popoverClass="darkTheme"
                                          triggers="mouseenter:mouseleave"
                                        >
                                          {{ subparent?.key_result_title }}
                                        </span>
                                      </h5>
                                    </div>
                                    <div
                                      class="p-0 d-flex flex-column justify-content-center align-items-center actionicons"
                                    >
                                      <ng-container>
                                        <div
                                          class="pb-1"
                                          *ngIf="removeicon && !disableEdit"
                                        >
                                          <div
                                            class="removeicon"
                                            (click)="
                                              AddRemoveColumn(
                                                subparent,
                                                treearray?.is_custom
                                              )
                                            "
                                          >
                                            <img
                                              class="img-fluid removeimg"
                                              src="/assets/images/pages/tree/treecross.svg"
                                              alt="close"
                                              width="auto"
                                              height="auto"
                                            />
                                          </div>
                                        </div>
                                      </ng-container>
                                      <div
                                        class="d-flex flex-row justify-content-around align-items-center"
                                      >
                                        <ng-container>
                                          <div
                                            class="pb-1 deleteicon"
                                            *ngIf="deleteicon && !disableEdit"
                                            (click)="DeleteKey_Toe(subparent)"
                                          >
                                            <mat-icon
                                              *ngIf="subparent?.delete == true"
                                              class="material-icons-outlined text-danger"
                                            >
                                              delete
                                            </mat-icon>
                                          </div>
                                          <div
                                            class="pb-2 deleteicon"
                                            *ngIf="deleteicon && !disableEdit"
                                            (click)="DeleteKey_Toe(subparent)"
                                          >
                                            <mat-icon
                                              *ngIf="subparent?.delete == false"
                                              class="material-icons-outlined text-muted"
                                            >
                                              delete
                                            </mat-icon>
                                          </div>
                                        </ng-container>
                                        <ng-container>
                                          <div
                                            class="pb-2 addicon"
                                            *ngIf="addicon && !disableEdit"
                                            (click)="
                                              AddRemoveColumn(
                                                subparent,
                                                treearray?.is_custom
                                              )
                                            "
                                          >
                                            <mat-icon
                                              class="material-icons-outlined"
                                            >
                                              add
                                            </mat-icon>
                                          </div>
                                        </ng-container>
                                      </div>
                                      <div class="pb-2 showownerschild">
                                        <ng-container>
                                          <img
                                            class="ownericon"
                                            container="body"
                                            [ngbPopover]="childMappingContent"
                                            popoverClass="darkTheme"
                                            triggers="mouseenter:mouseleave"
                                            src="/assets/images/pages/tree/Owner_icon_blue.svg"
                                            alt="close"
                                            width="auto"
                                            height="auto"
                                          />
                                          <ng-template #childMappingContent>
                                            <span
                                              *ngIf="
                                                subparent?.primary_owners
                                                  .length == 0 &&
                                                  subparent?.support_owners
                                                    .length == 0;
                                                else showOwners
                                              "
                                            >
                                              <p>Owners not found</p>
                                            </span>
                                            <ng-template #showOwners>
                                              <div
                                                class="d-flex flex-column"
                                                *ngFor="
                                                  let primary of subparent?.primary_owners;
                                                  index as PrimaryIndex;
                                                  let last = last
                                                "
                                              >
                                                <div class="flex-fill">
                                                  <span
                                                    >{{ primary.full_name }}
                                                  </span>
                                                  <span
                                                    *ngIf="
                                                      !primary.full_name &&
                                                      primary.username
                                                    "
                                                    >{{
                                                      primary.username
                                                    }}</span
                                                  >
                                                  <span
                                                    *ngIf="
                                                      !last ||
                                                      (subparent?.support_owners &&
                                                        subparent
                                                          ?.support_owners
                                                          ?.length > 0)
                                                    "
                                                  >
                                                    &comma;</span
                                                  >
                                                </div>
                                              </div>
                                              <div
                                                class="d-flex flex-column"
                                                *ngFor="
                                                  let support of subparent?.support_owners;
                                                  index as SupportIndex;
                                                  let last = last
                                                "
                                              >
                                                <div class="flex-fill">
                                                  <span
                                                    *ngIf="support.full_name"
                                                  >
                                                    &nbsp;</span
                                                  >
                                                  <span>{{
                                                    support.full_name
                                                  }}</span>
                                                  <span
                                                    *ngIf="
                                                      !support.full_name &&
                                                      support.username
                                                    "
                                                    >{{
                                                      support.username
                                                    }}</span
                                                  >
                                                  <span *ngIf="!last">
                                                    &comma;
                                                  </span>
                                                </div>
                                              </div>
                                            </ng-template>
                                          </ng-template>
                                          <ng-template #childMappingContent>
                                            <span
                                              *ngIf="
                                                risk?.primary_owners.length ==
                                                  0 &&
                                                  risk?.support_owners.length ==
                                                    0;
                                                else showOwners
                                              "
                                            >
                                              <p>Owners not found</p>
                                            </span>
                                            <ng-template #showOwners>
                                              <div
                                                class="d-flex flex-column"
                                                *ngFor="
                                                  let primary of risk?.primary_owners;
                                                  index as PrimaryIndex;
                                                  let last = last
                                                "
                                              >
                                                <div class="flex-fill">
                                                  <span
                                                    >{{ primary.full_name }}
                                                  </span>
                                                  <span
                                                    *ngIf="
                                                      !primary.full_name &&
                                                      primary.username
                                                    "
                                                    >{{
                                                      primary.username
                                                    }}</span
                                                  >
                                                  <span
                                                    *ngIf="
                                                      !last ||
                                                      (risk?.support_owners &&
                                                        risk?.support_owners
                                                          ?.length > 0)
                                                    "
                                                  >
                                                    &comma;</span
                                                  >
                                                </div>
                                              </div>
                                              <div
                                                class="d-flex flex-column"
                                                *ngFor="
                                                  let support of risk?.support_owners;
                                                  index as SupportIndex;
                                                  let last = last
                                                "
                                              >
                                                <div class="flex-fill">
                                                  <span
                                                    *ngIf="support.full_name"
                                                  >
                                                    &nbsp;</span
                                                  >
                                                  <span
                                                    >{{ support.full_name }}
                                                  </span>
                                                  <span
                                                    *ngIf="
                                                      !support.full_name &&
                                                      support.username
                                                    "
                                                    >{{
                                                      support.username
                                                    }}</span
                                                  >
                                                  <span *ngIf="!last">
                                                    &comma;</span
                                                  >
                                                </div>
                                              </div>
                                            </ng-template>
                                          </ng-template>
                                        </ng-container>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ng-container *ngIf="isOKRLog">
                              <div
                                class="treeForm formcontainer accordion-collapse"
                                [ngClass]="
                                  subparent?.hasError == true
                                    ? 'show'
                                    : 'collapse'
                                "
                                [id]="
                                  subParentTagname +
                                  parentindex +
                                  '_' +
                                  subIndex +
                                  '_' +
                                  treeindex
                                "
                                [attr.aria-labelledby]="
                                  subParentTagname +
                                  '_' +
                                  subIndex +
                                  '_' +
                                  parentindex
                                "
                                [attr.data-bs-parent]="
                                  subParentTagname +
                                  parentindex +
                                  '_' +
                                  treeindex
                                "
                              >
                                <div class="treeForm p-2">
                                  <div class="row row-cols-2 text-capitalize">
                                    <mat-form-field
                                      appearance="outline"
                                      class="w-45 mlp-5"
                                    >
                                      <mat-label>Unit</mat-label>
                                      <mat-select
                                        placeholder="Unit"
                                        [(ngModel)]="subparent.unit"
                                        [name]="
                                          'unit' + treeindex + '-' + subIndex
                                        "
                                        (ngModelChange)="
                                          setMeasurementCriteriaId(subparent)
                                        "
                                        [ngModelOptions]="{ standalone: true }"
                                      >
                                        <mat-option
                                          *ngFor="let opt of unitOptions"
                                          [value]="opt?.id"
                                        >
                                          {{ opt?.display_name }}</mat-option
                                        >
                                      </mat-select>
                                    </mat-form-field>
                                    <mat-form-field
                                      appearance="outline"
                                      class="w-50"
                                    >
                                      <mat-label>Frequency</mat-label>
                                      <mat-select
                                        placeholder="Frequency"
                                        [(ngModel)]="subparent.frequency"
                                        [name]="
                                          'frequency' +
                                          treeindex +
                                          '-' +
                                          subIndex
                                        "
                                        (ngModelChange)="
                                          setMeasurementCriteriaId(subparent)
                                        "
                                        [ngModelOptions]="{ standalone: true }"
                                      >
                                        <mat-option
                                          *ngFor="let opt of frequencyOptions"
                                          [value]="opt?.id"
                                        >
                                          {{ opt?.display_name }}</mat-option
                                        >
                                      </mat-select>
                                    </mat-form-field>
                                    <mat-form-field
                                      appearance="outline"
                                      class="w-50 mt-3"
                                    >
                                      <mat-label>Target</mat-label>
                                      <input
                                        matInput
                                        type="number"
                                        status="basic"
                                        placeholder="Target"
                                        autocomplete="off"
                                        [(ngModel)]="subparent.target"
                                        [name]="
                                          'target' + treeindex + '-' + subIndex
                                        "
                                        (ngModelChange)="
                                          setMeasurementCriteriaId(subparent)
                                        "
                                        [ngModelOptions]="{ standalone: true }"
                                      />
                                    </mat-form-field>
                                    <div class="form-row px-3 pb-2 mb-2">
                                      <div class="col-md-4">
                                        <label class="red-value">{{
                                          staticText.color_names.red
                                        }}</label>
                                        <input
                                          type="text"
                                          class="form-control form-field-1"
                                          [(ngModel)]="
                                            subparent.red_range_value
                                          "
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          placeholder="0-10"
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        <label class="amber-value">{{
                                          staticText.color_names.amber
                                        }}</label>
                                        <input
                                          type="text"
                                          class="form-control form-field-2"
                                          [(ngModel)]="
                                            subparent.amber_range_value
                                          "
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          placeholder="10-20"
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        <label class="green-value">{{
                                          staticText.color_names.green
                                        }}</label>
                                        <input
                                          type="text"
                                          class="form-control form-field-3"
                                          [(ngModel)]="
                                            subparent.green_range_value
                                          "
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          placeholder="20-30"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- custom Tree with forms -->

<section
  id="formtreelist"
  [class]="treeclass"
  *ngIf="treecardtype == 'formtree'"
>
  <div class="formParent">
    <div class="accordion" id="accordionExample" *ngIf="showChildOnly">
      <div
        class="d-flex justify-content-between align-items-center ml-3"
        *ngFor="let child of treearray; let subindex = index"
        [ngClass]="{ hide: isRiskUpdate && !child?.id }"
      >
        <div class="col-md-11 accordion-item my-2 p-0">
          <h2 class="accordion-header d-flex" id="headingOne{{ subindex }}">
            <span class="bubble-right pt-2 pb-2"></span>
            <button
              class="accordion-button p-2 pl-3 collapsed"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapseOne' + subindex"
              aria-expanded="true"
              aria-controls="collapseOne{{ subindex }}"
              [ngClass]="{ 'p-3': isRiskUpdate }"
            >
              <div class="col-md-11 p-0 d-flex justify-content-between">
                <div>
                  <span class="treeId pe-1 fw-bold">{{ child?.prefix }}</span>
                  <span
                    [ngbPopover]="
                      child?.measurement_criteria_display_name ||
                        child?.risk_display_name | titlecase
                    "
                    container="body"
                    popoverClass="darkTheme"
                    triggers="mouseenter:mouseleave"
                    >{{
                      child?.measurement_criteria_display_name ||
                        child?.risk_display_name | titlecase
                    }}
                  </span>
                </div>
                <div class="infoicon ml-2" *ngIf="isRiskUpdate">
                  <mat-icon
                    class="color-info infoicon ml-2"
                    (click)="openRiskDetails(child, RiskDetailsModal)"
                  >
                    info</mat-icon
                  >
                  <ng-container>
                    <img
                      class="ownericon ml-2"
                      container="body"
                      [ngbPopover]="childMappingContent"
                      popoverClass="darkTheme"
                      triggers="mouseenter:mouseleave"
                      src="/assets/images/pages/tree/Owner_icon_blue.svg"
                      alt="close"
                      width="auto"
                      height="auto"
                    />
                    <ng-template #childMappingContent>
                      <span
                        *ngIf="!child?.risk_owner?.full_name; else showOwners"
                      >
                        <p>Owners not found</p>
                      </span>
                      <ng-template #showOwners>
                        <div class="d-flex flex-column">
                          <div class="flex-fill">
                            <span>{{ child?.risk_owner?.full_name }}</span>
                            <span
                              *ngIf="
                                !child?.risk_owner?.full_name &&
                                child?.risk_owner?.username
                              "
                              >{{ child?.risk_owner?.username }}</span
                            >
                          </div>
                        </div>
                        <!--<div class="d-flex flex-column"
                                                *ngFor="let support of child?.support_owners;index as SupportIndex;let last=last">
                                                <div class="flex-fill">
                                                    <span
                                                        *ngIf="support.full_name">
                                                        &nbsp;</span>
                                                    <span>{{support.full_name}}</span>
                                                    <span
                                                        *ngIf="!support.full_name && support.username">{{support.username}}</span>
                                                    <span *ngIf="!last">
                                                        &comma;
                                                    </span>
                                                </div>
                                            </div> -->
                      </ng-template>
                    </ng-template>
                    <ng-template #childMappingContent>
                      <span
                        *ngIf="
                          risk?.primary_owners.length == 0 &&
                            risk?.support_owners.length == 0;
                          else showOwners
                        "
                      >
                        <p>Owners not found</p>
                      </span>
                      <ng-template #showOwners>
                        <div
                          class="d-flex flex-column"
                          *ngFor="
                            let primary of risk?.primary_owners;
                            index as PrimaryIndex;
                            let last = last
                          "
                        >
                          <div class="flex-fill">
                            <span>{{ primary.full_name }} </span>
                            <span
                              *ngIf="!primary.full_name && primary.username"
                              >{{ primary.username }}</span
                            >
                            <span
                              *ngIf="
                                !last ||
                                (risk?.support_owners &&
                                  risk?.support_owners?.length > 0)
                              "
                            >
                              &comma;</span
                            >
                          </div>
                        </div>
                        <div
                          class="d-flex flex-column"
                          *ngFor="
                            let support of risk?.support_owners;
                            index as SupportIndex;
                            let last = last
                          "
                        >
                          <div class="flex-fill">
                            <span *ngIf="support.full_name"> &nbsp;</span>
                            <span>{{ support.full_name }} </span>
                            <span
                              *ngIf="!support.full_name && support.username"
                              >{{ support.username }}</span
                            >
                            <span *ngIf="!last"> &comma;</span>
                          </div>
                        </div>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="collapseOne{{ subindex }}"
            class="accordion-collapse"
            [ngClass]="child.hasError ? 'show' : 'collapse'"
            aria-labelledby="headingOne{{ subindex }}"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body p-0">
              <div
                class="treeForm RiskLogform p-2"
                *ngIf="formShow && RiskLogform"
              >
                <div
                  class="row row-cols-2 text-capitalize"
                  *ngIf="!isRiskUpdate"
                >
                  <ng-container *ngIf="!child.id">
                    <div class="col felx-fill">
                      <mat-form-field
                        class="example-full-width"
                        appearance="outline"
                      >
                        <mat-label>Risk Impact</mat-label>
                        <input
                          type="text"
                          matInput
                          placeholder="Select"
                          aria-label="Number"
                          class="p-0"
                          [name]="'impact' + treeindex + '-' + subindex"
                          [(ngModel)]="child.risk_impact"
                          [ngModelOptions]="{ standalone: true }"
                          [matAutocomplete]="Impact"
                        />
                        <mat-autocomplete
                          [displayWith]="displayFn1"
                          #Impact="matAutocomplete"
                        >
                          <mat-option
                            *ngFor="let iitem of formArray?.riskImpactList"
                            [value]="iitem"
                          >
                            {{ iitem?.display_name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="col-6 felx-fill">
                      <!-- <mat-form-field
                                            class="example-full-width"
                                            appearance="outline"> -->
                      <label class="col mb-0 fs-14">Risk Likelyhood</label>
                      <!-- <input type="number" matInput min="0" max="1"
                                                aria-label="Number" class="p-0" [disabled]="child.id"
                                                [name]="'likelyhood'+treeindex+'-'+subindex"
                                                [(ngModel)]="child.risk_likelihood"
                                                [ngModelOptions]="{standalone: true}"> -->
                      <div class="d-flex">
                        <mat-slider
                          class="col ml-2 w-100 py-0"
                          min="0"
                          max="1"
                          step="0.1"
                          [disabled]="child.id"
                          [(ngModel)]="child.risk_likelihood"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <input matSliderThumb />
                        </mat-slider>
                        <p class="d-flex px-2 align-items-end mb-0">
                          {{ child.risk_likelihood }}
                        </p>
                      </div>
                      <div class="d-flex slider-legends">
                        <span class="color-red">Low</span>
                        <span class="color-blue">High</span>
                      </div>
                      <!-- </mat-form-field> -->
                    </div>
                  </ng-container>
                  <ng-container *ngIf="child.id">
                    <div class="col felx-fill">
                      <mat-form-field
                        class="example-full-width"
                        appearance="outline"
                      >
                        <mat-label>Risk Impact</mat-label>
                        <input
                          type="text"
                          matInput
                          placeholder="Risk Impact"
                          [disabled]="child.id"
                          aria-label="Number"
                          class="p-0"
                          [name]="'impact' + treeindex + '-' + subindex"
                          [value]="child.risk_impact?.display_name"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-6 felx-fill">
                      <!-- <mat-form-field
                                            class="example-full-width"
                                            appearance="outline"> -->
                      <label class="col mb-0 fs-14">Risk Likelyhood</label>
                      <div class="d-flex">
                        <mat-slider
                          class="col ml-2 w-100 py-0"
                          min="0"
                          max="1"
                          step="0.1"
                          [disabled]="child.id"
                          [value]="child.risk_likelihood"
                        >
                          <input matSliderThumb />
                        </mat-slider>
                        <p class="d-flex px-2 align-items-end mb-0">
                          {{ child.risk_likelihood }}
                        </p>
                      </div>
                      <div class="d-flex slider-legends">
                        <span class="color-red">Low</span>
                        <span class="color-blue">High</span>
                      </div>
                      <!-- <input type="number" matInput min="0" max="1"
                                                aria-label="Number" class="p-0" [disabled]="child.id"
                                                [name]="'likelyhood'+treeindex+'-'+subindex"
                                                [value]="child.risk_likelihood"> -->
                      <!-- </mat-form-field> -->
                    </div>
                  </ng-container>
                  <div class="col felx-fill">
                    <mat-form-field appearance="outline">
                      <mat-label>Due Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        [name]="'due' + treeindex + '-' + subindex"
                        [(ngModel)]="child.due_date"
                        [ngModelOptions]="{ standalone: true }"
                        dateFormat="yyyy/MM/dd"
                        (click)="picker.open()"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker> </mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col felx-fill">
                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>Risk Owner</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="Select"
                        aria-label="Number"
                        class="p-0"
                        [name]="'delegate' + treeindex + '-' + subindex"
                        [(ngModel)]="child.risk_owner"
                        [ngModelOptions]="{ standalone: true }"
                        [matAutocomplete]="Delegate"
                      />
                      <mat-autocomplete
                        [displayWith]="displayFn"
                        #Delegate="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="
                            let delegateList of formArray?.deliveryOwnersList
                          "
                          [value]="delegateList"
                        >
                          {{ delegateList.name || delegateList.mail }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col felx-fill">
                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>Status</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="Status"
                        aria-label="Number"
                        class="px-0"
                        [name]="'status' + treeindex + '-' + subindex"
                        [value]="child.status?.label || 'Open'"
                        disabled
                      />
                    </mat-form-field>
                  </div>
                  <div class="col felx-fill">
                    <mat-form-field appearance="outline">
                      <mat-label> Risk Phase </mat-label>
                      <input
                        type="text"
                        matInput
                        [(ngModel)]="child.risk_phase"
                        [name]="'risk_phase' + treeindex + '-' + subindex"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Risk Phase"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col felx-fill">
                    <mat-form-field appearance="outline">
                      <mat-label> Impact Description </mat-label>
                      <textarea
                        type="text"
                        matInput
                        cdkTextareaAutosize
                        [(ngModel)]="child.impact_description"
                        [name]="
                          'impact_description' + treeindex + '-' + subindex
                        "
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Type your Impact Description here"
                      >
                      </textarea>
                    </mat-form-field>
                  </div>
                  <div class="col felx-fill d-flex">
                    <mat-form-field appearance="outline">
                      <mat-label> Mitigation Plan </mat-label>
                      <textarea
                        type="text"
                        matInput
                        cdkTextareaAutosize
                        [(ngModel)]="child.risk_mitigation"
                        [name]="'risk_mitigation' + treeindex + '-' + subindex"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Type your Risk Mitigation plan here"
                      >
                      </textarea>
                    </mat-form-field>
                    <div class="infoicon ml-2" *ngIf="isCentrePane">
                      <mat-icon
                        class="color-info infoicon"
                        (click)="
                          openMeasureofSuccessModal(
                            child,
                            treearray?.is_custom,
                            MeasureofSuccessModal,
                            $event
                          )
                        "
                      >
                        info</mat-icon
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="row row-cols-2 text-capitalize"
                  *ngIf="isRiskUpdate"
                >
                  <div class="col-3 felx-fill">
                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>Status</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="Select"
                        aria-label="Number"
                        class="px-0"
                        [name]="'update_status' + treeindex + '-' + subindex"
                        [(ngModel)]="child.update_status"
                        [ngModelOptions]="{ standalone: true }"
                        [matAutocomplete]="update_status"
                      />
                      <mat-autocomplete
                        [displayWith]="displayFn"
                        #update_status="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="let status of formArray?.statusList"
                          [value]="status"
                        >
                          {{ status.label }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div
                    class="col-3 felx-fill"
                    *ngIf="child.update_status?.slug == 'project_risk_transfer'"
                  >
                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>New Owner</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="Select"
                        aria-label="Number"
                        class="p-0"
                        [name]="'new_owner' + treeindex + '-' + subindex"
                        [(ngModel)]="child.new_owner"
                        [ngModelOptions]="{ standalone: true }"
                        [matAutocomplete]="NewOwner"
                      />
                      <mat-autocomplete
                        [displayWith]="displayFn"
                        #NewOwner="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="
                            let delegateList of formArray?.deliveryOwnersList
                          "
                          [value]="delegateList"
                        >
                          {{ delegateList.name || delegateList.mail }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col-4 pl-0 felx-fill d-flex align-items-center">
                    <mat-form-field appearance="outline">
                      <mat-label>Actual Close/End Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        [name]="'end_date' + treeindex + '-' + subindex"
                        [(ngModel)]="child.end_date"
                        [ngModelOptions]="{ standalone: true }"
                        dateFormat="yyyy/MM/dd"
                        (click)="picker.open()"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker> </mat-datepicker>
                    </mat-form-field>
                    <div class="ml-3">
                      <img
                        class="statistics-img cursor-pointer"
                        (click)="openRiskHistory(child, RiskHistoryModal)"
                        src="./assets/images/graphics.svg"
                      />
                    </div>
                  </div>
                  <div class="col-8 felx-fill">
                    <mat-form-field appearance="outline">
                      <mat-label> Remarks </mat-label>
                      <textarea
                        type="text"
                        matInput
                        cdkTextareaAutosize
                        [(ngModel)]="child.remarks"
                        [name]="'remarks' + treeindex + '-' + subindex"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Type your remarks here"
                      >
                      </textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-2 d-flex align-items-center">
                    <button
                      class="saveRisk ml-0 text-white text-capitalize"
                      mat-raised-button
                      [disabled]="
                        !child.update_status ||
                        !child.end_date ||
                        !child.remarks
                      "
                      (click)="onClickSaveRisk(child)"
                      *ngxPermissionsOnly="['delivery.change_projectmapping']"
                    >
                      {{ staticText.buttons.save_btn }}
                    </button>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="formShow && !RiskLogform">
                <div
                  class="formcontainer accordion-collapse"
                  [id]="
                    formTagName +
                    subParentindex +
                    '_' +
                    childIndex +
                    '_' +
                    treeindex
                  "
                  [attr.aria-labelledby]="subParentTagname + subParentindex"
                  [attr.data-bs-parent]="subParentTagname + subParentindex"
                >
                  <div class="treeForm p-2">
                    <div class="row text-capitalize">
                      <!-- <div class="col felx-fill"> -->
                      <mat-form-field
                        appearance="outline"
                        class="w-45 mlp-5 col-4"
                      >
                        <mat-label>Unit</mat-label>
                        <mat-select
                          placeholder="Unit"
                          [(ngModel)]="child.unit"
                          [name]="'unit' + 0 + '-' + subIndex"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-option
                            *ngFor="let opt of unitOptions"
                            [value]="opt?.id"
                          >
                            {{ opt?.display_name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-4 pl-0">
                        <mat-label>Frequency</mat-label>
                        <mat-select
                          placeholder="Frequency"
                          [(ngModel)]="child.frequency"
                          [name]="'frequency' + 0 + '-' + subIndex"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-option
                            *ngFor="let opt of frequencyOptions"
                            [value]="opt?.id"
                          >
                            {{ opt?.display_name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-4 pl-0">
                        <mat-label>Expected End Date</mat-label>
                        <input
                          matInput
                          [matDatepicker]="picker1"
                          [(ngModel)]="child.end_date"
                          [ngModelOptions]="{ standalone: true }"
                          dateFormat="yyyy/MM/dd"
                          (click)="picker1.open()"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker1">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1> </mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Milestone Phase </mat-label>
                        <input
                          type="text"
                          matInput
                          [(ngModel)]="child.milestone"
                          [name]="'milestone' + treeindex + '-' + childIndex"
                          (ngModelChange)="setMeasurementCriteriaId(child)"
                          [ngModelOptions]="{ standalone: true }"
                          placeholder="Type your MileStone Phase here"
                          [disabled]="formInputDisable"
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Target</mat-label>
                        <input
                          matInput
                          type="number"
                          status="basic"
                          placeholder="Target"
                          autocomplete="off"
                          [(ngModel)]="child.target"
                          [name]="'target' + 0 + '-' + subIndex"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>
                      <div class="form-row pr-2 pb-2 mb-2 col-6">
                        <div class="col-md-4">
                          <label class="red-value">{{
                            staticText.color_names.red
                          }}</label>
                          <input
                            type="number"
                            class="form-control form-field-1"
                            [(ngModel)]="child.red_range_value"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="0-10"
                          />
                        </div>
                        <div class="col-md-4">
                          <label class="amber-value">{{
                            staticText.color_names.amber
                          }}</label>
                          <input
                            type="number"
                            class="form-control form-field-2"
                            [(ngModel)]="child.amber_range_value"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="10-20"
                          />
                        </div>
                        <div class="col-md-4">
                          <label class="green-value">{{
                            staticText.color_names.green
                          }}</label>
                          <input
                            type="number"
                            class="form-control form-field-3"
                            [(ngModel)]="child.green_range_value"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="20-30"
                          />
                        </div>
                      </div>
                      <mat-form-field
                        appearance="outline"
                        class="mt-3 col-6 pr-0 pl-4"
                      >
                        <mat-label>Actual Value</mat-label>
                        <input
                          matInput
                          type="number"
                          status="basic"
                          placeholder="Actual Value"
                          autocomplete="off"
                          [(ngModel)]="child.actual_value"
                          [name]="'target' + 0 + '-' + subIndex"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Actual Completion Date</mat-label>
                        <input
                          matInput
                          [matDatepicker]="picker2"
                          [(ngModel)]="child.completion_date"
                          [ngModelOptions]="{ standalone: true }"
                          dateFormat="yyyy/MM/dd"
                          (click)="picker2.open()"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker2">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2> </mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Remarks</mat-label>
                        <textarea
                          type="text"
                          matInput
                          cdkTextareaAutosize
                          [(ngModel)]="child.remarks"
                          [ngModelOptions]="{ standalone: true }"
                          [name]="'notes' + treeindex + '-' + childIndex"
                          placeholder="remarks your remarks here.."
                          [disabled]="formInputDisable"
                        >
                        </textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="col mx-2 p-0 cancel-icon"
          *ngIf="removeicon && !disableEdit && !isRiskUpdate"
        >
          <div
            class="removeicon pl-2"
            (click)="AddRemoveColumn(child, child?.is_custom)"
          >
            <img
              class="removeimg cursor-pointer"
              src="/assets/images/pages/tree/treecross.svg"
              alt="close"
              width="auto"
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="accordion accordion-flush formParentAccordion"
      [id]="parentTagName + treeindex"
      *ngIf="!showChildOnly"
    >
      <div
        class="accordion-item formParentItem"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#formParent' + parentTagName + treeindex"
        aria-expanded="true"
        [attr.aria-controls]="'formParent' + treeindex"
      >
        <div class="accordion-header p-0 formParentHeader cursor-pointer pl-0">
          <div
            class="d-flex flex-column justify-content-around"
            (click)="formParentTree[treeindex] = !formParentTree[treeindex]"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="p-2" style="padding-left: 0px !important">
                <div class="flex-fill d-flex align-items-center">
                  <!-- <span class="small-bubble-left pt-2 pb-2"
                                        [ngStyle]="{'background-color': treeindex | getRandomColor}"></span> -->
                  <h5 class="formParentHead pl-2 mb-0 one-lines">
                    <span class="treeId pe-1 fw-bold">{{
                      treearray?.prefix
                    }}</span>
                    <span
                      container="body"
                      [ngbPopover]="
                        treearray?.category_display_name ||
                          treearray?.risk_domain_name | titlecase
                      "
                      popoverClass="darkTheme"
                      triggers="mouseenter:mouseleave"
                      >{{
                        treearray?.category_display_name ||
                          treearray?.risk_domain_name | titlecase
                      }}
                    </span>
                  </h5>
                </div>
              </div>
              <!-- <mat-icon class="color-info" *ngIf="isCentrePane" style="display: contents;" (click)="openMeasureofSuccessModal(treearray,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon> -->
              <div class="d-flex justify-content-center">
                <div class="formParentArrow p-2 d-flex align-items-center">
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="!formParentTree[treeindex]"
                  >
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="formParentTree[treeindex]"
                  >
                    keyboard_arrow_up
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [id]="'formParent' + parentTagName + treeindex"
        class="accordion-collapse"
        [ngClass]="treearray?.hasError == true ? 'show' : 'collapse'"
        [attr.aria-labelledby]="parentTagName + treeindex"
        [attr.data-bs-parent]="parentTagName + treeindex"
      >
        <div class="accordion-body formParentBody">
          <div
            class="subParentContainer"
            *ngFor="
              let subParent of treearray?.term_sub_category ||
                treearray?.risk_sub_domain;
              index as subParentindex
            "
          >
            <div class="d-flex subParentRow">
              <div
                class="pe-1 ps-1 flex-shrink-1 dotted"
                [ngClass]="{
                  dottedNoLength:
                    treearray?.term_sub_category?.length - 1 == subParentindex
                }"
              >
                <div
                  class="d-flex justify-content-center"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="
                    '#' +
                    subParentTagname +
                    '_' +
                    treeindex +
                    '_' +
                    subParentindex
                  "
                  aria-expanded="true"
                  [attr.aria-controls]="'parent' + subParentindex"
                  (click)="
                    formSubParentTree[subParentindex] =
                      !formSubParentTree[subParentindex]
                  "
                >
                  <div
                    class="formSubParentArrow d-flex align-items-center"
                    *ngIf="subParentTagname + subParentindex"
                  >
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="!formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_down
                    </mat-icon>
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_up
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="pe-1 ps-1 w-100">
                <div class="accordion accordion-flush subParentAccordion">
                  <div
                    class="accordion-item subParentItem cursor-pointer pb-2"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="
                      '#' +
                      subParentTagname +
                      '_' +
                      treeindex +
                      '_' +
                      subParentindex
                    "
                    aria-expanded="true"
                    [attr.aria-controls]="'parent' + subParentindex"
                    (click)="
                      formSubParentTree[subParentindex] =
                        !formSubParentTree[subParentindex]
                    "
                  >
                    <div class="accordion-header subParentHeader">
                      <div class="d-flex">
                        <span class="bubble-right pt-2 pb-2"></span>
                        <div class="p-2 w-100 two-lines">
                          <h5
                            class="subParentHead w-100"
                            container="body"
                            [ngbPopover]="subParent?.sub_category_display_name"
                            popoverClass="darkTheme"
                            triggers="mouseenter:mouseleave"
                          >
                            <span class="treeId pe-1 fw-bold">{{
                              subParent?.prefix
                            }}</span>
                            <span
                              class="w-80"
                              [ngbPopover]="
                                subParent?.risk_sub_domain_display_name
                                  | titlecase
                              "
                              popoverClass="darkTheme"
                              triggers="mouseenter:mouseleave"
                              container="body"
                            >
                              {{
                                subParent?.sub_category_display_name ||
                                  subParent?.risk_sub_domain_display_name
                                  | titlecase
                              }}</span
                            >
                            <!-- <div class="removeicon" *ngIf="isCentrePane">
                                                                <mat-icon class="color-info" (click)="openMeasureofSuccessModal(subParent,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon>
                                                             </div> -->
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    [id]="
                      subParentTagname + '_' + treeindex + '_' + subParentindex
                    "
                    class="accordion-collapse"
                    [ngClass]="
                      subParent?.hasError == true ? 'show' : 'collapse'
                    "
                    [attr.aria-labelledby]="parentTagName + treeindex"
                    [attr.data-bs-parent]="parentTagName + treeindex"
                  >
                    <div class="accordion-body subParentBody pt-1">
                      <div
                        class="child"
                        *ngFor="
                          let child of subParent?.term_measurement_criteria ||
                            subParent?.risk;
                          index as childIndex
                        "
                      >
                        <div class="d-flex childRow">
                          <div
                            class="pe-1 ps-1 flex-shrink-1 dotted"
                            [ngClass]="{
                              dottedNoLength:
                                subParent?.term_measurement_criteria?.length -
                                  1 ==
                                childIndex
                            }"
                          >
                            <div
                              class="formChildArrow d-flex align-items-center"
                            >
                              <span class="yellowcircle"></span>
                            </div>
                          </div>
                          <div class="pe-1 ps-1 w-100 pb-2">
                            <div class="d-flex flex-column border-form">
                              <div
                                class="childaccordion cursor-pointer"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                [attr.data-bs-target]="
                                  '#' +
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                [attr.aria-controls]="
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                (click)="
                                  measurmentForm[
                                    subParentindex + childIndex + treeindex
                                  ] =
                                    !measurmentForm[
                                      subParentindex + childIndex + treeindex
                                    ]
                                "
                              >
                                <div class="d-flex">
                                  <span class="bubble-right pt-2 pb-2"></span>
                                  <div class="w-100">
                                    <div
                                      class="d-flex flex-row justify-content-between align-items-center"
                                    >
                                      <div class="three-lines">
                                        <p
                                          class="recommended-badge"
                                          *ngIf="child?.is_recomended"
                                        >
                                          <i class="fa fa-star"></i>Recommended
                                        </p>
                                        <h5
                                          class="childhead"
                                          container="body"
                                          [ngbPopover]="
                                            child?.measurement_criteria_display_name
                                          "
                                          popoverClass="darkTheme"
                                          triggers="mouseenter:mouseleave"
                                        >
                                          <span class="treeId pe-1 fw-bold">{{
                                            child?.prefix
                                          }}</span>
                                          <span
                                            *ngIf="!child.tags"
                                            [ngbPopover]="
                                              child?.measurement_criteria_display_name ||
                                                child?.risk_display_name
                                                | titlecase
                                            "
                                            container="body"
                                            popoverClass="darkTheme"
                                            triggers="mouseenter:mouseleave"
                                            >{{
                                              child?.measurement_criteria_display_name ||
                                                child?.risk_display_name
                                                | titlecase
                                            }}</span
                                          >
                                          <span
                                            *ngIf="child.tags"
                                            [ngbPopover]="
                                              child?.measurement_criteria_display_name ||
                                                child?.risk_display_name
                                                | titlecase
                                            "
                                            container="body"
                                            popoverClass="darkTheme"
                                            triggers="mouseenter:mouseleave"
                                            [ngClass]="{
                                              severe:
                                                child?.tags?.tags?.indexOf(
                                                  projectWorkType?.name
                                                ) > -1
                                            }"
                                            >{{
                                              child?.measurement_criteria_display_name ||
                                                child?.risk_display_name
                                                | titlecase
                                            }}</span
                                          >
                                        </h5>
                                      </div>

                                      <div
                                        class="d-flex flex-column justify-content-center align-items-center actionIcons"
                                      >
                                        <div
                                          class="d-flex flex-row justify-content-around"
                                        >
                                          <div
                                            class="measurementFormArrow cursor-pointer p-2 d-flex align-items-center"
                                            *ngIf="formShow"
                                          >
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                !measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_down
                                            </mat-icon>
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_up
                                            </mat-icon>
                                          </div>
                                          <ng-container>
                                            <!--*ngxPermissionsOnly="[treeDeletePermission]"-->
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == true &&
                                                !disableEdit
                                              "
                                              (click)="DeleteKey_Toe(child)"
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-danger cursor-pointer"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == false
                                              "
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-muted cursor-pointer"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom  || auto"
                                                title="Custom Measurement Consumed Can't be Removed"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                          <ng-container>
                                            <!--*ngxPermissionsOnly="[treeAddPermission]"-->
                                            <div
                                              class="pb-2 addicon"
                                              id="add_{{ child?.prefix }}"
                                              *ngIf="addicon && !disableEdit"
                                              (click)="
                                                AddRemoveColumn(
                                                  child,
                                                  treearray?.is_custom
                                                )
                                              "
                                            >
                                              <mat-icon
                                                id="add_span_{{
                                                  child?.prefix
                                                }}"
                                                class="material-icons-outlined"
                                              >
                                                add
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ng-container *ngIf="formShow && !RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse"
                                  [ngClass]="
                                    child?.hasError == true
                                      ? 'show'
                                      : 'collapse'
                                  "
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label
                                            >Milestone Phase
                                          </mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            [(ngModel)]="child.milestone"
                                            [name]="
                                              'milestone' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your MileStone Phase here"
                                            [disabled]="formInputDisable"
                                          />
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Deadline</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [(ngModel)]="child.deadline"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'deadline' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                            [disabled]="formInputDisable"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col flex-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Notes</mat-label>
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [(ngModel)]="child.notes"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'notes' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            placeholder="Type your Notes here.."
                                            [disabled]="formInputDisable"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="formShow && RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse collapse"
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2 text-capitalize">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk Description
                                          </mat-label>
                                          <!--<input type="text" matInput
                                                                                        [(ngModel)]="child.risk_description"
                                                                                        [name]="'risk_description'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Description here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_description' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_description"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Description here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                        <!-- <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>Risk Owner</mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'riskowner'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.risk_owner"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let riskOwnerList of formArray"
                                                                                            [value]="riskOwnerList">
                                                                                            {{riskOwnerList.name ||
                                                                                            riskOwnerList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field> -->
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk mitigation plan
                                          </mat-label>
                                          <!-- <input type="text" matInput
                                                                                        [(ngModel)]="child.risk_mitigation"
                                                                                        [name]="'risk_mitigation'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Mitigation plan here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_mitigation' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_mitigation"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Mitigation plan here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Delegate</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Delegate"
                                            aria-label="Number"
                                            [name]="
                                              'delegate' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.delegate"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="Delegate"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #Delegate="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let delegateList of formArray?.deliveryOwnersList
                                              "
                                              [value]="delegateList"
                                            >
                                              {{
                                                delegateList.name ||
                                                  delegateList.mail
                                              }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Due Date</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [name]="
                                              'due' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.due_date"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Status</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Status"
                                            aria-label="Number"
                                            [name]="
                                              'status' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.status"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="status"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #status="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let status of formArray?.statusList
                                              "
                                              [value]="status"
                                            >
                                              {{ status.label }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div
                                        class="infoicon"
                                        *ngIf="isCentrePane"
                                      >
                                        <mat-icon
                                          class="color-info infoicon"
                                          (click)="
                                            openMeasureofSuccessModal(
                                              child,
                                              treearray?.is_custom,
                                              MeasureofSuccessModal,
                                              $event
                                            )
                                          "
                                        >
                                          info</mat-icon
                                        >
                                      </div>
                                      <!-- <div class="col flex-fill">
                                                                                <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>General Manager
                                                                                    </mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'manager'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.director"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let managarList of formArray"
                                                                                            [value]="managarList">
                                                                                            {{managarList.name ||
                                                                                            managarList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field>
                                                                            </div> -->
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <ng-container>
                            <!--*ngxPermissionsOnly="[treeRemovePermission]"-->
                            <div class="p-2" *ngIf="removeicon && !disableEdit">
                              <div
                                class="removeicon"
                                (click)="
                                  AddRemoveColumn(child, treearray?.is_custom)
                                "
                              >
                                <img
                                  class="removeimg cursor-pointer"
                                  src="/assets/images/pages/tree/treecross.svg"
                                  alt="close"
                                  width="auto"
                                  height="auto"
                                />
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section
  id="formtreelistnew"
  [class]="treeclass"
  *ngIf="treecardtype == 'formtreenew'"
>
  <div class="formParent">
    <div class="accordion" id="accordionExample" *ngIf="showChildOnly">
      <div
        class="d-flex justify-content-between align-items-center ml-3"
        *ngFor="let child of treearray; let subindex = index"
        [ngClass]="{ hide: isRiskUpdate && !child?.id }"
      >
        <div class="col-md-11 accordion-item my-2 p-0">
          <h2 class="accordion-header d-flex" id="headingOne{{ subindex }}">
            <span class="bubble-right pt-2 pb-2"></span>
            <button
              class="accordion-button p-2 pl-3 collapsed"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapseOne' + subindex"
              aria-expanded="true"
              aria-controls="collapseOne{{ subindex }}"
              [ngClass]="{ 'p-3': isRiskUpdate }"
            >
              <div class="col-md-11 p-0 d-flex justify-content-between">
                <div>
                  <span class="treeId pe-1 fw-bold">{{ child?.prefix }}</span>
                  <span
                    [ngbPopover]="
                      child?.measurement_criteria_display_name ||
                        child?.risk_display_name | titlecase
                    "
                    container="body"
                    popoverClass="darkTheme"
                    triggers="mouseenter:mouseleave"
                    >{{
                      child?.measurement_criteria_display_name ||
                        child?.risk_display_name | titlecase
                    }}
                  </span>
                </div>
                <div class="infoicon ml-2" *ngIf="isRiskUpdate">
                  <mat-icon
                    class="color-info infoicon ml-2"
                    (click)="openRiskDetails(child, RiskDetailsModal)"
                  >
                    info</mat-icon
                  >
                  <ng-container>
                    <img
                      class="ownericon ml-2"
                      container="body"
                      [ngbPopover]="childMappingContent"
                      popoverClass="darkTheme"
                      triggers="mouseenter:mouseleave"
                      src="/assets/images/pages/tree/Owner_icon_blue.svg"
                      alt="close"
                      width="auto"
                      height="auto"
                    />
                    <ng-template #childMappingContent>
                      <span
                        *ngIf="!child?.risk_owner?.full_name; else showOwners"
                      >
                        <p>Owners not found</p>
                      </span>
                      <ng-template #showOwners>
                        <div class="d-flex flex-column">
                          <div class="flex-fill">
                            <span>{{ child?.risk_owner?.full_name }}</span>
                            <span
                              *ngIf="
                                !child?.risk_owner?.full_name &&
                                child?.risk_owner?.username
                              "
                              >{{ child?.risk_owner?.username }}</span
                            >
                          </div>
                        </div>
                        <!--<div class="d-flex flex-column"
                                                *ngFor="let support of child?.support_owners;index as SupportIndex;let last=last">
                                                <div class="flex-fill">
                                                    <span
                                                        *ngIf="support.full_name">
                                                        &nbsp;</span>
                                                    <span>{{support.full_name}}</span>
                                                    <span
                                                        *ngIf="!support.full_name && support.username">{{support.username}}</span>
                                                    <span *ngIf="!last">
                                                        &comma;
                                                    </span>
                                                </div>
                                            </div> -->
                      </ng-template>
                    </ng-template>
                    <ng-template #childMappingContent>
                      <span
                        *ngIf="
                          risk?.primary_owners.length == 0 &&
                            risk?.support_owners.length == 0;
                          else showOwners
                        "
                      >
                        <p>Owners not found</p>
                      </span>
                      <ng-template #showOwners>
                        <div
                          class="d-flex flex-column"
                          *ngFor="
                            let primary of risk?.primary_owners;
                            index as PrimaryIndex;
                            let last = last
                          "
                        >
                          <div class="flex-fill">
                            <span>{{ primary.full_name }} </span>
                            <span
                              *ngIf="!primary.full_name && primary.username"
                              >{{ primary.username }}</span
                            >
                            <span
                              *ngIf="
                                !last ||
                                (risk?.support_owners &&
                                  risk?.support_owners?.length > 0)
                              "
                            >
                              &comma;</span
                            >
                          </div>
                        </div>
                        <div
                          class="d-flex flex-column"
                          *ngFor="
                            let support of risk?.support_owners;
                            index as SupportIndex;
                            let last = last
                          "
                        >
                          <div class="flex-fill">
                            <span *ngIf="support.full_name"> &nbsp;</span>
                            <span>{{ support.full_name }} </span>
                            <span
                              *ngIf="!support.full_name && support.username"
                              >{{ support.username }}</span
                            >
                            <span *ngIf="!last"> &comma;</span>
                          </div>
                        </div>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="collapseOne{{ subindex }}"
            class="accordion-collapse"
            [ngClass]="child.hasError ? 'show' : 'collapse'"
            aria-labelledby="headingOne{{ subindex }}"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body p-0">
              <div
                class="treeForm RiskLogform p-2"
                *ngIf="formShow && RiskLogform"
              >
                <div
                  class="row row-cols-8 text-capitalize"
                  *ngIf="!isRiskUpdate"
                  [formGroup]="riskSetupFormArray"
                >
                  <div formArrayName="riskSetupList">
                    <div [formGroup]="riskSetupList.controls[subindex]">
                      <ng-container *ngIf="!child.id">
                        <div class="col felx-fill">
                          <mat-form-field
                            id="riskimpact{{ child?.prefix }}"
                            appearance="outline"
                            class="example-full-width"
                          >
                            <mat-label id="riskimpactlable{{ child?.prefix }}"
                              >Risk Impact</mat-label
                            >
                            <mat-select
                              id="riskimpactselect{{ child?.prefix }}"
                              formControlName="impact"
                              placeholder="Risk Impact"
                              required="true"
                            >
                              <mat-option
                                id="riskimpactoption{{ child?.prefix }}{{
                                  iitem?.display_name
                                }}"
                                *ngFor="
                                  let iitem of formArray?.riskImpactList;
                                  let itemindex = index
                                "
                                [value]="iitem.id"
                                >{{ iitem?.display_name }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col felx-fill">
                          <mat-form-field
                            id="risklikelyhood{{ child?.prefix }}"
                            appearance="outline"
                            class="example-full-width"
                          >
                            <mat-label
                              id="risklikelyhoodlable{{ child?.prefix }}"
                              >Risk Likelyhood</mat-label
                            >
                            <mat-select
                              id="risklikelyhoodselect{{ child?.prefix }}"
                              formControlName="likelihood"
                              placeholder="Risk Impact"
                              required="true"
                            >
                              <mat-option
                                id="risklikelyhoodoption{{ child?.prefix }}{{
                                  item?.display_name
                                }}"
                                *ngFor="
                                  let iitem of formArray?.likelyHoodOptions;
                                  let itemindex = index
                                "
                                [value]="iitem.id"
                                >{{ iitem?.display_name }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="child.id">
                        <div class="col felx-fill">
                          <mat-form-field
                            id="riskimpact{{ child?.prefix }}"
                            appearance="outline"
                            class="example-full-width"
                          >
                            <mat-label id="riskimpactlable{{ child?.prefix }}"
                              >Risk Impact</mat-label
                            >
                            <mat-select
                              id="riskimpactselect{{ child?.prefix }}"
                              formControlName="impact"
                              placeholder="Risk Impact"
                              required="true"
                            >
                              <mat-option
                                id="riskimpactoption{{ child?.prefix }}{{
                                  item?.display_name
                                }}"
                                *ngFor="
                                  let iitem of formArray?.riskImpactList;
                                  let itemindex = index
                                "
                                [value]="iitem.id"
                                >{{ iitem?.display_name }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col felx-fill">
                          <mat-form-field
                            id="risklikelyhood{{ child?.prefix }}"
                            appearance="outline"
                            class="example-full-width"
                          >
                            <mat-label
                              id="risklikelyhoodlable{{ child?.prefix }}"
                              >Risk Likelyhood</mat-label
                            >
                            <mat-select
                              id="risklikelyhoodselect{{ child?.prefix }}"
                              formControlName="likelihood"
                              placeholder="Risk Impact"
                              required="true"
                            >
                              <mat-option
                                id="risklikelyhoodoption{{ child?.prefix }}{{
                                  item?.display_name
                                }}"
                                *ngFor="
                                  let iitem of formArray?.likelyHoodOptions;
                                  let itemindex = index
                                "
                                [value]="iitem.id"
                                >{{ iitem?.display_name }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </ng-container>
                      <div class="col felx-fill">
                        <mat-form-field appearance="outline">
                          <mat-label id="duelable{{ child?.prefix }}"
                            >Due Date</mat-label
                          >
                          <input
                            matInput
                            [matDatepicker]="picker"
                            formControlName="duedate"
                            id="due{{ child?.prefix }}"
                            [ngModelOptions]="{ standalone: true }"
                            dateFormat="yyyy/MM/dd"
                            (click)="picker.open()"
                            required="true"
                          />
                          <mat-datepicker-toggle
                            id="duetoggle{{ child?.prefix }}"
                            matSuffix
                            [for]="picker"
                          >
                          </mat-datepicker-toggle>
                          <mat-datepicker
                            id="duepicker{{ child?.prefix }}"
                            #picker
                          >
                          </mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col felx-fill">
                        <mat-form-field
                          id="ownerfield{{ child?.prefix }}"
                          class="example-full-width"
                          appearance="outline"
                        >
                          <mat-label id="ownerlable{{ child?.prefix }}"
                            >Risk Owner</mat-label
                          >
                          <input
                            id="ownerinput{{ child?.prefix }}"
                            type="text"
                            matInput
                            placeholder="Select"
                            formControlName="owner"
                            aria-label="Number"
                            class="p-0"
                            [name]="'delegate' + treeindex + '-' + subindex"
                            [ngModelOptions]="{ standalone: true }"
                            [matAutocomplete]="Delegate"
                            required="true"
                          />
                          <mat-autocomplete
                            id="ownerautocomplete{{ child?.prefix }}"
                            [displayWith]="displayFn"
                            #Delegate="matAutocomplete"
                          >
                            <mat-option
                              id="ownerautocompleteex{{ child?.prefix }}{{
                                delegateList.name || delegateList.mail
                              }}"
                              *ngFor="
                                let delegateList of formArray?.deliveryOwnersList;
                                let itemindex = index
                              "
                              [value]="delegateList"
                            >
                              {{ delegateList.name || delegateList.mail }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <!-- <div class="col felx-fill">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Status</mat-label>
                                                    <input type="text" matInput placeholder="Status" formControlName="status" aria-label="Number" class="px-0" [name]="'status'+treeindex+'-'+subindex" [value]="child.status?.label ||'Open'" required="true" disabled>
                                                </mat-form-field>
                                            </div> -->
                      <div class="col felx-fill">
                        <mat-form-field
                          id="riskphasefield{{ child?.prefix }}"
                          appearance="outline"
                        >
                          <mat-label id="riskphaselable{{ child?.prefix }}">
                            Risk Phase
                          </mat-label>
                          <input
                            id="riskphaseinput{{ child?.prefix }}"
                            type="text"
                            matInput
                            formControlName="phase"
                            [name]="'risk_phase' + treeindex + '-' + subindex"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Risk Phase"
                            required="true"
                          />
                        </mat-form-field>
                      </div>
                      <div class="col felx-fill">
                        <mat-form-field
                          appearance="outline"
                          id="impact_descfield{{ child?.prefix }}"
                        >
                          <mat-label id="impact_desclable{{ child?.prefix }}">
                            Impact Description
                          </mat-label>
                          <textarea
                            id="impact_desctextarea{{ child?.prefix }}"
                            type="text"
                            matInput
                            cdkTextareaAutosize
                            formControlName="description"
                            [name]="
                              'impact_description' + treeindex + '-' + subindex
                            "
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Type your Impact Description here"
                            required="true"
                          >
                          </textarea>
                        </mat-form-field>
                      </div>
                      <div class="col felx-fill">
                        <mat-form-field
                          id="risk_response_field{{ child?.prefix }}"
                          appearance="outline"
                          class="example-full-width"
                        >
                          <mat-label id="risk_response_lable{{ child?.prefix }}"
                            >Risk Response</mat-label
                          >
                          <mat-select
                            id="risk_response_select{{ child?.prefix }}"
                            formControlName="response"
                            placeholder="Risk Response"
                            required="true"
                          >
                            <mat-option
                              id="risk_response_option{{ child?.prefix }}{{
                                item?.display_name
                              }}"
                              *ngFor="
                                let iitem of riskResponse;
                                let itemindex = index
                              "
                              [value]="iitem.display_name"
                              >{{ iitem?.display_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div
                        class="col felx-fill"
                        *ngIf="
                          riskSetupList.controls[subindex].get('response')
                            .value &&
                          riskSetupList.controls[subindex].get('response')
                            .value == 'Transfer'
                        "
                      >
                        <mat-form-field
                          id="risk_transfer_field{{ child?.prefix }}"
                          class="example-full-width"
                          appearance="outline"
                        >
                          <mat-label id="risk_transfer_lable{{ child?.prefix }}"
                            >Risk Transfer</mat-label
                          >
                          <input
                            type="text"
                            id="risk_transfer_input{{ child?.prefix }}"
                            matInput
                            placeholder="Risk Transfer"
                            formControlName="transferTo"
                            aria-label="Number"
                            class="p-0"
                            [name]="'delegate' + treeindex + '-' + subindex"
                            [ngModelOptions]="{ standalone: true }"
                            [matAutocomplete]="Delegate"
                          />
                          <mat-autocomplete
                            id="risk_transfer_auto_complete{{ child?.prefix }}"
                            [displayWith]="displayFn"
                            #Delegate="matAutocomplete"
                          >
                            <mat-option
                              id="risk_transfer_options{{ child?.prefix }}{{
                                delegateList.name || delegateList.mail
                              }}"
                              *ngFor="
                                let delegateList of formArray?.deliveryOwnersList;
                                let itemindex = index
                              "
                              [value]="delegateList"
                            >
                              {{ delegateList.name || delegateList.mail }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>

                      <div
                        class="col felx-fill d-flex"
                        *ngIf="
                          riskSetupList.controls[subindex].get('response')
                            .value &&
                          riskSetupList.controls[subindex].get('response')
                            .value == 'Mitigate'
                        "
                      >
                        <mat-form-field
                          id="risk_mitigation_field{{ child?.prefix }}"
                          appearance="outline"
                        >
                          <mat-label
                            id="risk_mitigation_lable{{ child?.prefix }}"
                          >
                            Mitigation Plan
                          </mat-label>
                          <input
                            id="risk_mitigation_input{{ child?.prefix }}"
                            type="text"
                            matInput
                            formControlName="mitigation"
                            [name]="
                              'risk_mitigation' + treeindex + '-' + subindex
                            "
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Type your Risk Mitigation plan here"
                          />
                        </mat-form-field>
                        <div class="infoicon ml-2" *ngIf="isCentrePane">
                          <mat-icon
                            id="risk_mitigation_info{{ child?.prefix }}"
                            class="color-info infoicon"
                            (click)="
                              openMitigationModal(
                                child,
                                treearray?.is_custom,
                                RiskMitigationModal,
                                $event,
                                subindex
                              )
                            "
                          >
                            info</mat-icon
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row row-cols-2 text-capitalize"
                  *ngIf="isRiskUpdate"
                >
                  <div class="col-3 felx-fill">
                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>Status</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="Select"
                        aria-label="Number"
                        class="px-0"
                        [name]="'update_status' + treeindex + '-' + subindex"
                        [(ngModel)]="child.update_status"
                        [ngModelOptions]="{ standalone: true }"
                        [matAutocomplete]="update_status"
                      />
                      <mat-autocomplete
                        [displayWith]="displayFn"
                        #update_status="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="let status of formArray?.statusList"
                          [value]="status"
                        >
                          {{ status.label }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div
                    class="col-3 felx-fill"
                    *ngIf="child.update_status?.slug == 'project_risk_transfer'"
                  >
                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>New Owner</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="Select"
                        aria-label="Number"
                        class="p-0"
                        [name]="'new_owner' + treeindex + '-' + subindex"
                        [(ngModel)]="child.new_owner"
                        [ngModelOptions]="{ standalone: true }"
                        [matAutocomplete]="NewOwner"
                      />
                      <mat-autocomplete
                        [displayWith]="displayFn"
                        #NewOwner="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="
                            let delegateList of formArray?.deliveryOwnersList
                          "
                          [value]="delegateList"
                        >
                          {{ delegateList.name || delegateList.mail }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col-4 pl-0 felx-fill d-flex align-items-center">
                    <mat-form-field appearance="outline">
                      <mat-label>Actual Close/End Date</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        [name]="'end_date' + treeindex + '-' + subindex"
                        [(ngModel)]="child.end_date"
                        [ngModelOptions]="{ standalone: true }"
                        dateFormat="yyyy/MM/dd"
                        (click)="picker.open()"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker> </mat-datepicker>
                    </mat-form-field>
                    <div class="ml-3">
                      <img
                        class="statistics-img cursor-pointer"
                        (click)="openRiskHistory(child, RiskHistoryModal)"
                        src="./assets/images/graphics.svg"
                      />
                    </div>
                  </div>
                  <div class="col-8 felx-fill">
                    <mat-form-field appearance="outline">
                      <mat-label> Remarks </mat-label>
                      <textarea
                        type="text"
                        matInput
                        cdkTextareaAutosize
                        [(ngModel)]="child.remarks"
                        [name]="'remarks' + treeindex + '-' + subindex"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Type your remarks here"
                      >
                      </textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-2 d-flex align-items-center">
                    <button
                      class="saveRisk ml-0 text-white text-capitalize"
                      mat-raised-button
                      [disabled]="
                        !child.update_status ||
                        !child.end_date ||
                        !child.remarks
                      "
                      (click)="onClickSaveRisk(child)"
                      *ngxPermissionsOnly="['delivery.change_projectmapping']"
                    >
                      {{ staticText.buttons.save_btn }}
                    </button>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="formShow && !RiskLogform">
                <div
                  class="formcontainer accordion-collapse"
                  [id]="
                    formTagName +
                    subParentindex +
                    '_' +
                    childIndex +
                    '_' +
                    treeindex
                  "
                  [attr.aria-labelledby]="subParentTagname + subParentindex"
                  [attr.data-bs-parent]="subParentTagname + subParentindex"
                >
                  <div class="treeForm p-2">
                    <div class="row text-capitalize">
                      <!-- <div class="col felx-fill"> -->
                      <mat-form-field
                        appearance="outline"
                        class="w-45 mlp-5 col-4"
                      >
                        <mat-label>Unit</mat-label>
                        <mat-select
                          placeholder="Unit"
                          [(ngModel)]="child.unit"
                          [name]="'unit' + 0 + '-' + subIndex"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-option
                            *ngFor="let opt of unitOptions"
                            [value]="opt?.id"
                          >
                            {{ opt?.display_name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-4 pl-0">
                        <mat-label>Frequency</mat-label>
                        <mat-select
                          placeholder="Frequency"
                          [(ngModel)]="child.frequency"
                          [name]="'frequency' + 0 + '-' + subIndex"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-option
                            *ngFor="let opt of frequencyOptions"
                            [value]="opt?.id"
                          >
                            {{ opt?.display_name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-4 pl-0">
                        <mat-label>Expected End Date</mat-label>
                        <input
                          matInput
                          [matDatepicker]="picker1"
                          [(ngModel)]="child.end_date"
                          [ngModelOptions]="{ standalone: true }"
                          dateFormat="yyyy/MM/dd"
                          (click)="picker1.open()"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker1">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1> </mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Milestone Phase </mat-label>
                        <input
                          type="text"
                          matInput
                          [(ngModel)]="child.milestone"
                          [name]="'milestone' + treeindex + '-' + childIndex"
                          (ngModelChange)="setMeasurementCriteriaId(child)"
                          [ngModelOptions]="{ standalone: true }"
                          placeholder="Type your MileStone Phase here"
                          [disabled]="formInputDisable"
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Target</mat-label>
                        <input
                          matInput
                          type="number"
                          status="basic"
                          placeholder="Target"
                          autocomplete="off"
                          [(ngModel)]="child.target"
                          [name]="'target' + 0 + '-' + subIndex"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>
                      <div class="form-row pr-2 pb-2 mb-2 col-6">
                        <div class="col-md-4">
                          <label class="red-value">{{
                            staticText.color_names.red
                          }}</label>
                          <input
                            type="number"
                            class="form-control form-field-1"
                            [(ngModel)]="child.red_range_value"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="0-10"
                          />
                        </div>
                        <div class="col-md-4">
                          <label class="amber-value">{{
                            staticText.color_names.amber
                          }}</label>
                          <input
                            type="number"
                            class="form-control form-field-2"
                            [(ngModel)]="child.amber_range_value"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="10-20"
                          />
                        </div>
                        <div class="col-md-4">
                          <label class="green-value">{{
                            staticText.color_names.green
                          }}</label>
                          <input
                            type="number"
                            class="form-control form-field-3"
                            [(ngModel)]="child.green_range_value"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="20-30"
                          />
                        </div>
                      </div>
                      <mat-form-field
                        appearance="outline"
                        class="mt-3 col-6 pr-0 pl-4"
                      >
                        <mat-label>Actual Value</mat-label>
                        <input
                          matInput
                          type="number"
                          status="basic"
                          placeholder="Actual Value"
                          autocomplete="off"
                          [(ngModel)]="child.actual_value"
                          [name]="'target' + 0 + '-' + subIndex"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Actual Completion Date</mat-label>
                        <input
                          matInput
                          [matDatepicker]="picker2"
                          [(ngModel)]="child.completion_date"
                          [ngModelOptions]="{ standalone: true }"
                          dateFormat="yyyy/MM/dd"
                          (click)="picker2.open()"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker2">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2> </mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Remarks</mat-label>
                        <textarea
                          type="text"
                          matInput
                          cdkTextareaAutosize
                          [(ngModel)]="child.remarks"
                          [ngModelOptions]="{ standalone: true }"
                          [name]="'notes' + treeindex + '-' + childIndex"
                          placeholder="remarks your remarks here.."
                          [disabled]="formInputDisable"
                        >
                        </textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </ng-container>
              <mat-card-actions class="p-2 m-2" align="end">
                <button
                  class="saveRisk text-white text-capitalize float-right"
                  (click)="saveRiskProperties(subindex)"
                  mat-raised-button
                >
                  Save
                </button>
              </mat-card-actions>
            </div>
          </div>
        </div>
        <div
          class="col mx-2 p-0 cancel-icon"
          *ngIf="removeicon && !disableEdit && !isRiskUpdate"
        >
          <div
            class="removeicon"
            (click)="AddRemoveColumn(child, child?.is_custom)"
          >
            <img
              class="removeimg cursor-pointer"
              src="/assets/images/pages/tree/treecross.svg"
              alt="close"
              width="auto"
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="accordion accordion-flush formParentAccordion"
      [id]="parentTagName + treeindex"
      *ngIf="!showChildOnly"
    >
      <div
        class="accordion-item formParentItem"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#formParent' + parentTagName + treeindex"
        aria-expanded="true"
        [attr.aria-controls]="'formParent' + treeindex"
      >
        <div class="accordion-header p-0 formParentHeader cursor-pointer pl-0">
          <div
            class="d-flex flex-column justify-content-around"
            (click)="formParentTree[treeindex] = !formParentTree[treeindex]"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="p-2" style="padding-left: 0px !important">
                <div class="flex-fill d-flex align-items-center">
                  <!-- <span class="small-bubble-left pt-2 pb-2"
                                        [ngStyle]="{'background-color': treeindex | getRandomColor}"></span> -->
                  <h5 class="formParentHead pl-2 mb-0 one-lines">
                    <span class="treeId pe-1 fw-bold">{{
                      treearray?.prefix
                    }}</span>
                    <span
                      container="body"
                      [ngbPopover]="
                        treearray?.category_display_name ||
                          treearray?.risk_domain_name | titlecase
                      "
                      popoverClass="darkTheme"
                      triggers="mouseenter:mouseleave"
                      >{{
                        treearray?.category_display_name ||
                          treearray?.risk_domain_name | titlecase
                      }}
                    </span>
                  </h5>
                </div>
              </div>
              <!-- <mat-icon class="color-info" *ngIf="isCentrePane" style="display: contents;" (click)="openMeasureofSuccessModal(treearray,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon> -->
              <div class="d-flex justify-content-center">
                <div class="formParentArrow p-2 d-flex align-items-center">
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="!formParentTree[treeindex]"
                  >
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="formParentTree[treeindex]"
                  >
                    keyboard_arrow_up
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [id]="'formParent' + parentTagName + treeindex"
        class="accordion-collapse"
        [ngClass]="treearray?.hasError == true ? 'show' : 'collapse'"
        [attr.aria-labelledby]="parentTagName + treeindex"
        [attr.data-bs-parent]="parentTagName + treeindex"
      >
        <div class="accordion-body formParentBody">
          <div
            class="subParentContainer"
            *ngFor="
              let subParent of treearray?.term_sub_category ||
                treearray?.risk_sub_domain;
              index as subParentindex
            "
          >
            <div class="d-flex subParentRow">
              <div
                class="pe-1 ps-1 flex-shrink-1 dotted"
                [ngClass]="{
                  dottedNoLength:
                    treearray?.term_sub_category?.length - 1 == subParentindex
                }"
              >
                <div
                  class="d-flex justify-content-center"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="
                    '#' +
                    subParentTagname +
                    '_' +
                    treeindex +
                    '_' +
                    subParentindex
                  "
                  aria-expanded="true"
                  [attr.aria-controls]="'parent' + subParentindex"
                  (click)="
                    formSubParentTree[subParentindex] =
                      !formSubParentTree[subParentindex]
                  "
                >
                  <div
                    class="formSubParentArrow d-flex align-items-center"
                    *ngIf="subParentTagname + subParentindex"
                  >
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="!formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_down
                    </mat-icon>
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_up
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="pe-1 ps-1 w-100">
                <div class="accordion accordion-flush subParentAccordion">
                  <div
                    class="accordion-item subParentItem cursor-pointer pb-2"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="
                      '#' +
                      subParentTagname +
                      '_' +
                      treeindex +
                      '_' +
                      subParentindex
                    "
                    aria-expanded="true"
                    [attr.aria-controls]="'parent' + subParentindex"
                    (click)="
                      formSubParentTree[subParentindex] =
                        !formSubParentTree[subParentindex]
                    "
                  >
                    <div class="accordion-header subParentHeader">
                      <div class="d-flex">
                        <span class="bubble-right pt-2 pb-2"></span>
                        <div class="p-2 w-100 two-lines">
                          <h5
                            class="subParentHead w-100"
                            container="body"
                            [ngbPopover]="subParent?.sub_category_display_name"
                            popoverClass="darkTheme"
                            triggers="mouseenter:mouseleave"
                          >
                            <span class="treeId pe-1 fw-bold">{{
                              subParent?.prefix
                            }}</span>
                            <span
                              class="w-80"
                              [ngbPopover]="
                                subParent?.risk_sub_domain_display_name
                                  | titlecase
                              "
                              popoverClass="darkTheme"
                              triggers="mouseenter:mouseleave"
                              container="body"
                            >
                              {{
                                subParent?.sub_category_display_name ||
                                  subParent?.risk_sub_domain_display_name
                                  | titlecase
                              }}</span
                            >
                            <!-- <div class="removeicon" *ngIf="isCentrePane">
                                                                <mat-icon class="color-info" (click)="openMeasureofSuccessModal(subParent,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon>
                                                             </div> -->
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    [id]="
                      subParentTagname + '_' + treeindex + '_' + subParentindex
                    "
                    class="accordion-collapse"
                    [ngClass]="
                      subParent?.hasError == true ? 'show' : 'collapse'
                    "
                    [attr.aria-labelledby]="parentTagName + treeindex"
                    [attr.data-bs-parent]="parentTagName + treeindex"
                  >
                    <div class="accordion-body subParentBody pt-1">
                      <div
                        class="child"
                        *ngFor="
                          let child of subParent?.term_measurement_criteria ||
                            subParent?.risk;
                          index as childIndex
                        "
                      >
                        <div class="d-flex childRow">
                          <div
                            class="pe-1 ps-1 flex-shrink-1 dotted"
                            [ngClass]="{
                              dottedNoLength:
                                subParent?.term_measurement_criteria?.length -
                                  1 ==
                                childIndex
                            }"
                          >
                            <div
                              class="formChildArrow d-flex align-items-center"
                            >
                              <span class="yellowcircle"></span>
                            </div>
                          </div>
                          <div class="pe-1 ps-1 w-100 pb-2">
                            <div class="d-flex flex-column border-form">
                              <div
                                class="childaccordion cursor-pointer"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                [attr.data-bs-target]="
                                  '#' +
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                [attr.aria-controls]="
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                (click)="
                                  measurmentForm[
                                    subParentindex + childIndex + treeindex
                                  ] =
                                    !measurmentForm[
                                      subParentindex + childIndex + treeindex
                                    ]
                                "
                              >
                                <div class="d-flex">
                                  <span class="bubble-right pt-2 pb-2"></span>
                                  <div class="w-100">
                                    <div
                                      class="d-flex flex-row justify-content-between align-items-center"
                                    >
                                      <div class="three-lines">
                                        <h5
                                          class="childhead"
                                          container="body"
                                          [ngbPopover]="
                                            child?.measurement_criteria_display_name
                                          "
                                          popoverClass="darkTheme"
                                          triggers="mouseenter:mouseleave"
                                        >
                                          <span class="treeId pe-1 fw-bold">{{
                                            child?.prefix
                                          }}</span>
                                          <span
                                            [ngbPopover]="
                                              child?.measurement_criteria_display_name ||
                                                child?.risk_display_name
                                                | titlecase
                                            "
                                            container="body"
                                            popoverClass="darkTheme"
                                            triggers="mouseenter:mouseleave"
                                            >{{
                                              child?.measurement_criteria_display_name ||
                                                child?.risk_display_name
                                                | titlecase
                                            }}</span
                                          >
                                        </h5>
                                      </div>

                                      <div
                                        class="d-flex flex-column justify-content-center align-items-center actionIcons"
                                      >
                                        <div
                                          class="d-flex flex-row justify-content-around"
                                        >
                                          <div
                                            class="measurementFormArrow cursor-pointer p-2 d-flex align-items-center"
                                            *ngIf="formShow"
                                          >
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                !measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_down
                                            </mat-icon>
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_up
                                            </mat-icon>
                                          </div>
                                          <ng-container>
                                            <!--*ngxPermissionsOnly="[treeDeletePermission]"-->
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == true &&
                                                !disableEdit
                                              "
                                              (click)="DeleteKey_Toe(child)"
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-danger cursor-pointer"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == false
                                              "
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-muted cursor-pointer"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom  || auto"
                                                title="Custom Measurement Consumed Can't be Removed"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                          <ng-container>
                                            <!--*ngxPermissionsOnly="[treeAddPermission]"-->
                                            <div
                                              class="pb-2 addicon"
                                              *ngIf="addicon && !disableEdit"
                                              (click)="
                                                AddRemoveColumn(
                                                  child,
                                                  treearray?.is_custom
                                                )
                                              "
                                            >
                                              <mat-icon
                                                class="material-icons-outlined"
                                              >
                                                add
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ng-container *ngIf="formShow && !RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse"
                                  [ngClass]="
                                    child?.hasError == true
                                      ? 'show'
                                      : 'collapse'
                                  "
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label
                                            >Milestone Phase
                                          </mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            [(ngModel)]="child.milestone"
                                            [name]="
                                              'milestone' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your MileStone Phase here"
                                            [disabled]="formInputDisable"
                                          />
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Deadline</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [(ngModel)]="child.deadline"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'deadline' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                            [disabled]="formInputDisable"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col flex-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Notes</mat-label>
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [(ngModel)]="child.notes"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'notes' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            placeholder="Type your Notes here.."
                                            [disabled]="formInputDisable"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="formShow && RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse collapse"
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2 text-capitalize">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk Description
                                          </mat-label>
                                          <!--<input type="text" matInput
                                                                                        [(ngModel)]="child.risk_description"
                                                                                        [name]="'risk_description'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Description here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_description' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_description"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Description here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                        <!-- <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>Risk Owner</mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'riskowner'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.risk_owner"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let riskOwnerList of formArray"
                                                                                            [value]="riskOwnerList">
                                                                                            {{riskOwnerList.name ||
                                                                                            riskOwnerList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field> -->
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk mitigation plan
                                          </mat-label>
                                          <!-- <input type="text" matInput
                                                                                        [(ngModel)]="child.risk_mitigation"
                                                                                        [name]="'risk_mitigation'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Mitigation plan here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_mitigation' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_mitigation"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Mitigation plan here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Delegate</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Delegate"
                                            aria-label="Number"
                                            [name]="
                                              'delegate' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.delegate"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="Delegate"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #Delegate="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let delegateList of formArray?.deliveryOwnersList
                                              "
                                              [value]="delegateList"
                                            >
                                              {{
                                                delegateList.name ||
                                                  delegateList.mail
                                              }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Due Date</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [name]="
                                              'due' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.due_date"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Status</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Status"
                                            aria-label="Number"
                                            [name]="
                                              'status' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.status"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="status"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #status="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let status of formArray?.statusList
                                              "
                                              [value]="status"
                                            >
                                              {{ status.label }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div
                                        class="infoicon"
                                        *ngIf="isCentrePane"
                                      >
                                        <mat-icon
                                          class="color-info infoicon"
                                          (click)="
                                            openMeasureofSuccessModal(
                                              child,
                                              treearray?.is_custom,
                                              MeasureofSuccessModal,
                                              $event
                                            )
                                          "
                                        >
                                          info</mat-icon
                                        >
                                      </div>
                                      <!-- <div class="col flex-fill">
                                                                                <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>General Manager
                                                                                    </mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'manager'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.director"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let managarList of formArray"
                                                                                            [value]="managarList">
                                                                                            {{managarList.name ||
                                                                                            managarList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field>
                                                                            </div> -->
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <ng-container>
                            <!--*ngxPermissionsOnly="[treeRemovePermission]"-->
                            <div class="p-2" *ngIf="removeicon && !disableEdit">
                              <div
                                class="removeicon"
                                (click)="
                                  AddRemoveColumn(child, treearray?.is_custom)
                                "
                              >
                                <img
                                  class="removeimg cursor-pointer"
                                  src="/assets/images/pages/tree/treecross.svg"
                                  alt="close"
                                  width="auto"
                                  height="auto"
                                />
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  id="formtreelist"
  [class]="treeclass"
  *ngIf="treecardtype == 'formtreeokr'"
>
  <div class="formParent" *ngIf="alreadyLinked(treearray)">
    <div
      class="accordion accordion-flush formParentAccordion"
      [id]="parentTagName + treeindex"
      *ngIf="!showChildOnly"
    >
      <div
        class="accordion-item formParentItem"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#formParent' + parentTagName + treeindex"
        aria-expanded="true"
        [attr.aria-controls]="'formParent' + treeindex"
      >
        <div class="accordion-header p-0 formParentHeader cursor-pointer pl-0">
          <div
            class="d-flex flex-column justify-content-around"
            (click)="formParentTree[treeindex] = !formParentTree[treeindex]"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="p-2" style="padding-left: 0px !important">
                <div class="flex-fill d-flex align-items-center">
                  <!-- <span class="small-bubble-left pt-2 pb-2"
                                        [ngStyle]="{'background-color': treeindex | getRandomColor}"></span> -->
                  <h5 class="formParentHead pl-2 mb-0 one-lines">
                    <span class="treeId pe-1 fw-bold">{{ treeindex + 1 }}</span>
                    <span
                      container="body"
                      [ngbPopover]="
                        treearray?.key_result_title || treearray?.key_title
                          | titlecase
                      "
                      popoverClass="darkTheme"
                      triggers="mouseenter:mouseleave"
                      >{{
                        treearray?.key_result_title || treearray?.key_title
                          | titlecase
                      }}
                    </span>
                  </h5>
                </div>
              </div>
              <!-- <mat-icon class="color-info" *ngIf="isCentrePane" style="display: contents;" (click)="openMeasureofSuccessModal(treearray,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon> -->
              <div class="d-flex justify-content-center">
                <div class="formParentArrow p-2 d-flex align-items-center">
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="!formParentTree[treeindex]"
                  >
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="formParentTree[treeindex]"
                  >
                    keyboard_arrow_up
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [id]="'formParent' + parentTagName + treeindex"
        class="accordion-collapse"
        [ngClass]="treearray?.hasError == true ? 'show' : 'collapse'"
        [attr.aria-labelledby]="parentTagName + treeindex"
        [attr.data-bs-parent]="parentTagName + treeindex"
      >
        <div class="accordion-body formParentBody">
          <div
            class="subParentContainer"
            *ngFor="
              let subParent of treearray?.child_key_results;
              index as subParentindex
            "
          >
            <div
              class="d-flex subParentRow"
              *ngIf="!subParent?.is_linked_key_result"
            >
              <div
                class="pe-1 ps-1 flex-shrink-1 dotted"
                [ngClass]="{
                  dottedNoLength:
                    treearray?.term_sub_category?.length - 1 == subParentindex
                }"
              >
                <div
                  class="d-flex justify-content-center"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="
                    '#' +
                    subParentTagname +
                    '_' +
                    treeindex +
                    '_' +
                    subParentindex
                  "
                  aria-expanded="true"
                  [attr.aria-controls]="'parent' + subParentindex"
                  (click)="
                    formSubParentTree[subParentindex] =
                      !formSubParentTree[subParentindex]
                  "
                >
                  <div
                    class="formSubParentArrow d-flex align-items-center"
                    *ngIf="subParentTagname + subParentindex"
                  >
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="!formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_down
                    </mat-icon>
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_up
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="pe-1 ps-1 w-100">
                <div class="accordion accordion-flush subParentAccordion">
                  <!-- data-bs-toggle="collapse" -->
                  <div
                    class="accordion-item subParentItem cursor-pointer pb-2"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="
                      '#' +
                      subParentTagname +
                      '_' +
                      treeindex +
                      '_' +
                      subParentindex
                    "
                    aria-expanded="true"
                    [attr.aria-controls]="'parent' + subParentindex"
                  >
                    <div class="accordion-header subParentHeader">
                      <div class="d-flex">
                        <span class="bubble-right pt-2 pb-2"></span>
                        <div class="p-2 w-100 two-lines">
                          <p
                            class="recommended-badge m-0 mb-2"
                            *ngIf="subParent?.is_recomended"
                          >
                            <i class="fa fa-star"></i>Recommended
                          </p>
                          <h5
                            class="subParentHead w-100"
                            container="body"
                            [ngbPopover]="subParent?.sub_category_display_name"
                            popoverClass="darkTheme"
                            triggers="mouseenter:mouseleave"
                          >
                            <span class="treeId pe-1 fw-bold">{{
                              subParentindex + 1
                            }}</span>
                            <span
                              class="w-80"
                              [ngbPopover]="
                                subParent?.risk_sub_domain_display_name
                                  | titlecase
                              "
                              popoverClass="darkTheme"
                              triggers="mouseenter:mouseleave"
                              container="body"
                            >
                              {{
                                subParent?.key_result_title ||
                                  subParent?.key_title ||
                                  "Not Available" | titlecase
                              }}</span
                            >
                            <!-- <div class="removeicon" *ngIf="isCentrePane">
                                                                <mat-icon class="color-info" (click)="openMeasureofSuccessModal(subParent,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon>
                                                             </div> -->
                          </h5>
                        </div>
                        <!--*ngxPermissionsOnly="[treeAddPermission]"-->
                        <ng-container>
                          <div
                            class="pb-2 addicon"
                            id="add_sub_category"
                            *ngIf="
                              addicon && !disableEdit && !subParent.isMapped
                            "
                            (click)="AddRemoveColumnOKR(subParent, treearray)"
                            style="margin-right: 10px; margin-top: 10px"
                          >
                            <mat-icon
                              id="add_span_{{ child?.prefix }}"
                              class="material-icons-outlined"
                            >
                              add
                            </mat-icon>
                          </div>
                          <div
                            class="pb-2 checkicon"
                            id="add_sub_category"
                            *ngIf="
                              addicon && !disableEdit && subParent.isMapped
                            "
                            style="margin-right: 10px; margin-top: 10px"
                          >
                            <mat-icon
                              id="add_span_{{ child?.prefix }}"
                              class="material-icons-outlined"
                            >
                              check
                            </mat-icon>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div
                    [id]="
                      subParentTagname + '_' + treeindex + '_' + subParentindex
                    "
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="parentTagName + treeindex"
                    [attr.data-bs-parent]="parentTagName + treeindex"
                  >
                    <div class="accordion-body subParentBody pt-1">
                      <div
                        class="child"
                        *ngFor="
                          let child of subParent?.measure_of_success;
                          index as childIndex
                        "
                      >
                        <div class="d-flex childRow">
                          <div
                            class="pe-1 ps-1 flex-shrink-1 dotted"
                            [ngClass]="{
                              dottedNoLength:
                                subParent?.term_measurement_criteria?.length -
                                  1 ==
                                childIndex
                            }"
                          >
                            <div
                              class="formChildArrow d-flex align-items-center"
                            >
                              <span class="yellowcircle"></span>
                            </div>
                          </div>
                          <div class="pe-1 ps-1 w-100 pb-2">
                            <div class="d-flex flex-column border-form">
                              <div
                                class="childaccordion cursor-pointer"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                [attr.data-bs-target]="
                                  '#' +
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                [attr.aria-controls]="
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                (click)="
                                  measurmentForm[
                                    subParentindex + childIndex + treeindex
                                  ] =
                                    !measurmentForm[
                                      subParentindex + childIndex + treeindex
                                    ]
                                "
                              >
                                <div class="d-flex">
                                  <span class="bubble-right pt-2 pb-2"></span>
                                  <div class="w-100">
                                    <div
                                      class="d-flex flex-row justify-content-between align-items-center"
                                    >
                                      <div class="three-lines">
                                        <h5
                                          class="childhead"
                                          container="body"
                                          popoverClass="darkTheme"
                                          triggers="mouseenter:mouseleave"
                                          style="margin-top: 10px"
                                        >
                                          <span class="treeId pe-1 fw-bold">{{
                                            childIndex + 1
                                          }}</span>
                                          <span
                                            [ngbPopover]="
                                              child?.goal_name | titlecase
                                            "
                                            container="body"
                                            popoverClass="darkTheme"
                                            triggers="mouseenter:mouseleave"
                                            >{{
                                              child?.goal_name | titlecase
                                            }}</span
                                          >
                                        </h5>
                                      </div>

                                      <div
                                        class="d-flex flex-column justify-content-center align-items-center actionIcons"
                                      >
                                        <div
                                          class="d-flex flex-row justify-content-around"
                                        >
                                          <div
                                            class="measurementFormArrow cursor-pointer p-2 d-flex align-items-center"
                                            *ngIf="formShow"
                                          >
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                !measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_down
                                            </mat-icon>
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_up
                                            </mat-icon>
                                          </div>
                                          <ng-container>
                                            <!--*ngxPermissionsOnly="[treeDeletePermission]"-->
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == true &&
                                                !disableEdit
                                              "
                                              (click)="DeleteKey_Toe(child)"
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-danger cursor-pointer"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == false
                                              "
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-muted cursor-pointer"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom  || auto"
                                                title="Custom Measurement Consumed Can't be Removed"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ng-container *ngIf="formShow && !RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse"
                                  [ngClass]="
                                    child?.hasError == true
                                      ? 'show'
                                      : 'collapse'
                                  "
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label
                                            >Milestone Phase
                                          </mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            [(ngModel)]="child.milestone"
                                            [name]="
                                              'milestone' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your MileStone Phase here"
                                            [disabled]="formInputDisable"
                                          />
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Deadline</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [(ngModel)]="child.deadline"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'deadline' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                            [disabled]="formInputDisable"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col flex-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Notes</mat-label>
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [(ngModel)]="child.notes"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'notes' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            placeholder="Type your Notes here.."
                                            [disabled]="formInputDisable"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="formShow && RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse collapse"
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2 text-capitalize">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk Description
                                          </mat-label>
                                          <!--<input type="text" matInput
                                                                                        [(ngModel)]="child.risk_description"
                                                                                        [name]="'risk_description'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Description here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_description' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_description"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Description here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                        <!-- <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>Risk Owner</mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'riskowner'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.risk_owner"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let riskOwnerList of formArray"
                                                                                            [value]="riskOwnerList">
                                                                                            {{riskOwnerList.name ||
                                                                                            riskOwnerList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field> -->
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk mitigation plan
                                          </mat-label>
                                          <!-- <input type="text" matInput
                                                                                        [(ngModel)]="child.risk_mitigation"
                                                                                        [name]="'risk_mitigation'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Mitigation plan here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_mitigation' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_mitigation"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Mitigation plan here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Delegate</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Delegate"
                                            aria-label="Number"
                                            [name]="
                                              'delegate' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.delegate"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="Delegate"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #Delegate="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let delegateList of formArray?.deliveryOwnersList
                                              "
                                              [value]="delegateList"
                                            >
                                              {{
                                                delegateList.name ||
                                                  delegateList.mail
                                              }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Due Date</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [name]="
                                              'due' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.due_date"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Status</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Status"
                                            aria-label="Number"
                                            [name]="
                                              'status' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.status"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="status"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #status="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let status of formArray?.statusList
                                              "
                                              [value]="status"
                                            >
                                              {{ status.label }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div
                                        class="infoicon"
                                        *ngIf="isCentrePane"
                                      >
                                        <mat-icon
                                          class="color-info infoicon"
                                          (click)="
                                            openMeasureofSuccessModal(
                                              child,
                                              treearray?.is_custom,
                                              MeasureofSuccessModal,
                                              $event
                                            )
                                          "
                                        >
                                          info</mat-icon
                                        >
                                      </div>
                                      <!-- <div class="col flex-fill">
                                                                                <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>General Manager
                                                                                    </mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'manager'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.director"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let managarList of formArray"
                                                                                            [value]="managarList">
                                                                                            {{managarList.name ||
                                                                                            managarList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field>
                                                                            </div> -->
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <ng-container>
                            <!--*ngxPermissionsOnly="[treeRemovePermission]"-->
                            <div class="p-2" *ngIf="removeicon && !disableEdit">
                              <div
                                class="removeicon"
                                (click)="
                                  AddRemoveColumn(child, treearray?.is_custom)
                                "
                              >
                                <img
                                  class="removeimg cursor-pointer"
                                  src="/assets/images/pages/tree/treecross.svg"
                                  alt="close"
                                  width="auto"
                                  height="auto"
                                />
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section
  id="formtreelist"
  [class]="treeclass"
  *ngIf="treecardtype == 'formtreeokrnew'"
>
  <div class="formParent">
    <div
      class="accordion accordion-flush formParentAccordion"
      [id]="parentTagName + treeindex"
      *ngIf="!showChildOnly"
    >
      <div
        class="accordion-item formParentItem"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#formParent' + parentTagName + treeindex"
        aria-expanded="true"
        [attr.aria-controls]="'formParent' + treeindex"
      >
        <div class="accordion-header p-0 formParentHeader cursor-pointer pl-0">
          <div
            class="d-flex flex-column justify-content-around"
            (click)="formParentTree[treeindex] = !formParentTree[treeindex]"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="p-2" style="padding-left: 0px !important">
                <div class="flex-fill d-flex align-items-center">
                  <!-- <span class="small-bubble-left pt-2 pb-2"
                                        [ngStyle]="{'background-color': treeindex | getRandomColor}"></span> -->
                  <h5 class="formParentHead pl-2 mb-0 one-lines">
                    <span class="treeId pe-1 fw-bold">{{ treeindex + 1 }}</span>
                    <span
                      container="body"
                      [ngbPopover]="
                        treearray?.key_result_title || treearray?.key_title
                          | titlecase
                      "
                      popoverClass="darkTheme"
                      triggers="mouseenter:mouseleave"
                      >{{
                        treearray?.key_result_title || treearray?.key_title
                          | titlecase
                      }}
                    </span>
                  </h5>
                </div>
              </div>
              <!-- <mat-icon class="color-info" *ngIf="isCentrePane" style="display: contents;" (click)="openMeasureofSuccessModal(treearray,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon> -->
              <div class="d-flex justify-content-center">
                <div class="formParentArrow p-2 d-flex align-items-center">
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="!formParentTree[treeindex]"
                  >
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="formParentTree[treeindex]"
                  >
                    keyboard_arrow_up
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [id]="'formParent' + parentTagName + treeindex"
        class="accordion-collapse"
        [ngClass]="treearray?.hasError == true ? 'show' : 'collapse'"
        [attr.aria-labelledby]="parentTagName + treeindex"
        [attr.data-bs-parent]="parentTagName + treeindex"
      >
        <div class="accordion-body formParentBody">
          <div
            class="subParentContainer"
            *ngFor="
              let subParent of treearray?.child_key_results;
              index as subParentindex
            "
          >
            <div
              class="d-flex subParentRow"
              *ngIf="!subParent?.is_linked_key_result"
            >
              <div
                class="pe-1 ps-1 flex-shrink-1 dotted"
                [ngClass]="{
                  dottedNoLength:
                    treearray?.term_sub_category?.length - 1 == subParentindex
                }"
              >
                <div
                  class="d-flex justify-content-center"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="
                    '#' +
                    subParentTagname +
                    '_' +
                    treeindex +
                    '_' +
                    subParentindex
                  "
                  aria-expanded="true"
                  [attr.aria-controls]="'parent' + subParentindex"
                  (click)="
                    formSubParentTree[subParentindex] =
                      !formSubParentTree[subParentindex]
                  "
                >
                  <div
                    class="formSubParentArrow d-flex align-items-center"
                    *ngIf="subParentTagname + subParentindex"
                  >
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="!formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_down
                    </mat-icon>
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_up
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="pe-1 ps-1 w-100">
                <div class="accordion accordion-flush subParentAccordion">
                  <!-- data-bs-toggle="collapse" -->
                  <div
                    class="accordion-item subParentItem cursor-pointer pb-2"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="
                      '#' +
                      subParentTagname +
                      '_' +
                      treeindex +
                      '_' +
                      subParentindex
                    "
                    aria-expanded="true"
                    [attr.aria-controls]="'parent' + subParentindex"
                  >
                    <div class="accordion-header subParentHeader">
                      <div class="d-flex">
                        <span class="bubble-right pt-2 pb-2"></span>
                        <div class="p-2 w-100 two-lines">
                          <p
                            class="recommended-badge m-0 mb-2"
                            *ngIf="subParent?.is_recomended"
                          >
                            <i class="fa fa-star"></i>Recommended
                          </p>
                          <h5
                            class="subParentHead w-100"
                            container="body"
                            [ngbPopover]="subParent?.sub_category_display_name"
                            popoverClass="darkTheme"
                            triggers="mouseenter:mouseleave"
                          >
                            <span class="treeId pe-1 fw-bold">{{
                              subParentindex + 1
                            }}</span>
                            <span
                              class="w-80"
                              [ngbPopover]="
                                subParent?.risk_sub_domain_display_name
                                  | titlecase
                              "
                              popoverClass="darkTheme"
                              triggers="mouseenter:mouseleave"
                              container="body"
                            >
                              {{
                                subParent?.key_result_title ||
                                  subParent?.key_title ||
                                  "Not Available" | titlecase
                              }}</span
                            >
                            <!-- <div class="removeicon" *ngIf="isCentrePane">
                                                                <mat-icon class="color-info" (click)="openMeasureofSuccessModal(subParent,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon>
                                                             </div> -->
                          </h5>
                        </div>
                        <!--*ngxPermissionsOnly="[treeAddPermission]"-->
                        <ng-container>
                          <div
                            class="pb-2 addicon"
                            id="add_sub_category"
                            *ngIf="
                              addicon && !disableEdit && !subParent.isMapped
                            "
                            (click)="AddRemoveColumnOKR(subParent, treearray)"
                            style="margin-right: 10px; margin-top: 10px"
                          >
                            <mat-icon
                              id="add_span_{{ child?.prefix }}"
                              class="material-icons-outlined"
                            >
                              add
                            </mat-icon>
                          </div>
                          <div
                            class="pb-2 checkicon"
                            id="add_sub_category"
                            *ngIf="
                              addicon && !disableEdit && subParent.isMapped
                            "
                            style="margin-right: 10px; margin-top: 10px"
                          >
                            <mat-icon
                              id="add_span_{{ child?.prefix }}"
                              class="material-icons-outlined"
                            >
                              check
                            </mat-icon>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div
                    [id]="
                      subParentTagname + '_' + treeindex + '_' + subParentindex
                    "
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="parentTagName + treeindex"
                    [attr.data-bs-parent]="parentTagName + treeindex"
                  >
                    <div class="accordion-body subParentBody pt-1">
                      <div
                        class="child"
                        *ngFor="
                          let child of subParent?.measure_of_success;
                          index as childIndex
                        "
                      >
                        <div class="d-flex childRow">
                          <div
                            class="pe-1 ps-1 flex-shrink-1 dotted"
                            [ngClass]="{
                              dottedNoLength:
                                subParent?.term_measurement_criteria?.length -
                                  1 ==
                                childIndex
                            }"
                          >
                            <div
                              class="formChildArrow d-flex align-items-center"
                            >
                              <span class="yellowcircle"></span>
                            </div>
                          </div>
                          <div class="pe-1 ps-1 w-100 pb-2">
                            <div class="d-flex flex-column border-form">
                              <div
                                class="childaccordion cursor-pointer"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                [attr.data-bs-target]="
                                  '#' +
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                [attr.aria-controls]="
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                (click)="
                                  measurmentForm[
                                    subParentindex + childIndex + treeindex
                                  ] =
                                    !measurmentForm[
                                      subParentindex + childIndex + treeindex
                                    ]
                                "
                              >
                                <div class="d-flex">
                                  <span class="bubble-right pt-2 pb-2"></span>
                                  <div class="w-100">
                                    <div
                                      class="d-flex flex-row justify-content-between align-items-center"
                                    >
                                      <div class="three-lines">
                                        <h5
                                          class="childhead"
                                          container="body"
                                          popoverClass="darkTheme"
                                          triggers="mouseenter:mouseleave"
                                          style="margin-top: 10px"
                                        >
                                          <span class="treeId pe-1 fw-bold">{{
                                            childIndex + 1
                                          }}</span>
                                          <span
                                            [ngbPopover]="
                                              child?.goal_name | titlecase
                                            "
                                            container="body"
                                            popoverClass="darkTheme"
                                            triggers="mouseenter:mouseleave"
                                            >{{
                                              child?.goal_name | titlecase
                                            }}</span
                                          >
                                        </h5>
                                      </div>

                                      <div
                                        class="d-flex flex-column justify-content-center align-items-center actionIcons"
                                      >
                                        <div
                                          class="d-flex flex-row justify-content-around"
                                        >
                                          <div
                                            class="measurementFormArrow cursor-pointer p-2 d-flex align-items-center"
                                            *ngIf="formShow"
                                          >
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                !measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_down
                                            </mat-icon>
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_up
                                            </mat-icon>
                                          </div>
                                          <ng-container>
                                            <!--*ngxPermissionsOnly="[treeDeletePermission]"-->
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == true &&
                                                !disableEdit
                                              "
                                              (click)="DeleteKey_Toe(child)"
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-danger cursor-pointer"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == false
                                              "
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-muted cursor-pointer"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom  || auto"
                                                title="Custom Measurement Consumed Can't be Removed"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ng-container *ngIf="formShow && !RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse"
                                  [ngClass]="
                                    child?.hasError == true
                                      ? 'show'
                                      : 'collapse'
                                  "
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label
                                            >Milestone Phase
                                          </mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            [(ngModel)]="child.milestone"
                                            [name]="
                                              'milestone' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your MileStone Phase here"
                                            [disabled]="formInputDisable"
                                          />
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Deadline</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [(ngModel)]="child.deadline"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'deadline' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                            [disabled]="formInputDisable"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col flex-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Notes</mat-label>
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [(ngModel)]="child.notes"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'notes' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            placeholder="Type your Notes here.."
                                            [disabled]="formInputDisable"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="formShow && RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse collapse"
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2 text-capitalize">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk Description
                                          </mat-label>
                                          <!--<input type="text" matInput
                                                                                        [(ngModel)]="child.risk_description"
                                                                                        [name]="'risk_description'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Description here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_description' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_description"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Description here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                        <!-- <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>Risk Owner</mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'riskowner'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.risk_owner"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let riskOwnerList of formArray"
                                                                                            [value]="riskOwnerList">
                                                                                            {{riskOwnerList.name ||
                                                                                            riskOwnerList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field> -->
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk mitigation plan
                                          </mat-label>
                                          <!-- <input type="text" matInput
                                                                                        [(ngModel)]="child.risk_mitigation"
                                                                                        [name]="'risk_mitigation'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Mitigation plan here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_mitigation' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_mitigation"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Mitigation plan here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Delegate</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Delegate"
                                            aria-label="Number"
                                            [name]="
                                              'delegate' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.delegate"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="Delegate"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #Delegate="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let delegateList of formArray?.deliveryOwnersList
                                              "
                                              [value]="delegateList"
                                            >
                                              {{
                                                delegateList.name ||
                                                  delegateList.mail
                                              }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Due Date</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [name]="
                                              'due' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.due_date"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Status</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Status"
                                            aria-label="Number"
                                            [name]="
                                              'status' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.status"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="status"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #status="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let status of formArray?.statusList
                                              "
                                              [value]="status"
                                            >
                                              {{ status.label }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div
                                        class="infoicon"
                                        *ngIf="isCentrePane"
                                      >
                                        <mat-icon
                                          class="color-info infoicon"
                                          (click)="
                                            openMeasureofSuccessModal(
                                              child,
                                              treearray?.is_custom,
                                              MeasureofSuccessModal,
                                              $event
                                            )
                                          "
                                        >
                                          info</mat-icon
                                        >
                                      </div>
                                      <!-- <div class="col flex-fill">
                                                                                <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>General Manager
                                                                                    </mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'manager'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.director"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let managarList of formArray"
                                                                                            [value]="managarList">
                                                                                            {{managarList.name ||
                                                                                            managarList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field>
                                                                            </div> -->
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <ng-container>
                            <!--*ngxPermissionsOnly="[treeRemovePermission]"-->
                            <div class="p-2" *ngIf="removeicon && !disableEdit">
                              <div
                                class="removeicon"
                                (click)="
                                  AddRemoveColumn(child, treearray?.is_custom)
                                "
                              >
                                <img
                                  class="removeimg cursor-pointer"
                                  src="/assets/images/pages/tree/treecross.svg"
                                  alt="close"
                                  width="auto"
                                  height="auto"
                                />
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section
  id="formtreelist"
  [class]="treeclass"
  *ngIf="treecardtype == 'formtreeokrselected'"
>
  <div class="formParent">
    <div
      class="accordion accordion-flush formParentAccordion"
      [id]="parentTagName + treeindex"
      *ngIf="!showChildOnly"
    >
      <div
        class="accordion-item formParentItem"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#formParent' + parentTagName + treeindex"
        aria-expanded="true"
        [attr.aria-controls]="'formParent' + treeindex"
      >
        <div class="accordion-header p-0 formParentHeader cursor-pointer pl-0">
          <div
            class="d-flex flex-column justify-content-around"
            (click)="formParentTree[treeindex] = !formParentTree[treeindex]"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="p-2" style="padding-left: 0px !important">
                <div class="flex-fill d-flex align-items-center">
                  <!-- <span class="small-bubble-left pt-2 pb-2"
                                        [ngStyle]="{'background-color': treeindex | getRandomColor}"></span> -->
                  <h5 class="formParentHead pl-2 mb-0 one-lines">
                    <span class="treeId pe-1 fw-bold">{{ treeindex + 1 }}</span>
                    <span
                      container="body"
                      [ngbPopover]="
                        treearray?.key_result_title ||
                          treearray?.key_result?.title | titlecase
                      "
                      popoverClass="darkTheme"
                      triggers="mouseenter:mouseleave"
                      >{{
                        treearray?.key_result_title ||
                          treearray?.key_result?.title | titlecase
                      }}
                    </span>
                  </h5>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="formParentArrow p-2 d-flex align-items-center">
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="!formParentTree[treeindex]"
                  >
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="formParentTree[treeindex]"
                  >
                    keyboard_arrow_up
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [id]="'formParent' + parentTagName + treeindex"
        class="accordion-collapse"
        [ngClass]="treearray?.hasError == true ? 'show' : 'collapse'"
        [attr.aria-labelledby]="parentTagName + treeindex"
        [attr.data-bs-parent]="parentTagName + treeindex"
      >
        <div class="accordion-body formParentBody">
          <div
            class="subParentContainer"
            *ngFor="
              let subParent of treearray?.key_result?.child_key_results;
              index as subParentindex
            "
          >
            <div class="d-flex subParentRow">
              <div
                class="pe-1 ps-1 flex-shrink-1 dotted"
                [ngClass]="{
                  dottedNoLength:
                    treearray?.term_sub_category?.length - 1 == subParentindex
                }"
              >
                <div
                  class="d-flex justify-content-center"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="
                    '#' +
                    subParentTagname +
                    '_' +
                    treeindex +
                    '_' +
                    subParentindex
                  "
                  aria-expanded="true"
                  [attr.aria-controls]="'parent' + subParentindex"
                  (click)="
                    formSubParentTree[subParentindex] =
                      !formSubParentTree[subParentindex]
                  "
                >
                  <div
                    class="formSubParentArrow d-flex align-items-center"
                    *ngIf="subParentTagname + subParentindex"
                  >
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="!formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_down
                    </mat-icon>
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_up
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="pe-1 ps-1 w-100">
                <div class="accordion accordion-flush subParentAccordion">
                  <!-- data-bs-toggle="collapse" -->
                  <div
                    class="accordion-item subParentItem cursor-pointer pb-2"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="
                      '#' +
                      subParentTagname +
                      '_' +
                      treeindex +
                      '_' +
                      subParentindex
                    "
                    aria-expanded="true"
                    [attr.aria-controls]="'parent' + subParentindex"
                  >
                    <div class="accordion-header subParentHeader">
                      <div class="d-flex">
                        <span class="bubble-right pt-2 pb-2"></span>
                        <div class="p-2 w-100 two-lines">
                          <h5
                            class="subParentHead w-100 flx"
                            container="body"
                            [ngbPopover]="subParent?.sub_category_display_name"
                            popoverClass="darkTheme"
                            triggers="mouseenter:mouseleave"
                          >
                            <span class="treeId pe-1 fw-bold mr-1">{{
                              subParentindex + 1
                            }}</span>
                            <span
                              class="w-80"
                              [ngbPopover]="
                                subParent?.key_title || subParent?.title
                                  | titlecase
                              "
                              popoverClass="darkTheme"
                              triggers="mouseenter:mouseleave"
                              container="body"
                            >
                              {{
                                subParent?.key_title || subParent?.title
                                  | titlecase
                              }}</span
                            >
                            <span class="removeicon">
                              <mat-icon
                                class="material-icons-outlined text-danger cursor-pointer"
                                (click)="
                                  DeleteKey_Okr(
                                    treearray?.key_result?.id,
                                    subParentindex
                                  )
                                "
                                >delete</mat-icon
                              >
                            </span>
                          </h5>
                        </div>
                        <!--*ngxPermissionsOnly="[treeAddPermission]"-->
                        <!-- <ng-container>
                                                        <div  class="pb-2 addicon" id="add_sub_category" *ngIf="addicon && !disableEdit && !subParent.isMapped" (click)="AddRemoveColumnOKR(subParent,treearray)" style="margin-right: 10px;margin-top: 10px;" >
                                                            <span  id="add_span_{{child?.prefix}}" class="material-icons-outlined">
                                                                add
                                                            </span>
                                                        </div>
                                                    </ng-container> -->
                      </div>
                    </div>
                  </div>
                  <div
                    [id]="
                      subParentTagname + '_' + treeindex + '_' + subParentindex
                    "
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="parentTagName + treeindex"
                    [attr.data-bs-parent]="parentTagName + treeindex"
                  >
                    <div class="accordion-body subParentBody pt-1">
                      <div
                        class="child"
                        *ngFor="
                          let child of subParent?.measure_of_success;
                          index as childIndex
                        "
                      >
                        <div class="d-flex childRow">
                          <div
                            class="pe-1 ps-1 flex-shrink-1 dotted"
                            [ngClass]="{
                              dottedNoLength:
                                subParent?.term_measurement_criteria?.length -
                                  1 ==
                                childIndex
                            }"
                          >
                            <div
                              class="formChildArrow d-flex align-items-center"
                            >
                              <span class="yellowcircle"></span>
                            </div>
                          </div>
                          <div class="pe-1 ps-1 w-100 pb-2">
                            <div class="d-flex flex-column border-form">
                              <div
                                class="childaccordion cursor-pointer"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                [attr.data-bs-target]="
                                  '#' +
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                [attr.aria-controls]="
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                (click)="
                                  measurmentForm[
                                    subParentindex + childIndex + treeindex
                                  ] =
                                    !measurmentForm[
                                      subParentindex + childIndex + treeindex
                                    ]
                                "
                              >
                                <div class="d-flex">
                                  <span class="bubble-right pt-2 pb-2"></span>
                                  <div class="w-100">
                                    <div
                                      class="d-flex flex-row justify-content-between align-items-center"
                                    >
                                      <div class="three-lines">
                                        <h5
                                          class="childhead"
                                          container="body"
                                          popoverClass="darkTheme"
                                          triggers="mouseenter:mouseleave"
                                          style="margin-top: 10px"
                                        >
                                          <span class="treeId pe-1 fw-bold">{{
                                            childIndex + 1
                                          }}</span>
                                          <span
                                            [ngbPopover]="
                                              child?.goal_name | titlecase
                                            "
                                            container="body"
                                            popoverClass="darkTheme"
                                            triggers="mouseenter:mouseleave"
                                            >{{
                                              child?.goal_name | titlecase
                                            }}</span
                                          >
                                        </h5>
                                      </div>

                                      <div
                                        class="d-flex flex-column justify-content-center align-items-center actionIcons"
                                      >
                                        <div
                                          class="d-flex flex-row justify-content-around"
                                        >
                                          <div
                                            class="measurementFormArrow cursor-pointer p-2 d-flex align-items-center"
                                            *ngIf="formShow"
                                          >
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                !measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_down
                                            </mat-icon>
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_up
                                            </mat-icon>
                                          </div>
                                          <ng-container>
                                            <!--*ngxPermissionsOnly="[treeDeletePermission]"-->
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == true &&
                                                !disableEdit
                                              "
                                              (click)="DeleteKey_Toe(child)"
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-danger cursor-pointer"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == false
                                              "
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-muted cursor-pointer"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom  || auto"
                                                title="Custom Measurement Consumed Can't be Removed"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                          <ng-container>
                                            <div
                                              style="
                                                margin-top: 10px;
                                                margin-right: 10px;
                                              "
                                              class="pb-2 addicon"
                                              id="add_{{ child?.prefix }}"
                                              *ngIf="addicon && !disableEdit"
                                              (click)="infoButton(child)"
                                            >
                                              <mat-icon
                                                id="add_span_{{
                                                  child?.prefix
                                                }}"
                                                class="material-icons-outlined"
                                              >
                                                info
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                          <!-- <ng-container>
                                                                                        *ngxPermissionsOnly="[treeAddPermission]"
                                                                                        <div class="pb-2 addicon" id="add_{{child?.prefix}}" *ngIf="addicon && !disableEdit" (click)=AddRemoveColumn(child,treearray?.is_custom)>
                                                                                            <span  id="add_span_{{child?.prefix}}" class="material-icons-outlined">
                                                                                                add
                                                                                            </span>
                                                                                        </div>
                                                                                    </ng-container> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ng-container *ngIf="formShow && !RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse"
                                  [ngClass]="
                                    child?.hasError == true
                                      ? 'show'
                                      : 'collapse'
                                  "
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label
                                            >Milestone Phase
                                          </mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            [(ngModel)]="child.milestone"
                                            [name]="
                                              'milestone' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your MileStone Phase here"
                                            [disabled]="formInputDisable"
                                          />
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Deadline</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [(ngModel)]="child.deadline"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'deadline' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                            [disabled]="formInputDisable"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col flex-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Notes</mat-label>
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [(ngModel)]="child.notes"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'notes' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            placeholder="Type your Notes here.."
                                            [disabled]="formInputDisable"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="formShow && RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse collapse"
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2 text-capitalize">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk Description
                                          </mat-label>
                                          <!--<input type="text" matInput
                                                                                        [(ngModel)]="child.risk_description"
                                                                                        [name]="'risk_description'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Description here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_description' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_description"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Description here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                        <!-- <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>Risk Owner</mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'riskowner'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.risk_owner"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let riskOwnerList of formArray"
                                                                                            [value]="riskOwnerList">
                                                                                            {{riskOwnerList.name ||
                                                                                            riskOwnerList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field> -->
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk mitigation plan
                                          </mat-label>
                                          <!-- <input type="text" matInput
                                                                                        [(ngModel)]="child.risk_mitigation"
                                                                                        [name]="'risk_mitigation'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Mitigation plan here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_mitigation' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_mitigation"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Mitigation plan here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Delegate</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Delegate"
                                            aria-label="Number"
                                            [name]="
                                              'delegate' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.delegate"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="Delegate"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #Delegate="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let delegateList of formArray?.deliveryOwnersList
                                              "
                                              [value]="delegateList"
                                            >
                                              {{
                                                delegateList.name ||
                                                  delegateList.mail
                                              }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Due Date</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [name]="
                                              'due' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.due_date"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Status</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Status"
                                            aria-label="Number"
                                            [name]="
                                              'status' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.status"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="status"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #status="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let status of formArray?.statusList
                                              "
                                              [value]="status"
                                            >
                                              {{ status.label }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div
                                        class="infoicon"
                                        *ngIf="isCentrePane"
                                      >
                                        <mat-icon
                                          class="color-info infoicon"
                                          (click)="
                                            openMeasureofSuccessModal(
                                              child,
                                              treearray?.is_custom,
                                              MeasureofSuccessModal,
                                              $event
                                            )
                                          "
                                        >
                                          info</mat-icon
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <ng-container>
                            <div class="p-2" *ngIf="removeicon && !disableEdit">
                              <div
                                class="removeicon"
                                (click)="
                                  AddRemoveColumn(child, treearray?.is_custom)
                                "
                              >
                                <img
                                  class="removeimg cursor-pointer"
                                  src="/assets/images/pages/tree/treecross.svg"
                                  alt="close"
                                  width="auto"
                                  height="auto"
                                />
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  id="formtreelist"
  [class]="treeclass"
  *ngIf="treecardtype == 'formtreetoe' || treecardtype == 'formtreetoeselected'"
>
  <div class="formParent">
    <div
      class="accordion accordion-flush formParentAccordion"
      [id]="parentTagName + treeindex"
      *ngIf="!showChildOnly"
    >
      <div
        class="accordion-item formParentItem"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#formParent' + parentTagName + treeindex"
        aria-expanded="true"
        [attr.aria-controls]="'formParent' + treeindex"
      >
        <div class="accordion-header p-0 formParentHeader cursor-pointer pl-0">
          <div
            class="d-flex flex-column justify-content-around"
            (click)="formParentTree[treeindex] = !formParentTree[treeindex]"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div class="p-2" style="padding-left: 0px !important">
                <div class="flex-fill d-flex align-items-center">
                  <!-- <span class="small-bubble-left pt-2 pb-2"
                                        [ngStyle]="{'background-color': treeindex | getRandomColor}"></span> -->
                  <h5 class="formParentHead pl-2 mb-0 one-lines">
                    <span class="treeId pe-1 fw-bold">{{ treeindex + 1 }}</span>
                    <span
                      container="body"
                      [ngbPopover]="
                        treearray?.display_name ||
                          treearray?.term_category?.category_display_name
                          | titlecase
                      "
                      popoverClass="darkTheme"
                      triggers="mouseenter:mouseleave"
                      >{{
                        treearray?.term_category?.category_display_name ||
                          treearray?.display_name | titlecase
                      }}
                    </span>
                  </h5>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="formParentArrow p-2 d-flex align-items-center">
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="!formParentTree[treeindex]"
                  >
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon
                    class="material-icons-outlined"
                    *ngIf="formParentTree[treeindex]"
                  >
                    keyboard_arrow_up
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [id]="'formParent' + parentTagName + treeindex"
        class="accordion-collapse"
        [ngClass]="treearray?.hasError == true ? 'show' : 'collapse'"
        [attr.aria-labelledby]="parentTagName + treeindex"
        [attr.data-bs-parent]="parentTagName + treeindex"
      >
        <div class="accordion-body formParentBody">
          <div
            class="subParentContainer"
            *ngFor="
              let subParent of treearray?.term_sub_category ||
                treearray?.term_category?.term_sub_category;
              index as subParentindex
            "
          >
            <div class="d-flex subParentRow">
              <div
                class="pe-1 ps-1 flex-shrink-1 dotted"
                [ngClass]="{
                  dottedNoLength:
                    treearray?.term_sub_category?.length - 1 ==
                      subParentindex ||
                    treearray?.term_category?.term_sub_category?.length - 1 ==
                      subParentindex
                }"
              >
                <div
                  class="d-flex justify-content-center"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="
                    '#' +
                    subParentTagname +
                    '_' +
                    treeindex +
                    '_' +
                    subParentindex
                  "
                  aria-expanded="true"
                  [attr.aria-controls]="'parent' + subParentindex"
                  (click)="
                    formSubParentTree[subParentindex] =
                      !formSubParentTree[subParentindex]
                  "
                >
                  <div
                    class="formSubParentArrow d-flex align-items-center"
                    *ngIf="subParentTagname + subParentindex"
                  >
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="!formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_down
                    </mat-icon>
                    <mat-icon
                      class="material-icons-outlined"
                      *ngIf="formSubParentTree[subParentindex]"
                    >
                      keyboard_arrow_up
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="pe-1 ps-1 w-100">
                <div class="accordion accordion-flush subParentAccordion">
                  <!-- data-bs-toggle="collapse" -->
                  <div
                    class="accordion-item subParentItem cursor-pointer pb-2"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="
                      '#' +
                      subParentTagname +
                      '_' +
                      treeindex +
                      '_' +
                      subParentindex
                    "
                    aria-expanded="true"
                    [attr.aria-controls]="'parent' + subParentindex"
                  >
                    <div class="accordion-header subParentHeader">
                      <div class="d-flex">
                        <span class="bubble-right pt-2 pb-2"></span>
                        <div class="p-2 w-100 two-lines">
                          <h5
                            class="subParentHead w-100"
                            container="body"
                            [ngbPopover]="subParent?.sub_category_display_name"
                            popoverClass="darkTheme"
                            triggers="mouseenter:mouseleave"
                          >
                            <span class="treeId pe-1 fw-bold">{{
                              subParentindex + 1
                            }}</span>
                            <span
                              class="w-80"
                              [ngbPopover]="
                                subParent?.display_name ||
                                  subParent?.title ||
                                  subParent?.sub_category_display_name
                                  | titlecase
                              "
                              popoverClass="darkTheme"
                              triggers="mouseenter:mouseleave"
                              container="body"
                            >
                              {{
                                subParent?.display_name ||
                                  subParent?.sub_category_display_name
                                  | titlecase
                              }}</span
                            >
                            <!-- <div class="removeicon" *ngIf="isCentrePane">
                                                                <mat-icon class="color-info" (click)="openMeasureofSuccessModal(subParent,treearray?.is_custom,MeasureofSuccessModal,$event)">info</mat-icon>
                                                             </div> -->
                          </h5>
                        </div>
                        <!--*ngxPermissionsOnly="[treeAddPermission]"-->
                        <!-- <ng-container>
                                                        <div  class="pb-2 addicon" id="add_sub_category" *ngIf="addicon " (click)="AddRemoveColumnOKR(subParent,treearray)" style="margin-right: 10px;margin-top: 10px;" >
                                                            <span  id="add_span_{{child?.prefix}}" class="material-icons-outlined">
                                                                add
                                                            </span>
                                                        </div>
                                                    </ng-container> -->
                      </div>
                    </div>
                  </div>
                  <div
                    [id]="
                      subParentTagname + '_' + treeindex + '_' + subParentindex
                    "
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="parentTagName + treeindex"
                    [attr.data-bs-parent]="parentTagName + treeindex"
                  >
                    <div class="accordion-body subParentBody pt-1">
                      <div
                        class="child"
                        *ngFor="
                          let child of subParent?.toe || subParent?.toe_details;
                          index as childIndex
                        "
                      >
                        <div class="d-flex childRow">
                          <div
                            class="pe-1 ps-1 flex-shrink-1 dotted"
                            [ngClass]="{
                              dottedNoLength:
                                subParent?.toe?.length - 1 == childIndex ||
                                subParent?.toe_details?.length - 1 == childIndex
                            }"
                          >
                            <div
                              class="formChildArrow d-flex align-items-center"
                            >
                              <span class="yellowcircle"></span>
                            </div>
                          </div>
                          <div class="pe-1 ps-1 w-100 pb-2">
                            <div class="d-flex flex-column border-form">
                              <div
                                class="childaccordion cursor-pointer"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                [attr.data-bs-target]="
                                  '#' +
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                [attr.aria-controls]="
                                  formTagName +
                                  subParentindex +
                                  '_' +
                                  childIndex +
                                  '_' +
                                  treeindex
                                "
                                (click)="
                                  measurmentForm[
                                    subParentindex + childIndex + treeindex
                                  ] =
                                    !measurmentForm[
                                      subParentindex + childIndex + treeindex
                                    ]
                                "
                              >
                                <div class="d-flex">
                                  <span class="bubble-right pt-2 pb-2"></span>
                                  <div class="w-100">
                                    <div
                                      class="d-flex flex-row justify-content-between align-items-center"
                                    >
                                      <div class="three-lines p-2">
                                        <p
                                          class="recommended-badge"
                                          *ngIf="child?.is_recomended"
                                        >
                                          <i class="fa fa-star"></i>Recommended
                                        </p>
                                        <h5
                                          class="childhead"
                                          container="body"
                                          popoverClass="darkTheme"
                                          triggers="mouseenter:mouseleave"
                                        >
                                          <span class="treeId pe-1 fw-bold">{{
                                            childIndex + 1
                                          }}</span>
                                          <span
                                            [ngbPopover]="
                                              child?.title | titlecase
                                            "
                                            container="body"
                                            popoverClass="darkTheme"
                                            triggers="mouseenter:mouseleave"
                                            >{{
                                              child?.title | titlecase
                                            }}</span
                                          >
                                        </h5>
                                      </div>

                                      <div
                                        class="d-flex flex-column justify-content-center align-items-center actionIcons"
                                      >
                                        <div
                                          class="d-flex flex-row justify-content-around"
                                        >
                                          <div
                                            class="measurementFormArrow cursor-pointer p-2 d-flex align-items-center"
                                            *ngIf="formShow"
                                          >
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                !measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_down
                                            </mat-icon>
                                            <mat-icon
                                              class="material-icons-outlined"
                                              *ngIf="
                                                measurmentForm[
                                                  subParentindex +
                                                    childIndex +
                                                    treeindex
                                                ]
                                              "
                                            >
                                              keyboard_arrow_up
                                            </mat-icon>
                                          </div>
                                          <ng-container>
                                            <!--*ngxPermissionsOnly="[treeDeletePermission]"-->
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == true &&
                                                !disableEdit
                                              "
                                              (click)="DeleteKey_Toe(child)"
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-danger cursor-pointer"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                            <div
                                              class="pb-2 deleteicon"
                                              *ngIf="
                                                deleteicon &&
                                                child?.delete == false
                                              "
                                            >
                                              <mat-icon
                                                class="material-icons-outlined text-muted cursor-pointer"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom  || auto"
                                                title="Custom Measurement Consumed Can't be Removed"
                                              >
                                                delete
                                              </mat-icon>
                                            </div>
                                          </ng-container>
                                          <ng-container>
                                            <div
                                              style="
                                                margin-top: 10px;
                                                margin-right: 10px;
                                              "
                                              class="pb-2 addicon"
                                              id="add_{{ child?.id }}"
                                              *ngIf="
                                                addicon &&
                                                treecardtype == 'formtreetoe'
                                              "
                                              (click)="
                                                AddRemoveColumnOKR(
                                                  subParent,
                                                  treearray,
                                                  child
                                                )
                                              "
                                            >
                                              <mat-icon
                                                id="add_span_{{ child?.id }}"
                                                class="material-icons-outlined"
                                              >
                                                add
                                              </mat-icon>
                                            </div>
                                            <div
                                              class="infoicon"
                                              *ngIf="
                                                treecardtype ==
                                                'formtreetoeselected'
                                              "
                                            >
                                              <mat-icon
                                                class="color-info infoicon"
                                                style="margin-right: 10px"
                                                (click)="
                                                  openToEModal(child, ToEModal)
                                                "
                                              >
                                                info</mat-icon
                                              >
                                            </div>
                                            <!-- <div class="deleteicon" *ngIf="treecardtype == 'formtreetoeselected'">
                                                                                            <mat-icon class="color-info infoicon" style="margin-right: 10px;" (click)="openToEModal(child,ToEModal)">
                                                                                                delete</mat-icon>
                                                                                        </div> -->
                                          </ng-container>
                                          <!-- <ng-container>
                                                                                        *ngxPermissionsOnly="[treeAddPermission]"
                                                                                        <div class="pb-2 addicon" id="add_{{child?.prefix}}" *ngIf="addicon && !disableEdit" (click)=AddRemoveColumn(child,treearray?.is_custom)>
                                                                                            <span  id="add_span_{{child?.prefix}}" class="material-icons-outlined">
                                                                                                add
                                                                                            </span>
                                                                                        </div>
                                                                                    </ng-container> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ng-container *ngIf="formShow && !RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse"
                                  [ngClass]="
                                    child?.hasError == true
                                      ? 'show'
                                      : 'collapse'
                                  "
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label
                                            >Milestone Phase
                                          </mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            [(ngModel)]="child.milestone"
                                            [name]="
                                              'milestone' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your MileStone Phase here"
                                            [disabled]="formInputDisable"
                                          />
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Deadline</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [(ngModel)]="child.deadline"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'deadline' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                            [disabled]="formInputDisable"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col flex-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Notes</mat-label>
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [(ngModel)]="child.notes"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [name]="
                                              'notes' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            placeholder="Type your Notes here.."
                                            [disabled]="formInputDisable"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="formShow && RiskLogform">
                                <div
                                  class="formcontainer accordion-collapse collapse"
                                  [id]="
                                    formTagName +
                                    subParentindex +
                                    '_' +
                                    childIndex +
                                    '_' +
                                    treeindex
                                  "
                                  [attr.aria-labelledby]="
                                    subParentTagname + subParentindex
                                  "
                                  [attr.data-bs-parent]="
                                    subParentTagname + subParentindex
                                  "
                                >
                                  <div class="treeForm p-2">
                                    <div class="row row-cols-2 text-capitalize">
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk Description
                                          </mat-label>
                                          <!--<input type="text" matInput
                                                                                        [(ngModel)]="child.risk_description"
                                                                                        [name]="'risk_description'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Description here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_description' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_description"
                                            (ngModelChange)="
                                              setMeasurementCriteriaId(child)
                                            "
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Description here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                        <!-- <mat-form-field
                                                                                    class="example-full-width"
                                                                                    appearance="outline">
                                                                                    <mat-label>Risk Owner</mat-label>
                                                                                    <input type="text" matInput
                                                                                        placeholder="Pick one"
                                                                                        aria-label="Number"
                                                                                        [name]="'riskowner'+treeindex+'-'+childIndex"
                                                                                        [(ngModel)]="child.risk_owner"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        [matAutocomplete]="auto">
                                                                                    <mat-autocomplete
                                                                                        [displayWith]="displayFn"
                                                                                        #auto="matAutocomplete">
                                                                                        <mat-option
                                                                                            *ngFor="let riskOwnerList of formArray"
                                                                                            [value]="riskOwnerList">
                                                                                            {{riskOwnerList.name ||
                                                                                            riskOwnerList.mail}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field> -->
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>
                                            Risk mitigation plan
                                          </mat-label>
                                          <!-- <input type="text" matInput
                                                                                        [(ngModel)]="child.risk_mitigation"
                                                                                        [name]="'risk_mitigation'+treeindex+'-'+childIndex"
                                                                                        (ngModelChange)="setMeasurementCriteriaId(child)"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        placeholder="Type your Risk Mitigation plan here"> -->
                                          <textarea
                                            type="text"
                                            matInput
                                            cdkTextareaAutosize
                                            [name]="
                                              'risk_mitigation' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.risk_mitigation"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            placeholder="Type your Risk Mitigation plan here"
                                          >
                                          </textarea>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Delegate</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Delegate"
                                            aria-label="Number"
                                            [name]="
                                              'delegate' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.delegate"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="Delegate"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #Delegate="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let delegateList of formArray?.deliveryOwnersList
                                              "
                                              [value]="delegateList"
                                            >
                                              {{
                                                delegateList.name ||
                                                  delegateList.mail
                                              }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field appearance="outline">
                                          <mat-label>Due Date</mat-label>
                                          <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [name]="
                                              'due' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.due_date"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            dateFormat="yyyy/MM/dd"
                                            (click)="picker.open()"
                                          />
                                          <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                          >
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker>
                                          </mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col felx-fill">
                                        <mat-form-field
                                          class="example-full-width"
                                          appearance="outline"
                                        >
                                          <mat-label>Status</mat-label>
                                          <input
                                            type="text"
                                            matInput
                                            placeholder="Pick Status"
                                            aria-label="Number"
                                            [name]="
                                              'status' +
                                              treeindex +
                                              '-' +
                                              childIndex
                                            "
                                            [(ngModel)]="child.status"
                                            [ngModelOptions]="{
                                              standalone: true
                                            }"
                                            [matAutocomplete]="status"
                                          />
                                          <mat-autocomplete
                                            [displayWith]="displayFn"
                                            #status="matAutocomplete"
                                          >
                                            <mat-option
                                              *ngFor="
                                                let status of formArray?.statusList
                                              "
                                              [value]="status"
                                            >
                                              {{ status.label }}
                                            </mat-option>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                      <div
                                        class="infoicon"
                                        *ngIf="isCentrePane"
                                      >
                                        <mat-icon
                                          class="color-info infoicon"
                                          (click)="
                                            openMeasureofSuccessModal(
                                              child,
                                              treearray?.is_custom,
                                              MeasureofSuccessModal,
                                              $event
                                            )
                                          "
                                        >
                                          info</mat-icon
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <ng-container>
                            <div class="p-2" *ngIf="removeicon && !disableEdit">
                              <div
                                class="removeicon"
                                (click)="
                                  AddRemoveColumn(child, treearray?.is_custom)
                                "
                              >
                                <img
                                  class="removeimg cursor-pointer"
                                  src="/assets/images/pages/tree/treecross.svg"
                                  alt="close"
                                  width="auto"
                                  height="auto"
                                />
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #MeasureofSuccessModal>
  <span class="closeIcon cursor-pointer" (click)="closeDialog()">
    <img src="./assets/images/pages/workforce/close.svg" alt="close" />
  </span>
  <section class="riskguidelines p-2 mt-2">
    <span class="subhead"
      >{{ staticText?.risk_convergence?.risk_guidelines }}
    </span>

    <ng-container *ngFor="let measure of measureofSuccessData">
      <div class="risk-efficiency p-2 pt-3 mt-3 fs-14" *ngIf="measure">
        <div
          [innerHTML]="
            measure.criteria ? measure.criteria : measure.mitigations_plan_info
          "
        ></div>
      </div>
    </ng-container>
    <div
      *ngIf="!measureofSuccessData || measureofSuccessData?.length == 0"
      class="row justify-content-center no-data"
    >
      No Data Found
    </div>
  </section>
</ng-template>

<ng-template #RiskHistoryModal>
  <div class="modal-dialog m-0">
    <div class="modal-content">
      <div class="border-top"></div>
      <div class="modal-header d-flex flex-column align-items-flex-start">
        <h2 mat-dialog-title class="fw-bold mb-0">Risk History</h2>
        <div>
          <span class="treeId pe-1 fw-bold">{{
            selectedRiskDetails?.prefix
          }}</span>
          <span
            [ngbPopover]="
              selectedRiskDetails?.measurement_criteria_display_name ||
                selectedRiskDetails?.risk_display_name | titlecase
            "
            container="body"
            popoverClass="darkTheme"
            triggers="mouseenter:mouseleave"
            >{{
              selectedRiskDetails?.measurement_criteria_display_name ||
                selectedRiskDetails?.risk_display_name | titlecase
            }}
          </span>
        </div>
      </div>
      <div class="modal-body d-flex w-100">
        <div class="d-grid w-100">
          <table class="table table-bordered fs-14 w-auto">
            <tr>
              <th class="theading text-center">Date</th>
              <th class="theading text-center">Status</th>
              <th class="theading text-center">Owner</th>
              <th class="theading text-center">Remarks</th>
            </tr>
            <tr *ngFor="let data of riskHistory">
              <td>{{ data?.actual_close_date | date : "dd-MM-yyyy" }}</td>
              <td class="input-fld">{{ data?.status }}</td>
              <td>{{ data?.owner || "N/A" }}</td>
              <td class="input-fld">{{ data?.remarks }}</td>
            </tr>
            <tr
              *ngIf="!riskHistory || riskHistory.length == 0"
              class="text-center"
            >
              <td [attr.colspan]="4">
                {{ staticText.common.no_records_found }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div>
            <button
              mat-button
              mat-dialog-close
              class="saveRisk mr-2 text-white text-capitalize float-right add-release"
            >
              {{ staticText?.buttons.close_btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #RiskMitigationModal>
  <div class="mw-550 m-0">
    <div class="modal-content">
      <div class="border-top"></div>
      <div class="modal-header d-flex flex-column align-items-flex-start">
        <h2 mat-dialog-title class="fw-bold mb-0">Risk Mitigation</h2>
        <div>
          <span class="treeId pe-1 fw-bold">{{
            selectedRiskDetails?.prefix
          }}</span>
          <span
            [ngbPopover]="
              selectedRiskDetails?.measurement_criteria_display_name ||
                selectedRiskDetails?.risk_display_name | titlecase
            "
            container="body"
            popoverClass="darkTheme"
            triggers="mouseenter:mouseleave"
            >{{
              selectedRiskDetails?.measurement_criteria_display_name ||
                selectedRiskDetails?.risk_display_name | titlecase
            }}
          </span>
        </div>
      </div>
      <div class="modal-body d-flex w-100">
        <div
          class="row row-cols-8 text-capitalize"
          [formGroup]="riskSetupFormArray"
        >
          <div
            *ngIf="mitigationList(selectedMitigatinIndex)?.controls.length > 0"
          >
            <div formArrayName="mitigationList">
              <ng-container
                *ngFor="
                  let fform of mitigationList(selectedMitigatinIndex)?.controls;
                  let i = index
                "
              >
                <div class="d-grid w-100" [formGroup]="fform">
                  <div class="col felx-fill d-flex">
                    <div
                      class="d-flex mt-8 mw-250 fs-14"
                      id="checkdiv"
                      style="margin-top: 25px; margin-right: 20px"
                    >
                      <input
                        id="checkinput"
                        formControlName="isSelected"
                        type="checkbox"
                        class="mx-2"
                        [checked]="fform.get('isSelected').value == true"
                      />
                      <div id="checkdivtitle" style="margin-top: -4px">
                        {{ toe?.title }}
                      </div>
                    </div>
                    <div class="col-5 felx-fill" id="actiontaskdiv">
                      <mat-form-field id="formfieldaction" appearance="outline">
                        <mat-label id="formfieldlableaction">
                          Action Task
                        </mat-label>
                        <input
                          id="formfieldinputaction"
                          type="text"
                          formControlName="mitigationtask"
                          matInput
                          [name]="'action_task-' + i"
                          [ngModelOptions]="{ standalone: true }"
                          placeholder="Action Task"
                          disabled
                        />
                      </mat-form-field>
                    </div>

                    <div
                      id="duedatediv"
                      class="col-4 pl-0 felx-fill d-flex align-items-center"
                      style="margin-left: 20px"
                    >
                      <mat-form-field
                        id="duedateformfield"
                        appearance="outline"
                      >
                        <mat-label id="duedateformfieldlable"
                          >Due Date</mat-label
                        >
                        <input
                          id="duedateformfieldinput"
                          matInput
                          formControlName="duedate"
                          [matDatepicker]="picker"
                          [name]="'due_date-' + i"
                          [ngModelOptions]="{ standalone: true }"
                          dateFormat="yyyy/MM/dd"
                          (click)="picker.open()"
                        />
                        <mat-datepicker-toggle
                          id="duedateformfieldtoggle"
                          matSuffix
                          [for]="picker"
                        >
                        </mat-datepicker-toggle>
                        <mat-datepicker id="duedateformfieldpicker" #picker>
                        </mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div>
            <button
              mat-button
              class="saveRisk mr-2 text-white text-capitalize float-right add-release"
              (click)="validateMitigation()"
            >
              {{ staticText?.buttons.ok_btn }}
            </button>
            <button
              mat-button
              mat-dialog-close
              class="border mr-2 text-capitalize float-right add-release"
            >
              {{ staticText?.buttons.cancel_btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #RiskDetailsModal>
  <div class="modal-dialog m-0">
    <div class="modal-content">
      <div class="border-top"></div>
      <div class="modal-header d-flex flex-column align-items-flex-start">
        <h2 mat-dialog-title class="fw-bold mb-0">Risk Details</h2>
        <div>
          <span class="treeId pe-1 fw-bold">{{
            selectedRiskDetails?.prefix
          }}</span>
          <span
            [ngbPopover]="
              selectedRiskDetails?.measurement_criteria_display_name ||
                selectedRiskDetails?.risk_display_name | titlecase
            "
            container="body"
            popoverClass="darkTheme"
            triggers="mouseenter:mouseleave"
            >{{
              selectedRiskDetails?.measurement_criteria_display_name ||
                selectedRiskDetails?.risk_display_name | titlecase
            }}
          </span>
        </div>
      </div>
      <div class="modal-body w-100">
        <div class="row">
          <div class="col-3">
            <div>Risk Impact</div>
            <div class="fw-500">
              {{ selectedRiskDetails?.risk_impact?.display_name }}
            </div>
          </div>
          <div class="col-3">
            <div>Risk Likelyhood</div>
            <div class="fw-500">{{ selectedRiskDetails?.risk_likelihood }}</div>
          </div>
          <div class="col-3">
            <div>Risk Due Date</div>
            <div class="fw-500">{{ selectedRiskDetails?.due_date }}</div>
          </div>
          <div class="col-3">
            <div>Risk Owner</div>
            <div class="fw-500">
              {{ selectedRiskDetails?.risk_owner?.full_name }}
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-3">
            <div>Risk Status</div>
            <div class="fw-500">{{ selectedRiskDetails?.status?.label }}</div>
          </div>
          <div class="col-3">
            <div>Risk Phase</div>
            <div class="fw-500">{{ selectedRiskDetails?.risk_phase }}</div>
          </div>
          <div class="col-3">
            <div>Impact Description</div>
            <div class="fw-500">
              {{ selectedRiskDetails?.impact_description }}
            </div>
          </div>
          <div class="col-3">
            <div>Risk Mitigation</div>
            <div class="fw-500">{{ selectedRiskDetails?.risk_mitigation }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div>
            <button
              mat-button
              mat-dialog-close
              class="saveRisk mr-2 text-white text-capitalize float-right add-release"
            >
              {{ staticText?.buttons.close_btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #ToEModal>
  <div class="modal-dialog m-0">
    <div class="modal-content">
      <div class="border-top"></div>
      <div class="modal-header">
        <h2 mat-dialog-title class="fw-bold mb-0">ToE Details</h2>
      </div>
      <div class="modal-body">
        <div>
          <table class="table table-responsive fs-14 w-100 d-table">
            <tr>
              <td [attr.colspan]="3" class="risk-title">
                <b>{{ selectedToeData?.title }}</b>
              </td>
            </tr>
            <tr>
              <td class="theading">Metric Name</td>
              <td class="theading">MoS Type</td>
              <td class="theading">Target Value</td>
            </tr>
            <tr *ngFor="let metric of selectedToeData?.metrics">
              <td>{{ metric?.name }}</td>
              <td>{{ metric?.mos_type_name }}</td>
              <td *ngIf="metric?.mos_type_name == 'Need Improvement'">
                {{ metric?.from_value }}-{{ metric?.to_value }}
              </td>
              <td *ngIf="metric?.mos_type_name == 'LTB'">
                {{ metric?.min_value }}
              </td>
              <td *ngIf="metric?.mos_type_name == 'HTB'">
                {{ metric?.max_value }}
              </td>
              <td *ngIf="metric?.mos_type_name == 'Binary(Adherence)'">
                {{ metric?.binary_value ? "Yes" : "No" }}
              </td>
            </tr>
            <tr
              *ngIf="
                !selectedToeData?.metrics ||
                selectedToeData?.metrics?.length == 0
              "
            >
              <td [attr.colspan]="3">
                {{ staticText.common.no_records_found }}
              </td>
            </tr>
          </table>
        </div>
        <div class="row border-top pt-3">
          <div class="col-4">
            <div class="fs-14 title-color fw-normal ms-4 mb-0">
              Applicability
            </div>
            <div class="ms-4 fs-16 text-black mb-0">
              {{
                selectedToeData?.applicability
                  | joinArrayValues : "display_name" || "N/A"
              }}
            </div>
          </div>
          <div class="col-4">
            <div class="fs-14 title-color fw-normal ms-4 mb-0">
              Consequences
            </div>
            <div class="ms-4 fs-16 text-black mb-0">
              {{
                selectedToeData?.consequences
                  | joinArrayValues : "display_name" || "N/A"
              }}
              <!-- <span *ngIf="selectedToeData?.award">Award</span>
                            <span *ngIf="selectedToeData?.discount">Discount</span>
                            <span *ngIf="selectedToeData?.penalty">Penalty</span> -->
            </div>
          </div>
          <div class="col-4">
            <div class="fs-14 title-color fw-normal ms-4 mb-0">Compliance</div>
            <div class="ms-4 fs-16 text-black mb-0">
              {{
                selectedToeData?.compliance
                  | joinArrayValues : "display_name" || "N/A"
              }}
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="fs-14 title-color fw-normal ms-4 mb-0">Evidence</div>
            <div class="ms-4 fs-16 text-black mb-0">
              {{
                selectedToeData?.evidence
                  | joinArrayValues : "display_name" || "N/A"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div>
            <button
              mat-button
              class="saveOkr text-white text-capitalize float-right add-release"
              (click)="closeDialog()"
            >
              {{ staticText?.buttons.close_btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
