import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxPermissionsService } from "ngx-permissions";
import { DataService } from "../../services/data.service";
import { LodashService } from "../../services/lodash.service";
var _ = require("lodash");
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ListComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;

  @Input() search: boolean | any;
  @Input() customkeydeleteicon: boolean = false;
  @Input() TreecardTagname: string = "goallist";
  @Input() TreecardParentTagname: string = "goallistparent";
  @Input() TreecardToeFormTagname: string = "toeFormList";
  @Input() filterList: boolean = false;
  @Input() filterListKey: string = "";
  @Input() filterListKeyValue: boolean | any;
  @Input() list: Array<any> | any;
  @Input() searchBoxText: any = "";
  @Input() patchkeyResult: any[] = [];
  @Input() patchToeRecords: any[] = [];
  @Input() disableEdit: boolean = false;

  popupevent: any;
  functionEvent: any;
  @Input() set type(data: any) {
    this.module = data;
  }
  @Input() set content(data: any) {
    this.contents = data;
  }

  @Output() getChips = new EventEmitter<{}>();
  @Output() levelSelected = new EventEmitter<{}>();
  @Output() addCustomfeild = new EventEmitter<{}>();
  @Output() levelClose = new EventEmitter<{}>();
  @Output() functionClose = new EventEmitter<{}>();
  @Output() onEdit = new EventEmitter<{}>();

  localArray: any[] = [];
  termsArray: any[] = [];
  patchArray: any[] = [];
  updateItems: any[] = [];
  items: any[] = [];

  module = "list";
  contents: any;
  searchText = "";
  showData: {} | any;
  step: any;
  showKey = false;
  customStyle = {
    backgroundColor: "#5126A2",
    borderRadius: "50%",
    color: "white",
    cursor: "pointer",
    fontWeight: "bolder",
  };
  sample: any = [
    {
      para: " If to want to input a lot of scope items manually, you can download the CSV template and import it manually.Although we recommend system suggested scope items.",
      btn: "Customer",
    },
    {
      para: " If to want to input a lot of scope items manually, you can download the CSV template and import it manually.Although we recommend system suggested scope items.",
      btn: "Customer",
    },
    {
      para: " If to want to input a lot of scope items manually, you can download the CSV template and import it manually.Although we recommend system suggested scope items.",
      btn: "Customer",
    },
    {
      para: " If to want to input a lot of scope items manually, you can download the CSV template and import it manually.Although we recommend system suggested scope items.",
      btn: "Customer",
    },
  ];

  constructor(
    private permissionsService: NgxPermissionsService,
    private dataService: DataService,
    private ref: ChangeDetectorRef,
    private lodashService: LodashService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const perm: any = JSON.parse(localStorage.getItem("permission") || "{}");
    this.permissionsService.loadPermissions(perm.permissions);
    this.items = this.list;
    this.localArray = _.cloneDeep(this.list);
    this.termsArray = _.cloneDeep(this.list);

    this.dataService.removeKeyResultItem.subscribe((res: any) => {
      if (res.key_result_uuid != null && !this.filterListKeyValue) {
        this.patchkeyresult(res);
      }
    });

    this.dataService.removeCustomKeyResultItem.subscribe((res: any) => {
      if (res.key_result_uuid != null && this.filterListKeyValue) {
        this.patchkeyresult(res);
      }
    });

    this.dataService.removeToeRecommendedItem.subscribe((res: any) => {
      if (res.measurement_criteria_uuid != null && !this.filterListKeyValue) {
        this.patchToeCategory(res);
      }
    });

    this.dataService.removeToeCustomItem.subscribe((res: any) => {
      if (res.measurement_criteria_uuid != null && this.filterListKeyValue) {
        this.patchToeCategory(res);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(this.list);

    this.localArray = _.cloneDeep(this.list);
    if (changes["list"]) {
      let currentValue: any = changes["list"]["currentValue"];
      if (!currentValue) {
        let changesGoal =
          currentValue?.filter((findGoal: any) => {
            if (findGoal && findGoal?.goal_uuid) {
              return findGoal.goal_uuid;
            }
          }) || null;
        let changesTermsCategory =
          currentValue?.filter((findTermsCategory: any) => {
            if (findTermsCategory.category_uuid) {
              return findTermsCategory.category_uuid;
            }
          }) || null;
        if (changesGoal?.length !== 0 && changesGoal !== null) {
          this.localArray = currentValue;
        } else if (
          changesTermsCategory?.length !== 0 &&
          changesTermsCategory !== null
        ) {
          this.termsArray = currentValue;
        } else {
          this.items = this.list;
        }
      } else {
        this.items = this.list;
        if (this.module == "termsList")
          this.termsArray = _.cloneDeep(this.list);
      }
    }
  }

  patchkeyresult(item: any) {
    if (this.localArray.length !== 0 || this.localArray) {
      let goalIndex = this.localArray?.findIndex(
        (goalIndex: any) => goalIndex.goal_uuid == item.strategic_goal_uuid
      );
      if (goalIndex !== -1) {
        for (var goalKeyResult of this.localArray) {
          if (goalKeyResult) {
            if (goalKeyResult.goal_uuid == item.strategic_goal_uuid) {
              let parenKeyResultIndex = goalKeyResult.key_results.findIndex(
                (parentKeyResultsIndex: any) =>
                  parentKeyResultsIndex.key_result_uuid ==
                  item.parent_key_result_uuid
              );
              if (parenKeyResultIndex !== -1) {
                goalKeyResult.key_results[
                  parenKeyResultIndex
                ].child_key_results.push(item);
                // let localChildIndex = goalKeyResult.key_results[parenKeyResultIndex].child_key_results.findIndex((localChildIndex: any) => localChildIndex.key_result_uuid == item.key_result_uuid);
                // goalKeyResult.key_results[parenKeyResultIndex].child_key_results = this.lodashService.sortUniqueBy(goalKeyResult.key_results[parenKeyResultIndex].child_key_results, goalKeyResult.key_results[parenKeyResultIndex].child_key_results[localChildIndex].key_result_uuid);
                goalKeyResult.key_results[
                  parenKeyResultIndex
                ].child_key_results = this.lodashService.orderBy(
                  goalKeyResult.key_results[parenKeyResultIndex]
                    .child_key_results,
                  "key_title",
                  "asc"
                );

                break;
              } else {
                if (this.patchkeyResult.length !== 0) {
                  for (var patchGoalKeyResult of this.patchkeyResult) {
                    if (patchGoalKeyResult) {
                      if (
                        patchGoalKeyResult.goal_uuid == item.strategic_goal_uuid
                      ) {
                        let patchParentIndex =
                          patchGoalKeyResult.key_results.findIndex(
                            (patchParentKeyResults: any) =>
                              patchParentKeyResults.key_result_uuid ==
                              item.parent_key_result_uuid
                          );
                        if (patchParentIndex !== -1) {
                          for (var patchParent of patchGoalKeyResult.key_results) {
                            let patchParentclone =
                              this.lodashService.Clonedata(patchParent);
                            if (
                              patchParent.key_result_uuid ==
                              item.parent_key_result_uuid
                            ) {
                              patchParentclone = patchParent;
                              patchParentclone.child_key_results = [];

                              for (var patchLocalArray of this.localArray) {
                                if (
                                  patchLocalArray.goal_uuid ==
                                  item.strategic_goal_uuid
                                ) {
                                  patchLocalArray.key_results.push(
                                    patchParentclone
                                  );
                                  patchParentclone.child_key_results.push(item);
                                  // let patchChildIndex = patchLocalArray.key_results[patchParentIndex].child_key_results.findIndex((patchChildIndex: any) => patchChildIndex.key_result_uuid == item.key_result_uuid);
                                  // patchParentclone.child_key_results = this.lodashService.sortUniqueBy(patchParentclone.child_key_results, patchLocalArray.key_results[patchParentIndex].child_key_results[patchChildIndex].key_result_uuid);
                                  patchLocalArray.key_results =
                                    this.lodashService.orderBy(
                                      patchLocalArray.key_results,
                                      "key_title",
                                      "asc"
                                    );
                                  break;
                                }
                              }
                              break;
                            }
                          }
                        }
                        break;
                      }
                    }
                  }
                }
                break;
              }
            }
          }
        }
      } else {
        if (this.patchkeyResult.length !== 0) {
          for (var patchNewGoal of this.patchkeyResult) {
            if (patchNewGoal) {
              if (patchNewGoal.goal_uuid == item.strategic_goal_uuid) {
                let patchNewGoalParentIndex =
                  patchNewGoal.key_results.findIndex(
                    (patchNewGoalParentKeyResults: any) =>
                      patchNewGoalParentKeyResults.key_result_uuid ==
                      item.parent_key_result_uuid
                  );
                if (patchNewGoalParentIndex !== -1) {
                  let patchNewGoalclone =
                    this.lodashService.Clonedata(patchNewGoal);
                  let patchNewGoalParent = patchNewGoal.key_results.filter(
                    (patchNewGoalparentdata: any) =>
                      patchNewGoalparentdata.key_result_uuid ==
                      item.parent_key_result_uuid
                  );
                  if (patchNewGoal.goal_uuid == item.strategic_goal_uuid) {
                    patchNewGoalclone.key_results = patchNewGoalParent;
                    patchNewGoalclone.key_results[
                      patchNewGoalParentIndex
                    ].child_key_results = [];
                    patchNewGoalclone.key_results[
                      patchNewGoalParentIndex
                    ].child_key_results.push(item);
                    let patchNewChildIndex = patchNewGoalclone.key_results[
                      patchNewGoalParentIndex
                    ].child_key_results.findIndex(
                      (patchNewChildIndex: any) =>
                        patchNewChildIndex.key_result_uuid ==
                        item.key_result_uuid
                    );
                    patchNewGoalclone.key_results[
                      patchNewGoalParentIndex
                    ].child_key_results = this.lodashService.sortUniqueBy(
                      patchNewGoalclone.key_results[patchNewGoalParentIndex]
                        .child_key_results,
                      patchNewGoalclone.key_results[patchNewGoalParentIndex]
                        .child_key_results[patchNewChildIndex].key_result_uuid
                    );
                    this.localArray.push(patchNewGoalclone);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //-------------------------Patch ToE------------------------

  patchToeCategory(item: any) {
    if (this.termsArray && this.termsArray?.length !== 0) {
      let categoryIndex = this.termsArray?.findIndex(
        (categoryIndx: any) => categoryIndx?.category_uuid == item.category_uuid
      );
      if (categoryIndex !== -1) {
        for (var categorydata of this.termsArray) {
          if (categorydata) {
            if (categorydata?.category_uuid == item.category_uuid) {
              let parenKeyResultIndex =
                categorydata.term_sub_category.findIndex(
                  (parentKeyResultsIndex: any) =>
                    parentKeyResultsIndex.sub_category_uuid ==
                    item.sub_category_uuid
                );
              if (parenKeyResultIndex !== -1) {
                categorydata.term_sub_category[
                  parenKeyResultIndex
                ].term_measurement_criteria.push(item);
                // categorydata.term_sub_category[parenKeyResultIndex].term_measurement_criteria = this.lodashService.orderBy(categorydata.term_sub_category[parenKeyResultIndex].term_measurement_criteria, "key_result_title", "asc");
                break;
              } else {
                if (this.patchToeRecords.length !== 0) {
                  for (var patchCategory of this.patchToeRecords) {
                    if (patchCategory) {
                      if (patchCategory?.category_uuid == item.category_uuid) {
                        let patchParentIndex =
                          patchCategory.term_sub_category.findIndex(
                            (patchParentKeyResults: any) =>
                              patchParentKeyResults.sub_category_uuid ==
                              item.sub_category_uuid
                          );
                        if (patchParentIndex !== -1) {
                          for (var patchParent of patchCategory.term_sub_category) {
                            let patchParentclone =
                              this.lodashService.Clonedata(patchParent);
                            if (
                              patchParent.sub_category_uuid ==
                              item.sub_category_uuid
                            ) {
                              patchParentclone = patchParent;
                              patchParentclone.term_measurement_criteria = [];
                              for (var patchLocalArray of this.termsArray) {
                                if (
                                  patchLocalArray.category_uuid ==
                                  item.category_uuid
                                ) {
                                  patchLocalArray.term_sub_category.push(
                                    patchParentclone
                                  );
                                  patchParentclone.term_measurement_criteria.push(
                                    item
                                  );
                                  // patchLocalArray.term_sub_category = this.lodashService.orderBy(patchLocalArray.term_sub_category, "key_result_title", "asc");
                                  break;
                                }
                              }
                              break;
                            }
                          }
                        }
                        break;
                      }
                    }
                  }
                }
                break;
              }
            }
          }
        }
      } else {
        if (this.patchToeRecords.length !== 0) {
          for (var patchNewCategory of this.patchToeRecords) {
            if (patchNewCategory) {
              if (patchNewCategory.category_uuid == item.category_uuid) {
                let patchNewSubCategoryIndex =
                  patchNewCategory.term_sub_category.findIndex(
                    (patchNewCategoryParentKeyResults: any) =>
                      patchNewCategoryParentKeyResults.sub_category_uuid ==
                      item.sub_category_uuid
                  );
                if (patchNewSubCategoryIndex !== -1) {
                  let patchNewCategoryclone =
                    this.lodashService.Clonedata(patchNewCategory);
                  let patchNewCategoryParent =
                    patchNewCategory.term_sub_category.filter(
                      (patchNewCategoryparentdata: any) =>
                        patchNewCategoryparentdata.sub_category_uuid ==
                        item.sub_category_uuid
                    );
                  if (patchNewCategory.category_uuid == item.category_uuid) {
                    patchNewCategoryclone.term_sub_category =
                      patchNewCategoryParent;
                    patchNewCategoryclone.term_sub_category[
                      patchNewSubCategoryIndex
                    ].term_measurement_criteria = [];
                    patchNewCategoryclone.term_sub_category[
                      patchNewSubCategoryIndex
                    ].term_measurement_criteria.push(item);
                    let patchNewChildIndex =
                      patchNewCategoryclone.term_sub_category[
                        patchNewSubCategoryIndex
                      ].term_measurement_criteria.findIndex(
                        (patchNewChildIndex: any) =>
                          patchNewChildIndex.measurement_criteria_uuid ==
                          item.measurement_criteria_uuid
                      );
                    patchNewCategoryclone.term_sub_category[
                      patchNewSubCategoryIndex
                    ].term_measurement_criteria =
                      this.lodashService.sortUniqueBy(
                        patchNewCategoryclone.term_sub_category[
                          patchNewSubCategoryIndex
                        ].term_measurement_criteria,
                        patchNewCategoryclone.term_sub_category[
                          patchNewSubCategoryIndex
                        ].term_measurement_criteria[patchNewChildIndex]
                          .measurement_criteria_uuid
                      );
                    this.termsArray.push(patchNewCategoryclone);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  addColumn(data?: any) {
    this.levelSelected.emit(data);
    var BreakException = {};
    try {
      let goalIndex = this.localArray.findIndex(
        (goalIndex: any) => goalIndex.goal_uuid == data.strategic_goal_uuid
      );
      this.localArray.filter((goal: any) => {
        if (goal.goal_uuid == data.strategic_goal_uuid) {
          goal?.key_results?.forEach((parent: any, parentindex: number) => {
            if (parent.child_key_results.length != 0 || null) {
              if (parent.key_result_uuid == data.parent_key_result_uuid) {
                for (let j = 0; j < parent.child_key_results.length; j++) {
                  let childKeyResultIndex = parent.child_key_results.findIndex(
                    (child: any) =>
                      child.key_result_uuid == data.key_result_uuid
                  );
                  parent.child_key_results.splice(childKeyResultIndex, 1);
                  if (
                    parent.child_key_results.length == 0 &&
                    goal.key_results.length == 0
                  ) {
                    this.localArray.splice(goalIndex, 1);
                  }
                  if (parent.child_key_results.length == 0) {
                    goal.key_results.splice(parentindex, 1);
                    if (goal.key_results.length == 0) {
                      this.localArray.splice(goalIndex, 1);
                    }
                    let parentIndex = goal.key_results.findIndex(
                      (sliceParentIndex: any) =>
                        sliceParentIndex.key_result_uuid ==
                        data.parent_key_result_uuid
                    );
                    if (parentIndex !== -1) {
                      goal.key_results.splice(parentIndex, 1);
                    }
                  }
                  break;
                }
              } else {
                if (
                  parent.child_key_results.length == 0 &&
                  goal.key_results.length == 0
                ) {
                  this.localArray.splice(goalIndex, 1);
                }
              }
            }
          });
        }
      });
    } catch (error) {
      if (error !== BreakException) throw error;
    }
  }

  addToeMeasurement(data?: any) {
    //-----------------Delivery Obligations ----------------------------//
    this.levelSelected.emit(data);
    var BreakException = {};
    try {
      let categoryIndex = this.termsArray.findIndex(
        (categoryIndex: any) =>
          categoryIndex.category_uuid == data.category_uuid
      );
      this.termsArray.filter((category: any) => {
        if (category?.category_uuid == data?.category_uuid) {
          category.term_sub_category.forEach(
            (subCategory: any, subCategoryindex: number) => {
              if (subCategory?.term_measurement_criteria?.length != 0 || null) {
                if (subCategory?.sub_category_uuid == data?.sub_category_uuid) {
                  for (
                    let j = 0;
                    j < subCategory.term_measurement_criteria.length;
                    j++
                  ) {
                    let childKeyResultIndex =
                      subCategory?.term_measurement_criteria.findIndex(
                        (child: any) =>
                          child.measurement_criteria_uuid ==
                          data.measurement_criteria_uuid
                      );
                    subCategory?.term_measurement_criteria.splice(
                      childKeyResultIndex,
                      1
                    );
                    if (
                      subCategory?.term_measurement_criteria?.length == 0 &&
                      category?.term_sub_category?.length == 0
                    ) {
                      this.termsArray.splice(categoryIndex, 1);
                    }
                    if (subCategory?.term_measurement_criteria?.length == 0) {
                      category?.term_sub_category.splice(subCategoryindex, 1);
                      if (category?.term_sub_category?.length == 0) {
                        this.termsArray.splice(categoryIndex, 1);
                      }
                      let parentIndex = category?.term_sub_category.findIndex(
                        (sliceParentIndex: any) =>
                          sliceParentIndex?.sub_category_uuid ==
                          data.sub_category_uuid
                      );
                      if (parentIndex !== -1) {
                        category?.term_sub_category.splice(parentIndex, 1);
                      }
                    }
                    break;
                  }
                } else {
                  if (
                    subCategory?.term_measurement_criteria?.length == 0 &&
                    category?.term_sub_category?.length == 0
                  ) {
                    this.termsArray.splice(categoryIndex, 1);
                  }
                }
              }
            }
          );
        }
      });
    } catch (error) {
      if (error !== BreakException) throw error;
    }
  }

  closeColumn() {
    this.levelClose.emit(this.popupevent);
    this.dialog.closeAll();
  }

  callPopUp(i: any, removeCategory: any) {
    this.popupevent = i;
    this.dialog.open(removeCategory);
  }

  callFunctionPopUp(i: any, removeFunction: any) {
    this.functionEvent = i;
    this.dialog.open(removeFunction);
  }

  deleteFunction() {
    this.functionClose.emit(this.functionEvent);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onInputChange(e: any) {
    const searchText = e.target.value;
    if (!!searchText) {
      this.searchText.toLowerCase();
      setTimeout(() => {
        this.items = this.items.filter((e) =>
          e.name.toLowerCase().includes(this.searchText)
        );
      }, 200);
    } else {
      this.items = this.updateItems;
    }
  }
  addCustom() {
    this.addCustomfeild.emit(true);
  }

  opened(item: any) {
    this.step = item;
    this.showKey = true;
  }
  closed(item: any) {
    this.step = item;
    this.showKey = false;
  }

  getShortName(fullName: any) {
    return fullName
      ?.split(" ")
      ?.slice(0, 2)
      ?.map((n: any) => n[0])
      .join("");
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    //
    this.items = [];
    this.localArray = [];
    this.termsArray = [];
    this.dataService.removeKeyMeasurmentMethod({});
    this.dataService.removeCustomKeyMeasurmentMethod({});
    this.dataService.patchToeCategory({});
    this.dataService.patchCustomToeCategory({});
  }
}
