import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScorecardService {
  constructor(private http: HttpClient) { }
  private messageSource = new BehaviorSubject<any>([]);
  cardDetails = this.messageSource.asObservable();

  private okrSource = new BehaviorSubject<any>([]);
  okrDetails$ = this.okrSource.asObservable();

  private riskSource = new BehaviorSubject<any>([]);
  riskDetails$ = this.riskSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  okrSpread(data: any) {
    this.okrSource.next(data);
  }

  riskSpread(data: any) {
    this.riskSource.next(data);
  }

  getUpcomingReleases() {
    return this.http.get("delivery/upcoming_release?");
  }

  getRecentReleases() {
    return this.http.get("delivery/current_release?");
  }

  getFilteredUpcomingReleases(
    portofolio_id: any,
    subportfolio_id: any,
    program_id: any,
    vendor_id: any
  ) {
    return this.http.get(
      `delivery/upcoming_release?program_id=${program_id}&portfolio_id=${portofolio_id}&sub_portfolio_id=${subportfolio_id}&vendor=${vendor_id}`
    );
  }

  getFilteredRecentReleases(
    portofolio_id: any,
    subportfolio_id: any,
    program_id: any,
    vendor_id: any
  ) {
    return this.http.get(
      `delivery/current_release?program_id=${program_id}&portfolio_id=${portofolio_id}&sub_portfolio_id=${subportfolio_id}&vendor=${vendor_id}`
    );
  }

  getRiskDetails(filterString = '') {
    return this.http.get("delivery/risk-data/project-risk-portfolio/?" + filterString);
  }

  getFilteredRiskDetails(
    portfolioId: any,
    subportfolioId: any,
    programId: any,
    vendorId: any
  ) {
    let qprm = "";
    if (portfolioId) {
      qprm = "portfolio=" + portfolioId;
    } else if (subportfolioId) {
      qprm = "subportfolio=" + subportfolioId;
    } else if (programId) {
      qprm = "program=" + programId;
    } else if (vendorId) {
      qprm = "vendor=" + vendorId;
    }
    return this.http.get(`delivery/risk-data/project-risk-portfolio/?${qprm}`);
  }

  getWhatIfData(metricId: any) {
    return this.http.get(`metrics/derived-metrics/?metric=${metricId}`);
  }

  getMetricsList() {
    return this.http.get("metrics/independent-matric/");
  }

  getPortfolioInfo(portfolioId: any, riskId: any) {
    return this.http.get(
      `delivery/risk-data/project-risk-subportfolio/${portfolioId}/?risk=${riskId}`
    );
  }

  getGoalInfo(portfolioId: any, riskId: any) {
    return this.http.get(
      `delivery/risk-data/project-goal-data/${portfolioId}/?risk=${riskId}`
    );
  }
}
