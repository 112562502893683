import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-tree-listing",
  templateUrl: "./tree-listing.component.html",
  styleUrls: ["./tree-listing.component.scss"],
})
export class TreeListingComponent implements OnInit {
  @Input() items: any = [];
  @Input() tree: any;
  @Input() context: any;
  @Input() rightpane: boolean = false;
  @Input() filterArray: any = null;

  loading: boolean = false;
  user: any;

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") || "{}");
    this.expandAccordions();
  }

  async expandAccordions() {
    for (const portfolio of this.items) {
      if (portfolio.children) {
        const shouldExpandPortfolio =
          this.filterArray.subportfolio?.some((subId: number) =>
            portfolio.children?.some(
              (subportfolio: any) => subportfolio.id === subId
            )
          ) ||
          this.filterArray.program?.some((programId: number) =>
            portfolio.children?.some((subportfolio: any) =>
              subportfolio.children?.some(
                (program: any) => program.id === programId
              )
            )
          ) ||
          this.filterArray.project?.some((projectId: number) =>
            portfolio.children?.some((subportfolio: any) =>
              subportfolio.children?.some((program: any) =>
                program.children?.some(
                  (project: any) => project.id === projectId
                )
              )
            )
          ) ||
          this.filterArray.opportunity?.some((opportunityId: number) =>
            portfolio.children?.some((subportfolio: any) =>
              subportfolio.children?.some((program: any) =>
                program.children?.some(
                  (opportunity: any) => opportunity.id === opportunityId
                )
              )
            )
          ) ||
          this.filterArray.vendor?.length ||
          this.filterArray.status?.length ||
          this.filterArray.tags?.length;

        if (shouldExpandPortfolio) {
          portfolio.expanded = true;
          await this.toggleAccordion(portfolio, true);

          for (const subportfolio of portfolio.children) {
            const shouldExpandSubportfolio =
              this.filterArray.subportfolio?.includes(subportfolio.id) ||
              this.filterArray.program?.some((programId: number) =>
                subportfolio.children.some(
                  (program: any) => program.id === programId
                )
              ) ||
              this.filterArray.project?.some((projectId: number) =>
                subportfolio.children.some((program: any) =>
                  program.children.some(
                    (project: any) => project.id === projectId
                  )
                )
              ) ||
              this.filterArray.opportunity?.some((opportunityId: number) =>
                subportfolio.children.some((program: any) =>
                  program.children.some(
                    (opportunity: any) => opportunity.id === opportunityId
                  )
                )
              ) ||
              this.filterArray.vendor?.length ||
              this.filterArray.status?.length ||
              this.filterArray.tags?.length;

            if (shouldExpandSubportfolio) {
              subportfolio.expanded = true;
              await this.toggleAccordion(subportfolio, true);

              for (const program of subportfolio.children) {
                const shouldExpandProgram =
                  this.filterArray.project?.some((projectId: number) =>
                    program.children.some(
                      (project: any) => project.id === projectId
                    )
                  ) ||
                  this.filterArray.opportunity?.some((opportunityId: number) =>
                    program.children.some(
                      (opportunity: any) => opportunity.id === opportunityId
                    )
                  ) ||
                  this.filterArray.vendor?.length ||
                  this.filterArray.status?.length ||
                  this.filterArray.tags?.length;

                if (shouldExpandProgram) {
                  program.expanded = true;
                  await this.toggleAccordion(program, true);

                  for (const project of program.children) {
                    if (
                      this.filterArray.project?.includes(project.id) ||
                      this.filterArray.opportunity?.includes(project.id) ||
                      this.filterArray.vendor?.length ||
                      this.filterArray.status?.length ||
                      this.filterArray.tags?.length
                    ) {
                      project.expanded = true;
                      await this.toggleAccordion(project, true);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  async toggleAccordion(item: any, initialExpand: boolean = false) {
    if (!initialExpand) {
      item.expanded = !item.expanded;
    }

    if (item.expanded || initialExpand) {
      this.context.componentParent.collapse(item);

      this.loading = true;
      const response = await this.context.componentParent.getLevel(item);
      this.loading = false;
    }
  }

  getColor(item: any) {
    if (item.level == "portfolio" || item.level == "Level 1") {
      return "#0E2954";
    } else if (item.level == "sub_portfolio") {
      return "#1F6E8C";
    } else if (item.level == "program") {
      return "#2E8A99";
    } else if (item.level == "last") {
      return "rgb(224 224 224)";
    } else {
      return "";
    }
  }

  openUrl(item: any, event?: any) {
    event.preventDefault();

    console.log(item);
    this.context.componentParent.navigateTo(item);
  }

  deleteItem(item: any) {
    this.context.componentParent.deleteItem(item);
  }

  archiveItem(item: any) {
    this.context.componentParent.archiveItem(item);
  }

  archiveList(item: any) {
    this.context.componentParent.archiveList(item);
  }
}
