import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ProjectService } from "src/app/shared/services/project.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe, formatDate } from "@angular/common";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-risklog-heatmap",
  templateUrl: "./risklog-heatmap.component.html",
  styleUrls: ["./risklog-heatmap.component.scss"],
  providers: [DatePipe],
})
export class RisklogHeatmapComponent implements OnInit {
  @Input() riskArrayHeatMap: any;
  @Input() projectId: any;
  @Input() iframeEmbedded: boolean = false;
  riskMetric: any;
  riskMetricCreateForm: FormGroup | any;
  riskMetricAcceptCreateForm: FormGroup | any;
  riskMetricTranferCreateForm: FormGroup | any;
  riskOwners: any;
  user: any;

  constructor(
    public fb: FormBuilder,
    private projectService: ProjectService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("permission") || "{}");
    this.getRiskOwners();
    this.riskMetricCreateForm = this.fb.array([]);
    this.riskMetricAcceptCreateForm = this.fb.group({
      comments: [""],
    });
    this.riskMetricTranferCreateForm = this.fb.group({
      comments: [""],
      transfer_to: [""],
    });
  }

  getRiskOwners() {
    this.projectService.getRiskOwners().subscribe((getRiskOwner: any) => {
      this.riskOwners = getRiskOwner.risk_owners;
    });
  }

  getFilteredArray(array: any) {
    return array != undefined
      ? array.filter((e: any) => e.selected == true)
      : [];
  }

  getTrucatedName(subdomain: string, name: string) {
    if (!name) {
      return "NA";
    } else {
      if (name.length > subdomain.length) {
        return (
          name.substring(
            0,
            subdomain.length - 1 > 10 ? subdomain.length - 1 : 10
          ) + "..."
        );
      } else {
        return name;
      }
    }
  }

  onClickRisk(domain: any, sub: any, risk: any, modal: any) {
    if (this.user?.is_vendor || this.iframeEmbedded) return;
    this.riskMetric = {
      domain: domain.name,
      subdomain: sub.name,
      risk: risk.name,
      risk_id: risk.uuid,
      risk_response: "",
    };
    this.spinner.show();
    this.riskMetricCreateForm.clear();
    this.projectService
      .getRiskProjectMapped(this.projectId, this.riskMetric.risk_id)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.riskMetric.risk_response = res.risk_response;
          if (this.riskMetric.risk_response == "Mitigate") {
            res.risk_mitigation.forEach((element: any) => {
              this.riskMetricCreateForm.push(
                this.fb.group({
                  mitigation_task: [element.risk_mitigation.action_tasks],
                  due_date: [element.planned_end_date],
                  status: [element.status],
                  comments: [element.remarks],
                  owner: element.owner ? [element.owner.email] : null,
                })
              );
            });
          }

          if (this.riskMetric.risk_response == "Accept") {
            this.riskMetricAcceptCreateForm = this.fb.group({
              comments: [res.comments],
            });
          }

          if (this.riskMetric.risk_response == "Transfer") {
            this.riskMetricTranferCreateForm = this.fb.group({
              comments: [res.comments],
              transfer_to: [res.risk_transfer.email],
            });
          }
        }
        this.dialog.open(modal, {
          width: "80vw",
          maxHeight: "70vh",
        });
      });
  }

  addMetricItems() {
    this.riskMetricCreateForm.push(
      this.fb.group({
        mitigation_task: [""],
        due_date: [""],
        status: [""],
        comments: [""],
        owner: [""],
      })
    );
  }

  removeMetric(index: any) {
    this.riskMetricCreateForm.removeAt(index);
  }

  updateRiskStatus(riskId: any, res: any) {
    let statusUpdated = false;
    for (let domain of this.riskArrayHeatMap) {
      for (let subdomain of domain.sub_domains) {
        let risk = subdomain.risks.find((item: any) => item.uuid === riskId);
        if (risk) {
          risk.status = res.risk_response;
          statusUpdated = true;
          break;
        }
      }
      if (statusUpdated) {
        break;
      }
    }
  }

  saveMetric() {
    let postdata;
    if (this.riskMetric.risk_response == "Mitigate") {
      postdata = this.riskMetricCreateForm.value;
      postdata.forEach((element: any) => {
        element.due_date = this.datepipe.transform(
          element.due_date,
          "yyyy-MM-dd"
        );
      });
    }
    if (this.riskMetric.risk_response == "Accept") {
      postdata = this.riskMetricAcceptCreateForm.value;
    }
    if (this.riskMetric.risk_response == "Transfer") {
      postdata = this.riskMetricTranferCreateForm.value;
    }
    this.spinner.show();
    let queryParam = `?risk_id=${this.riskMetric.risk_id}&risk_response=${this.riskMetric.risk_response}&project_id=${this.projectId}`;
    this.projectService
      .setRiskResponse(queryParam, postdata)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.updateRiskStatus(this.riskMetric.risk_id, res);
        this.toastrService.success("Risk updated successfully");
      });
  }
}
