<h6 *ngIf="display_header == true">Curated from the Web</h6>
<div class="gnhold">
  <app-loading *ngIf="loading"></app-loading>
  <h6 style="font-size: 14px; line-height: 1.5">{{ query_string }}</h6>
  <ul class="crawl" [style.maxHeight]="isExpanded ? '60vh' : 'none'">
    <li *ngFor="let data of llmformattedresponse">
      <p
        [innerHTML]="data"
        class="mb-1"
        style="font-size: 13px; font-style: italic"
      ></p>
    </li>
  </ul>
  <div class="text-center" *ngIf="llmformattedresponse">
    <!-- <button (click)="isExpanded = isExpanded ? false : true" class="view-more-btn">View 
            <span *ngIf="isExpanded"> less </span>
            <span *ngIf="!isExpanded"> more </span>
        </button> -->
  </div>
  <div class="mt-2" style="overflow-y: scroll; max-height: 30vh">
    <a
      class="mb-1 slinks"
      target="_blank"
      href="{{ data.url }}"
      *ngFor="let data of llmlinks; let i = index"
    >
      <p
        style="font-size: 12px; height: 40px; overflow: hidden; color: black"
        class="m-0 mb-1"
      >
        {{ data.name }}
      </p>
      <a href="{{ data.url }}" target="_blank" class="hurl"
        ><span class="citation-links">{{ i + 1 }}</span> {{ data.url }}
      </a>
    </a>
  </div>
</div>

<!-- <div *ngFor="let data of browseAIData">
                <div class="browseai-card" [ngStyle]="{'background': data.color}" >
                    <div class="d-flex justify-content-between">
                        <h6 style="font-size:14px">{{data?.['title']}}</h6>
                        <div class="text-right">                            
                            <a (click)="openNewWindow(data?.['url'])"><mat-icon class="browseai-card-link" >link</mat-icon></a>
                        </div>
                    </div>
                    <p class="browseai-card-desc">{{data?.['description']}}</p>
                    <p class="browseai-card-details d-flex mt-2">
                        <mat-icon style="font-size:14px;height: 24px;width:24px;">date_range</mat-icon> 
                        <span>{{data?.['published_date'] | date:'yyyy-MMM-dd' }}</span> 
                    </p>
                    
                </div>
            </div>   -->
