import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { OkrConvergenceService } from "src/app/shared/services/okr-convergence.service";
import { OkrDialogComponent } from "../../okr-dialog/okr-dialog.component";

@Component({
  selector: "app-okr-listing",
  templateUrl: "./okr-listing.component.html",
  styleUrls: ["./okr-listing.component.scss"],
})
export class OkrListingComponent implements OnInit {
  @Input() OKRList: any;
  @Input() showOnlyObjectives: boolean = false;
  @Input() currentObjective: any = null;
  @Input() showMonths: boolean = false;
  tableColumns = [
    "#",
    "MOS",
    "Value",
    "Target Value",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  mosList: any;
  constructor(
    private okrConvergenceService: OkrConvergenceService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.currentObjective) {
      this.autoScroll();
    }
  }

  autoScroll() {
    setTimeout(() => {
      const dialogElement: any =
        document.getElementsByClassName("okr-dialog")[0];
      const keplerElement = dialogElement.querySelector(
        `#obj-${this.currentObjective.id}`
      ) as HTMLElement;
      if (keplerElement) {
        keplerElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 1000);
  }

  getMos() {
    this.okrConvergenceService.getMosMaster().subscribe((res: any) => {
      this.mosList = res;
    });
  }
  getMosInfo(item: any) {
    return this.mosList.filter((e: any) => e.id === item)[0];
  }

  showDetailedDialog(objective: any) {
    if (this.showOnlyObjectives) {
      const dialog = this.dialog.open(OkrDialogComponent, {
        width: "80vw",
        data: {
          okrData: this.OKRList,
          currentObjective: objective,
        },
      });
    }
  }
}
