import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  map,
  startWith
} from "rxjs/operators";
import { CommonService } from "../../services/common.service";
import { FormValidatorService } from "../../services/form-validator/form-validator.service";
import { ProjectService } from "../../services/project.service";
@Component({
  selector: "app-tabmenu",
  templateUrl: "./tabmenu.component.html",
  styleUrls: ["./tabmenu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TabmenuComponent implements OnInit {
  @ViewChild("tabcontent", { read: TemplateRef }) tabcontent:
    | TemplateRef<any>
    | any;
  @Input() tabcontentdynamic: any;
  @Input() subportfolio: any;
  @Input() status: any;
  @Input() screen_name: any;
  @Input() initiative: Array<any> | any;
  @Input() benefits: Array<any> | any;
  @Input() actionCategory: Array<any> | any;
  @Input() function: Array<any> | any;
  @Input() projectList: Array<any> | any;
  @Input() GDPList: Array<any> | any;
  @Input() showProjectFirst: any;
  @Input() carouselSpace: any;
  @Input() showOpportunity = false;
  @Input() showProjectFilters: any;
  @Output() portfolio = new EventEmitter<any>();
  @Output() statusValue = new EventEmitter<any>();
  @Output() subportfolioId = new EventEmitter<any>();
  @Output() programId = new EventEmitter<any>();

  @Output() ProjectId = new EventEmitter<any>();
  @Output() GPDId = new EventEmitter<any>();
  @Output() BenefitsId = new EventEmitter<any>();
  @Output() InitiativeId = new EventEmitter<any>();
  @Output() subportfolioSelectedValue = new EventEmitter<any>();
  @Output() clearFilterData = new EventEmitter<any>();
  @Output() functionId = new EventEmitter<any>();
  @Output() actionId = new EventEmitter<any>();
  @Output() onSelectOpportunity = new EventEmitter<any>();
  @Output() onFilterOptionsChanged = new EventEmitter<any>();

  @Output() onSearchProject = new EventEmitter<any>();

  show: boolean = false;

  filterSubPortfoliolist: any;
  searchSubPortCtrl = new FormControl("");
  filterInitiativeTypeList: any;
  searchInitiativeTypeCtrl = new FormControl("");
  filterProjectList: any;
  searchProjectCtrl = new FormControl("");
  searchGDPCtrl = new FormControl("");
  searchOppCtrl = new FormControl("");
  filterGDPOptions: any;
  filterActionCategory: any;
  searchActionCategory = new FormControl("");
  filterStatus: any;
  searchStatus = new FormControl("");
  subPortFolioChild: any[] = [];

  balancescore: any = [
    { name: "portfolio", icon: "business_center" },
    { name: "delivery partner", icon: "handshake" },
    { name: "delivery partner", icon: "handshake" },
  ];
  status_values: any = [
    {
      display_name: "Open",
    },
    {
      display_name: "Initiated",
    },
    {
      display_name: "Partially Closed",
    },
    {
      display_name: "Closed",
    },
  ];

  portfoliodata: any;
  subportfoliodata: any;
  statusId: any;
  filterbutton = "show";
  clearcarousel: object | any;

  parentForm: FormGroup;

  selectedSubPortFolio: any;
  selectedProgram: any;
  displayLabels: any;

  programList: any[] = [];
  constructor(
    private projectService: ProjectService,
    private vref: ViewContainerRef,
    private fb: FormBuilder,
    private _formValidator: FormValidatorService,
    private commonService: CommonService
  ) {
    this.parentForm = this.fb.group({
      subportfolio1: ["", [this._formValidator.autocompleteObjectValidator()]],
      project: ["", [this._formValidator.autocompleteObjectValidator()]],
      action_category: [
        "",
        [this._formValidator.autocompleteObjectValidator()],
      ],
      function: ["", [this._formValidator.autocompleteObjectValidator()]],
      initiative_type: [
        "",
        [this._formValidator.autocompleteObjectValidator()],
      ],
      benefits: ["", [this._formValidator.autocompleteObjectValidator()]],
      status: ["", [this._formValidator.autocompleteObjectValidator()]],
      gdp: ["", [this._formValidator.autocompleteObjectValidator()]],
      opportunity: [""],
      projectName: [""],
      projectId: [""],
      programName: ["", [this._formValidator.autocompleteObjectValidator()]],
      vendor: [""],
    });
  }

  automenu1: any = [
    { name: "sub portfolio/program", iconleft: false },
    { name: "application modernization", iconleft: false },
  ];

  automenu2: any = [
    { name: "Delivery Partner", iconleft: true },
    { name: "Portfolio", iconleft: true },
    { name: "Sub portfolio/Program", iconleft: true },
    { name: "Projects", iconleft: true },
  ];

  ngOnInit(): void {
    this.getDisplayLables();
    this.filterDatas();
    /*    console.log('scree_name' , this.scree_name); */
    this.searchProject();
  }

  getDisplayLables() {
    this.displayLabels = this.commonService.getLabels();
  }

  autocomplete() {
    this.show = !this.show;
  }

  clearFilter() {
    this.clearcarousel = {
      background: "#d5d6ff85",
      color: "#000b3e",
    };
    this.show = !this.show;
    this.parentForm.reset();
    this.clearFilterData.emit();
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : "";
  }
  displayNameFn(data: any) {
    return data && data.name ? data.name : "";
  }
  displayProjectFn(data: any) {
    console.log(data);

    return data && data.project_name ? data.project_name : "";
  }

  tabmenu(menu: any) {
    let p = "tabcontents";
  }

  selectedPortfolioValues(data: any) {
    this.filterbutton = "hide";
    this.portfoliodata = data;
    if (!data) {
      this.show = false;
      this.clearFilterData.emit();
    }
    this.portfolio.emit(this.portfoliodata);
    this.parentForm.reset();
    this.subPortFolioChild = [];
    Object.keys(this.parentForm.controls).forEach((control: any) => {
      if (control.includes("sub_portfolio_child")) {
        this.parentForm.removeControl(control);
      }
    });
    this.onFilterOptionChanged(this.portfoliodata);
  }

  onFilterOptionChanged(
    portfolio?: any,
    subPortfolio?: any,
    program?: any,
    projectId?: any
  ) {
    if (projectId) {
      this.onFilterOptionsChanged.emit({
        portfolio: portfolio,
        subPortfolio: subPortfolio,
        program: program,
        projectId: projectId,
      });
    } else if (program) {
      this.onFilterOptionsChanged.emit({
        portfolio: portfolio,
        subPortfolio: subPortfolio,
        program: program,
        projectId: undefined,
      });
      this.parentForm.patchValue({ projectId: "" });

      this.parentForm.updateValueAndValidity();
    } else if (subPortfolio) {
      this.programList = [];
      this.parentForm.patchValue({ programName: "", projectId: "" });
      this.parentForm.updateValueAndValidity();
      this.onFilterOptionsChanged.emit({
        portfolio: portfolio,
        subPortfolio: subPortfolio,
        program: undefined,
        projectId: undefined,
      });
    } else if (portfolio) {
      this.parentForm.patchValue({
        subportfolio1: "",
        programName: "",
        projectId: "",
      });
      this.parentForm.updateValueAndValidity();
      this.selectedSubPortFolio = undefined;
      this.selectedProgram = undefined;

      this.onFilterOptionsChanged.emit({
        portfolio: portfolio,
        subPortfolio: undefined,
        program: undefined,
        projectId: undefined,
      });
    }
  }

  statusChange(data: any) {
    this.statusId = data.value.id;
    this.statusValue.emit(this.statusId);
  }
  statusOnChange(data: any) {
    this.statusValue.emit(data.value.display_name);
  }

  subPortfolio(data: any) {
    this.subPortFolioChild = [];
    let value = data.option.value;
    this.selectedSubPortFolio = data.option.value.id;
    if (JSON.stringify(value).includes("sub_portfolio_child")) {
      Object.keys(value).forEach((key: any) => {
        if (
          key.includes("sub_portfolio_child") &&
          value[key] &&
          value[key]?.length > 0
        ) {
          this.parentForm.addControl(
            key,
            new FormControl("", [
              Validators.required,
              this._formValidator.autocompleteObjectValidator(),
            ])
          );
          let level = key?.split("_")?.pop();
          this.subPortFolioChild.push({
            key: key,
            name: "Sub-Portfolio Child " + level,
            arrList: value[key],
          });
        }
      });
    }
    this.subportfolioId.emit(data.option.value.id);
    this.getProgramList();
    this.onFilterOptionChanged(this.portfoliodata, value);
  }
  onProgramSelect(data: any) {
    this.selectedProgram = data.option.value;
    this.programId.emit(data.option.value.id);
    this.onFilterOptionChanged(
      this.portfoliodata,
      this.selectedSubPortFolio,
      this.selectedProgram
    );
  }

  async getProgramList() {
    this.programList = await this.projectService
      .getPortfolioPrograms({ id: this.selectedSubPortFolio })
      .then((res: any) => {
        return res.records;
      });
  }
  async getProjectList() {
    this.programList = await this.projectService
      .getOpportunityList({ id: this.selectedSubPortFolio })
      .then((res: any) => {
        return res.records;
      });
  }
  onSelectionChangeSubportPortfolioChild(e: any) {
    let value = e.source.value;
    if (JSON.stringify(value).includes("sub_portfolio_child")) {
      Object.keys(value).forEach((key: any) => {
        if (
          key.includes("sub_portfolio_child") &&
          value[key] &&
          value[key]?.length > 0
        ) {
          let index = this.subPortFolioChild.findIndex(
            (e: any) => e.key === key
          );
          if (index > -1)
            this.subPortFolioChild.splice(
              index,
              this.subPortFolioChild.length - index
            );
          this.parentForm.addControl(
            key,
            new FormControl("", [
              Validators.required,
              this._formValidator.autocompleteObjectValidator(),
            ])
          );
          let level = key?.split("_")?.pop();
          this.subPortFolioChild.push({
            key: key,
            name: "Sub-Portfolio Child " + level,
            arrList: value[key],
          });
        }
      });
    }
  }
  changeProject(data: any) {
    this.ProjectId.emit(data.option.value.id);
  }
  changeOpportunity(data: any) {
    this.onSelectOpportunity.emit(data?.target?.value);
  }
  changeGDP(data: any) {
    this.GPDId.emit(data.option.value.id);
  }
  changeInitiative(data: any) {
    this.InitiativeId.emit(data.option.value.id);
  }

  changeBenefits(data: any) {
    this.BenefitsId.emit(data.option.value.id);
  }
  changeActionCategory(data: any) {
    this.actionId.emit(data.option.value.id);
  }
  changeFunction(data: any) {
    this.functionId.emit(data.option.value.id);
  }
  filterDatas() {
    this.filterSubPortfoliolist = this.searchSubPortCtrl!.valueChanges.pipe(
      startWith(""),
      map((value: any) =>
        typeof value === "string" ? value : value?.display_name
      ),
      map((name: any) =>
        name
          ? this._filterArray(name, this.subportfolio, "display_name")
          : this.subportfolio.slice()
      )
    );
    this.filterInitiativeTypeList =
      this.searchInitiativeTypeCtrl!.valueChanges.pipe(
        startWith(""),
        map((value: any) =>
          typeof value === "string" ? value : value?.display_name
        ),
        map((name: any) =>
          name
            ? this._filterArray(name, this.initiative, "display_name")
            : this.initiative.slice()
        )
      );
    this.filterProjectList = this.searchProjectCtrl!.valueChanges.pipe(
      startWith(""),
      map((value: any) =>
        typeof value === "string" ? value : value?.project_name
      ),
      map((name: any) =>
        name
          ? this._filterArray(name, this.projectList, "project_name")
          : this.projectList.slice()
      )
    );
    this.filterActionCategory = this.searchActionCategory!.valueChanges.pipe(
      startWith(""),
      map((value: any) =>
        typeof value === "string" ? value : value?.display_name
      ),
      map((name: any) =>
        name
          ? this._filterArray(name, this.actionCategory, "display_name")
          : this.actionCategory.slice()
      )
    );

    this.filterGDPOptions = this.searchGDPCtrl!.valueChanges.pipe(
      startWith(""),
      map((value: any) => (typeof value === "string" ? value : value?.name)),
      map((name: any) =>
        name
          ? this._filterArray(name, this.GDPList, "name")
          : this.GDPList.slice()
      )
    );

    // this.filterStatus = this.searchStatus!.valueChanges
    // .pipe(
    //   startWith(''),
    //   map((value: any) => typeof value === 'string' ? value : value?.display_name),
    //   map((name: any) => name ? this._filterArray(name,this.status_values,'display_name') : this.status_values.slice())
    // );
    // this.filterStatus = this.searchStatus!.valueChanges
    // .pipe(
    //   startWith(''),
    //   map((value: any) => typeof value === 'string' ? value : value?.display_name),
    //   map((name: any) => name ? this._filterArray(name,this.status_values,'display_name') : this.status_values.slice())
    // );
  }
  private _filterArray(value: any, arr: any = [], key: any): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((tag: any) =>
      tag?.[key]?.toLowerCase().includes(filterValue)
    );
  }

  searchProjectName(event: any) {
    if (event.target.value && event.target.value.trim().length > 0) {
      this.filterProjectList = this.searchProjectCtrl!.valueChanges.pipe(
        startWith(event.target.value),
        map((value: any) =>
          typeof value === "string" ? value : value?.project_name
        ),
        map((name: any) =>
          name
            ? this._filterArray(name, this.projectList, "project_name")
            : this.projectList.slice()
        )
      );
    } else {
      this.filterProjectList = this.searchProjectCtrl!.valueChanges.pipe(
        startWith(""),
        map((value: any) =>
          typeof value === "string" ? value : value?.project_name
        ),
        map((name: any) =>
          name
            ? this._filterArray(name, this.projectList, "project_name")
            : this.projectList.slice()
        )
      );
    }
  }
  searchProject(event?: any) {
    //  this.parentForm?.get("projectId")
    //   ?.valueChanges.pipe(filter((data:any) => data?.trim().length >= 0),
    //     debounceTime(500),
    //     switchMap((query: string): any => {
    //       console.log("Search For Project Id:- "+query)
    //       this.onSearchProject.emit({"query":query,"type":"project_id"});
    //       this.onFilterOptionChanged(this.portfoliodata,this.selectedSubPortFolio,this.selectedProgram,query);
    //       return query
    //   }
    //     )
    //   )
    //   .subscribe((e:any) => {
    //     console.log(e)
    //   });
  }
  searchProjectId(event: any) {
    this.onFilterOptionChanged(
      this.portfoliodata,
      this.selectedSubPortFolio,
      this.selectedProgram,
      event.target.value
    );
  }
}
