import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-okr-dialog",
  templateUrl: "./okr-dialog.component.html",
  styleUrls: ["./okr-dialog.component.scss"],
})
export class OkrDialogComponent implements OnInit {
  okrData: any;
  currentObjective: any;

  constructor(
    public dialogRef: MatDialogRef<OkrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.okrData = this.data.okrData;
    this.currentObjective = this.data.currentObjective;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
