import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-pulses-dialog",
  templateUrl: "./pulses-dialog.component.html",
  styleUrls: ["./pulses-dialog.component.scss"],
})
export class PulsesDialogComponent implements OnInit {
  pulsesList: any;
  projectId: any = null;
  card: any = null;
  isRisk: any = false;

  constructor(
    public dialogRef: MatDialogRef<PulsesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.pulsesList = this.data.pulsesList;
    this.projectId = this.data.projectId;
    this.card = this.data.card;
    this.isRisk = this.data.isRisk;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
