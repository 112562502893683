<app-modal-header-common title="Subscribed OKRs"> </app-modal-header-common>
<div class="okr-dialog">
  <div class="content">
    <app-okr-listing
      [OKRList]="okrData"
      [currentObjective]="currentObjective"
      [showMonths]="true"
    ></app-okr-listing>
  </div>
  <div style="display: flex; justify-content: end" class="mt-4">
    <button (click)="closeDialog()" mat-flat-button color="secondary">
      Close
    </button>
  </div>
</div>
