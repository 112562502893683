import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import {tap } from 'rxjs/operators';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  private selectedLabel: Subject<number> = new Subject();
  private currentStatus: Subject<any> = new Subject();

  filterObj: any;
  
  opportunityDetails:any={};
  constructor(private http: HttpClient,private router:Router,private transferState: TransferState, private filterService: FilterService) { 
    this.filterService.filterSubject.subscribe((res: any) => {
      this.filterObj = res;
   })
  }
  public getValue(): Observable<number> {
    return this.selectedLabel.asObservable();
  }
  public setValue(value: number): void {
    this.selectedLabel.next(value);
  }
  public getStatusValue(): Observable<any> {
    return this.currentStatus.asObservable();
  }
  public setStatusValue(value: any): void {
    this.currentStatus.next(value);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let id = route.params['id'];
    try{
      id = atob(id)
    }catch(e){
      this.router.navigate(["404"])
    }    
    const detailKey = makeStateKey(`opportunity/get-status-opportunity/${id}/`);
    return this.http.get(`opportunity/get-status-opportunity/${id}/`).pipe(
      tap((res:any) => {
        this.transferState.set(detailKey, res)
      })
    )
  }
  getOppData(id:number){
    const detailKey = makeStateKey(`opportunity/get-single-opportunity/${id}/`);
    return this.http.get(`opportunity/get-single-opportunity/${id}/`).pipe(
      tap((res:any) => this.transferState.set(detailKey, res))
    )
  }
  getOppStatus(id:number){
    const detailKey = makeStateKey(`opportunity/get-status-opportunity/${id}/`);
    return this.http.get(`opportunity/get-status-opportunity/${id}/`).pipe(
      tap((res:any) => this.transferState.set(detailKey, res))
    )
  }

  getOpportunity(offset: number = 0, end?: any, subPortId?: any, searchText?: any, portId?: any, status?: any) {
    let params = `offset=${offset}&end=${end}`;;
    if (portId) {
      params = `${params}&portfolio=${portId}`
    } if (subPortId) {
      params = `${params}&portfolio=${subPortId}`
    } if (status) {
      params = `${params}&status=${status}`
    } if (searchText) {
      params = `${params}&search_text=${searchText}`
    }
    return this.http.get(`opportunity/get-opportunity-list/?${params}`);
  }
  getSingleOpportunityById(id: any) {  
    console.log("Single Opportunity Called") ;
     const detailKey = makeStateKey(`opportunity/get-single-opportunity/${id}/`);
     const cachedResponse = this.transferState.get(detailKey, null);
     if (!cachedResponse || true) {
       return this.http.get(`opportunity/get-single-opportunity/${id}/`).pipe(
         tap((res:any) => this.transferState.set(detailKey, res))
       )
     }
     return of(cachedResponse);
   }

  getOpportunityById(id: any) {   
    const detailKey = makeStateKey(`opportunity/${id}/`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get(`opportunity/${id}/`).pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }

  // getOpportunityEvaluationById(id:any){
  //   return this.http.get(`opportunity/${id}/`);
  // }
  getOpportunityByIdWithoutChachedResponse(id: any) {   
     return this.http.get(`opportunity/${id}/`);
    
  }


 

  getOpportunityMoreInfo(id: any) {   
     return this.http.get(`opportunity/get-more-info-opportunity/${id}/`);
   }

  getOpportunityEvoluationData(id: any) {   
    return this.http.get(`opportunity/get-evaluation-matrix-opportunity/${id}/`);
  }


   getOpportunityScopeData(id: any) {   
     return this.http.get(`opportunity/get-scope-opportunity/${id}/`);
   }
   getProgramData() {  
    return this.http.get(`delivery/get-programs-list/`);
  }
 
  getProjectScopeData(id: any) {   
    return this.http.get(`delivery/project-scope/get-scope-project/${id}/`);
  }

  getProject(id: any) {   
    return this.http.get(`delivery/project-scope/get-scope-project/${id}/`);
  }

  getOpportunityFormData(query?: any, query2?: any) {    
    const dataKey = makeStateKey(`form_name=${query}`);
    const cachedResponse = this.transferState.get(dataKey, null);
    if (!cachedResponse) {
      return this.http.get(`dynamic-form/?form_name=${query}` + (query2 ? query2 : '')).pipe(
        tap((res:any) => this.transferState.set(dataKey, res))
      )
    }
    return of(cachedResponse);
  }

  getOpportunityFormDataWithoutChache(query?: any) { 
   
      return this.http.get(`dynamic-form/?form_name=${query}`)
  }
  getOpportunityHierarchy(){
    return this.http.get(`delivery/get-portfolio-hierarchy/`)

  }
  getOpportunityType(){
    return this.http.get(`delivery/get-opportunity-type/`)

  }
  getOpportunitySPOC(){
    return this.http.get(`delivery/get-risk-owners/`);

  }




  createOpportunity(payload: any) {
    return this.http.post('opportunity/', payload)
  }
  getUser() {
    const detailKey = makeStateKey(`users/`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get(`users/`).pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }
 
  updateOpportunity(id: any, payload: any) {
    return this.http.put(`opportunity/${id}/`, payload)
  }

  updateProjectScope(id: any, payload: any) {
    return this.http.put(`delivery/project-scope/update_project_scope/${id}/`, payload)
  }

  createCustomParameter(payload: any) {
    return this.http.post(`dynamic-form/create-customparameter/`, payload);
  }
  deleteCustomParameter(id: any) {
    return this.http.delete(`dynamic-form/delete-customparameter/${id}`);
  }
  getDynamicForm() {
    const detailKey = makeStateKey(`dynamic-form/`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get(`dynamic-form/`).pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }
  getProgramValues(id: any) {
    return this.http.get(`opportunity/get-grouped-subportfolio?sub_portfolio_child=${id}`);
  }
  createCustomKeyResult(payload: any) {
    return this.http.post(`opportunity/create-custom-keyresult/`, payload, { observe: 'response' });
  }
  deleteCustomKeyResutls(uuid: any, goal_uuid: any) {
    return this.http.delete(`opportunity/delete-custom-keyresult/${uuid}/${goal_uuid}`);

  }
  updateKeyresults(uuid: any) {
    return this.http.get(`opportunity/update-keyresult/${uuid}`);
  }
  deleteStrategicGoal(uuid: any) {
    return this.http.delete(`opportunity/delete-custom-strategicgoal/${uuid}`)
  }

  createFunction(payload: any) {
    return this.http.post(`opportunity/create-function/`, payload);
  }
  deleteFunction(id: any) {
    return this.http.delete(`opportunity/delete-function/${id}`);
  }

  createTimeline(payload: any) {
    return this.http.post(`opportunity/create-timeline/`, payload);
  }
  deleteCustomTimeline(id: any) {
    return this.http.delete(`opportunity/delete-timeline/${id}`);
  }

  searchOpportunity(portId: any, subPortId: any, offset: number = 0, end: number = 10, program: number = 1, searchText: any) {
    return this.http.get(`opportunity/?portfolio=${portId}&portfolio=${subPortId}&program=${program}&search_text=${searchText}&offset=${offset}&end=${end}`);
  }

  deleteOpportunity(id: any) {
    return this.http.delete(`opportunity/${id}`);
  }

  getStatus() {
    const statusKey = makeStateKey('opportunity-status-list');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get(`opportunity/opportunity-status-list`).pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  getProjectStatus() {
    const statusKey = makeStateKey('project-status');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get(`delivery/project-status/`).pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }
  getOpportunityToe(id:any){
    return this.http.get(`opportunity-toe/?opportunity_id=${id}`);
  }

  getToeSettings(id:any){
    
    return this.http.get(`opportunity-toe/?opportunity_id=${id}`);
  }

  getOpportunityToeNew(id:any){
    return this.http.get(`opportunity/get-toe-opportunity/${id}/`);
  }
  mapOpportunityToe(id:any,payload:any){
    return this.http.post(`opportunity-toe/?opportunity_id=${id}`,payload);
  }
  updateInnitiatedate(payload: any) {
    return this.http.post(`terms-of-engagement/update-toe-initiation-date/`, payload);
  }

  updateToeStatus(payload: any) {
    return this.http.post(`terms-of-engagement/update-toe-status/`, payload);
  }
  updateStatus(data: any) {
    return this.http.post('action-tracker/update-action-tracker-status/', data);
  }

  getToelist(offset: number = 0, end: number = 10, subPortId?: any, searchText?: any, portId?: any, status?: any) {
    let params = `offset=${offset}&end=${end}`;
    if (portId) {
      params = `portfolio=${portId}`
    } if (subPortId) {
      params = `${params}&portfolio=${subPortId}`
    } if (status) {
      params = `${params}&status=${status}`
    }if (searchText) {
      params = `search_text=${searchText}`
    }
    return this.http.get(`terms-of-engagement/get-toe-list/?${params}`);
  }



  treelisting(data: any) {
    const baseURL = "http://tenant1.kepleruat.easyngo.com/server/v1"
    return this.http.post('users/get-user-hierarchy', data);
  }

  // used to save opportunity file in s3 and send mail to opportunity vendors
  opportunityActions(id: any, payload: any) {
    return this.http.post(`opportunity/send-opportunity-to-vendors/${id}/`, payload, { observe: 'response' });
  }

  // To download opportunity file
  opportunityFileDownload(id: any, payload: any) {
    return this.http.post(`opportunity/send-opportunity-to-vendors/${id}/`, payload, { responseType: 'blob' });
  }

  exportOpportunities(id: any, payload: any) {
    return this.http.put(`opportunity/${id}/`, payload)
  }
  getInitiativeData() {
    const statusKey = makeStateKey('get-initiative-types');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get(`innovation/get-initiative-types`).pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  // Below api for top filter disable based on user accessiblity / permisssion (top filter:all of it, channels);
  comparePorfolio() {    
    const dataKey = makeStateKey('get-all-portfolio');
    const cachedResponse = this.transferState.get(dataKey, null);
    if (!cachedResponse) {
      return this.http.get(`dynamic-form/get-all-portfolio/`).pipe(
        tap((res:any) => this.transferState.set(dataKey, res))
      )
    }
    return of(cachedResponse);
  } 

  getPortfolioList(queryParams: any = ''){
    return this.http.get(`delivery/get-portfolio-list/`+queryParams).toPromise();
  }

  getDealPortfolioList(queryParams: any = ''){
    return this.http.get(`delivery/get-portfolio-list/`+ '?page=deal' + (queryParams ? `&${queryParams}` : '')).toPromise();
  }

  getSubPortfolioList(payload: any, queryParams: any = ''){
    return this.http.get(`delivery/get-subportfolio-list/?parent=${payload.id}` + queryParams).toPromise();
  }

  getPortfolioPrograms(payload: any, queryParams: any = ''){
    if(this.filterObj?.subportfolio?.array?.length > 0){
      let body: any = { portfolio: this.filterObj.subportfolio};
      return this.http.post(`delivery/get-filtered-list/?` + queryParams, body).toPromise();
    }
    return this.http.get(`delivery/get-opportunity-programs/?portfolio=${payload.id}` + queryParams).toPromise();
  }

  getOpportunityList(payload: any, queryParams: any = ''){
    return this.http.get(`delivery/get-opportunity-list/?portfolio=${payload?.parent?.id}&program=${payload.id}` + queryParams).toPromise();
  }

  getOpportunityKrData(opportunityId: any){
    return this.http.get(`opportunity/opportunity/${opportunityId}/opportunitygoals/`);
  }

  selectOpportunityGoals(payload: any){
    return this.http.post(`opportunity/select-opportunitygoals/`, payload);
  }

  getOpportunityToeData(opportunityId: any){
    return this.http.get(`terms/category/?opportunity_id=${opportunityId}`);
  }

  selectOpportunityToe(payload: any){
    return this.http.post(`terms/opportunity-terms/`, payload);
  }

  getFilteredList(queryParam: any, bodyParam: any = {}){
    return this.http.post(`delivery/get-filtered-list/?${queryParam}`, bodyParam).toPromise();
  }

  deleteOpportunityById(item: any){
    return this.http.delete(`opportunity/delete-opportunity/${item}/`).toPromise();
  }

  archiveOpportunityById(item: any){
    return this.http.put(`opportunity/archive-opportunity/${item}/`, []).toPromise();
  }

  getArchiveList(portfolio: any, subportfolio: any, program: any){
    return this.http.get(`opportunity/list-archive-opportunity?portfolio=${portfolio}&sub_portfolio=${subportfolio}&program=${program}`).toPromise();
  }

  unarchiveOppo(payload: any){
    return this.http.put(`opportunity/unarchive-opportunity/`, payload).toPromise();
  }


  getTermMatrix(oppId:any){
    return this.http.get(`terms/matrix/?opportunity_id=${oppId}`);
  }
  updateTermMatrix(oppId:any,data:any){
    return this.http.post(`terms/matrix/`, data);
  }
  getSelectedTerms(oppId:any){
    return this.http.get(`terms/category/?opportunity_id=${oppId}`);
  }
  
}
